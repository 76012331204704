import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { Grid } from "@material-ui/core";
import { config } from "utilities/config";
import {
  getFormschemapayload,
  createFormsubmitpayload,
  getFormsubmitpayload,
} from "data/payload";
import Sonicwebform from "app/components/mvp/sonicwebform";
import { uiSchema } from "app/pages/indvmembership/model/uiSchema";
import styled from "styled-components";
import Button from "app/components/atoms/Button/RoundCornerButton";
import { break_addressbar } from "utilities/splitUrl";
import CreateOrder from "brand/components/order";
import { useStyles } from "./style";
const StartPage = styled.section`
  li {
    //margin-top: 1rem;
    p {
      margin: 0;
      padding: 0;
    }
  }
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function IndvMembership(props) {
  const [isStarted, setStartApplication] = useState(0);
  const [isNew, setisNew] = useState(true);
  const [urlParam, seturlParam] = useState({
    formName: "individualMembership",
    subId: "",
  });
  const classes = useStyles();
  const options = {
    formTitle: "Individual Membership Application",
    getFormschemaURL: config.sonicwebform.getFormschema,
    createFormsubmitURL: config.sonicwebform.createFormsubmit,
    getFormsubmitURL: config.sonicwebform.getFormsubmit,
    getFormschemapayload: getFormschemapayload,
    createFormsubmitpayload: createFormsubmitpayload,
    getFormsubmitpayload: getFormsubmitpayload,
    WebFormName: urlParam.formName,
    uiSchema: uiSchema,
    submissionId: urlParam.subId,
    stepCount: 0,
    formschema: {},
    swfdata: {},
    swfId: "",
    createForm: isNew,
    unAuthenticate: false,
    handleChange: props.handleChange,
    emptyOrder: false,
    hasPayment: true,
    hasMultipayment: false,
  };

  const startApp = () => {
    setStartApplication(1);
  };
  useEffect(() => {
    localStorage.setItem("oid", "");

    if (window.location.href.indexOf("?") > -1) {
      seturlParam(break_addressbar(window.location.href));

      setisNew(false);
    } else {
      setisNew(true);
    }
  }, []);

  useEffect(() => {
    //console.log("url parameters"+JSON.stringify(urlParam))
  }, [urlParam]);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div id="cTTP_sonicForm" className={classes.container}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              {/* <Sonicwebform options={options} /> */}
              {isStarted === 0 ? (
                <StartPage>
                   <h1>INDIVIDUAL MEMBERSHIP APPLICATION</h1>
                  <p style={{ fontWeight: "700", marginTop: "2rem" }}>
                  Prior to completing your application please have the following required documents ready to upload.  Following receipt of your application, you will invited to complete
                  your Declaration statements online.
                  </p>

                  <div className={classes.infoBlock}>
                    <h3>
                    Regular Members
                    </h3>
                    <ul>
                    <li>Business Law Certificate evidencing completion of this course</li>
                    <li>A copy of your national ID with photograph:  ie:  Current passport or driver’s license</li>
                    <li>Character Reference – A letter confirming applicant is of good character from current employer, a previous employer or a professional person confirming they have known you for at least two years.</li>
                    <li>Evidence of legal status:  ie a copy of Work Permit, Temp Work Permit, Confirmation of being Caymanian letter,  PR Certificate, evidence of PR renewal.</li>
                    <li>Letter of Good Standing from your Accounting Institute</li>
                    </ul>
                    <p>Other optional documents that can be submitted are:</p>
                    <ul>
                      <li>Professional qualification certificate (CPA,ACCA, CMA,CIA,  CA, ACA, etc).</li>
                    </ul>
                  </div>
                  <div className={classes.infoBlock}>
                    <h3>Associate Members</h3>
                    <ul>
                      <li>A copy of your national ID with photograph:  ie:  Current passport or driver’s license</li>
                      <li>Character Reference – A letter confirming applicant is of good character from current employer, a previous employer or a professional person confirming they have known you for at least two years.</li>
                      <li>Evidence of legal status:  ie a copy of Work Permit, Temp Work Permit, Confirmation of being Caymanian letter,  PR Certificate, evidence of PR renewal.</li>
                    </ul>
                  </div>
                  <div className={classes.infoBlock}>
                    <h3>Student Members</h3>
                    <ul>
                      <li>A copy of your national ID with photograph:  ie:  Current passport or driver’s license</li><li>
Character Reference – A letter confirming applicant is of good character from current employer, a previous employer or a professional person confirming they have known you for at least two years.
</li>
                      <li>Enrolment Letter</li>
                      <li>Evidence of legal status:  ie a copy of Work Permit, Temp Work Permit, Confirmation of being Caymanian letter,  PR Certificate, evidence of PR renewal.</li>
                    </ul>
                  </div>
                  <p>
                    Please note that reference letters must be dated, addressed
                    to CIIPA and contain email and telephone number of the
                    reference provider.
                  </p>
                  <p style={{ fontStyle: "italic" }}>
                    Supported file types:- PDF, JPG, JPEG, DOC, DOCX
                  </p>

                  <br />
                  <Button
                    variant="contained"
                    onClick={startApp}
                    style={{
                      color: globalTheme.palette.white.main,
                      background: globalTheme.palette.primary.dark,
                      borderRadius: "47px",
                      border: "0",
                      padding: "10px 31px",
                      fontSize: "18px",
                      height: "unset",
                      "&:hover": {
                        color: globalTheme.palette.primary.dark,
                        background: globalTheme.palette.secondary.light,
                      },
                    }}
                  >
                    {isNew ? "Start Application" : "Resume Application"}
                  </Button>
                </StartPage>
              ) : (
                <div>
                  <Sonicwebform options={options} />
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default CreateOrder(IndvMembership);
