import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import FormWidget from "app/components/mvp/formWidget";
import { viewEmploymentHistoryDetailsUiSchema } from "../model/schema/viewemploymenthistorydetailsuiSchema";
import {
  getEmploymentDetailsPayload,
  employmentDetailsPayload,
} from "../model/payload/payload";
import { config } from "utilities/config";
import { useLocation, useHistory } from "react-router-dom";
import { post } from "app/services/api";
import { getEmploymentHistoryDetailsSchema } from "../model/schema/schema";
import { useProfileStyles } from "../style";
import { usePreLoader } from "app/services/hooks/usePreLoader";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ViewEmploymentHistoryDetails(props) {
  const [recordId, setrecordId] = useState("");
  const [contact2FormData, setContact2FormData] = useState({});
  const getcontactId = useSelector((state) => state.contactid);
  let query = useQuery();
  const dispatch = useDispatch();
  const userEntitlement = useSelector((state) => state.entitleMents);
  const userRole = useSelector((state) => state.role);
  let permissions =
    userEntitlement && userEntitlement.find((x) => x.route == props.match.path);

  let formDataa = {};
  const { enableLoader } = usePreLoader();

  const classes = useProfileStyles();
  const history = useHistory();

  const options = {
    widgetType: "update",
    formSchema: getEmploymentHistoryDetailsSchema,
    uiSchema: viewEmploymentHistoryDetailsUiSchema,
    entityType: "ctpt_professionalhistories",
    button1Label:
      userRole && userRole.name && !userRole.name.includes("Individual")
        ? "Save"
        : "Submit",
    button2Label: "Go Back",
    apiURL: config.teleportal.submitForm.Update,
    payloadSubmit: employmentDetailsPayload,
    goBackPage: "/profile/employmenthistory",
    historyUrl: "",
    button2GoBack: true,
    columns: [],
    contactId: getcontactId,
    formData: contact2FormData,
    hasModel: false,
    recordId: recordId,
  };

  const [contactOptions, setContactOptions] = useState(options);

  useEffect(() => {
    if (window.location.href.indexOf("?") > -1) {
      setrecordId(query.get("id"));
    }
  }, []);

  useEffect(() => {
    let payload = {
      ...getEmploymentDetailsPayload,
      filters: [
        {
          field: "ctpt_professionalhistoryid",
          searchvalue: recordId,
          type: "guid",
        },
      ],
    };

    getRecordDetails(payload);
  }, [recordId]);

  const updateFormData = (entityType, formData) => {
    options.formData = formData;
    options.initialFormData = formData;
    setContactOptions(options);
    setContact2FormData(formData);
  };

  const getRecordDetails = (payload) => {
    let api = config.teleportal.submitForm.getId;
    if (recordId !== "") {
      enableLoader(true, "Loading Employment History");
      post(api, payload)
        .then((res) => {
          res.data.data.forEach((item) => {
            formDataa[item.name] = item.id != undefined ? item.id : item.value;
          });
          updateFormData({}, formDataa);
        })
        .catch(console.log)
        .finally(() => {
          enableLoader(false);
        });
    }
  };
  //console.log("CCONS:", contact2FormData);

  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Your Employment History Updated successfully",
          code: "",
          display: "show",
          severity: "success",
        },
      });
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Employment update failed",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item xs={12} md={9}>
        <div className={classes.componentHolder}>
          <FormWidget
            options={contactOptions}
            updateFormData={updateFormData}
            successResponse={getResponse}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default ViewEmploymentHistoryDetails;
