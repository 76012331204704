//Import React Library
import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthenticatedTemplate } from "@azure/msal-react";
import styled from "styled-components";

//Import Material-UI Library
import { ThemeProvider } from "@material-ui/core/styles";

//Loading Data and Style..
import { globalTheme } from "style/globalTheme";
import { useStyles } from "./style";
//Loading components
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { config } from "utilities/config";
import { postSwf } from "app/services/api";

import ListComponent from "app/components/mvp/listWidget";
import { getlistPayload } from "./model/payload";
import {
  applicationNameMap,
  MEMBER_CLASS,
  declrationTilesData,
} from "./model/applicationNameMap";
import TabWidget from "app/components/molecules/tab/TabWidget.mui";
import _ from "lodash";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import ProfileTileCard from "../profile/components/profileTile";

const customFilter = (objects, filterCompletedApplications = false) => {
  const newRow = objects.data;
  return _.filter(
    newRow.filter(
      (o) =>
        o.name !== "eventreg" &&
        o.name !== "examreg" &&
        o.swfStatus ===
          (filterCompletedApplications ? "100000001" : "100000000")
    )
  );
};
const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 2rem 8rem 4rem 8rem;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;
const Heading = styled.div`

  border-radius: 4px; 
  height: 100px;
  //padding: 1rem 1.2rem;
  
  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;


  h2 {
    max-width: max-content;   
    //margin: 4rem 2.5rem;
    margin: 0;
    font-size: 1.5rem;
    letter-spacing: -0.16px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 1rem;
    // border-radius: 8px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    );

     color:${globalTheme.palette.primary.main};
  }

  ${globalTheme.breakpoints.down("sm")} {
    position: relative;
    width: 100%;
    h2 {
      margin-left: 0;
      font-size: 2.5rem;
    }
  }

  ${globalTheme.breakpoints.down("xs")} {
    height: 15rem;
    h2 {
     margin-left: 1rem;
     margin-right: 1rem;
    }
  }
}
`;

function Declarations() {
  const classes = useStyles();
  const [getparams, setgetparams] = useState({
    submissionId: "",
    webFormName: "",
  });
  const [open, setOpen] = React.useState(false);
  const [isCancel, setisCancel] = React.useState(false);
  const [updatedKey, setupdatedKey] = React.useState(false);
  const role = useSelector((state) => state.role);
  const getcontactId = useSelector((state) => state.contactid);
  const dispatch = useDispatch();
  const history = useHistory();
  const continueClick = (params) => {
    // localStorage.setItem("ctpt_sd", params.row.id);
    //localStorage.setItem("ctpt_fm", params.row.name);
    dispatch({
      type: "SET_DECLARATIONS",
      payload: params,
    });
    /* let path =
      window.location.protocol +
      "//" +
      window.location.host +
      "/declarations/" +
      "id=" +
      params.row.ctpt_memberdeclarationsid;
    window.location.href = path;*/

    history.push({
      pathname: "/declarations/view",
      state: {
        id: params.row.ctpt_memberdeclarationsid,
      },
    });
  };

  useEffect(() => {
    localStorage.setItem("oid", "");
  }, []);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
      <StartPage>
          <div>
            <Button
              size="medium"
              variant="outlined"
              onClick={() => {
                history.push("/profile");
              }}
             
            >
              Go Back
            </Button>
           <Heading>
        <h2>Manage Declarations</h2>
        </Heading>
          </div>
          <div className={classes.flexContainer}>
            {declrationTilesData.map(
              (
                { title, linkText, linkURI, items, dialogue, description },
                i
              ) => (
                <div className={classes.flexItems}>
                  <ProfileTileCard
                    title={title}
                    description={description}
                    linkURL={linkURI}
                    linkText="Update"
                  />
                  {/*<TileCard
                  key={title}
                  title={title}
                  image={
                    <img src={`./brand/leaf-optimized.jpg`} alt="member" />
                  }
                  linkURL={linkURI}
                  linkText={linkText}
                  items={items}
                  variant={"PROFILE"}
                  hasDialogue={dialogue}
                  handleChange={handleChange}
                  description={description}
                />*/}
                </div>
              )
            )}
          </div>
        </StartPage>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default Declarations;
