import Upload from "app/components/atoms/upload";
import CustomTextBox from "app/components/atoms/customTextBox";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import CustomRadio from "app/components/atoms/customRadio";
import CustomSelect from "app/components/atoms/customSelect";

const reUsableSchemaComp = {
  uploadId: {
    "ui:widget": (props) => {
      return (
        <Upload
          recordType={props.schema.containerName}
          recordPath={props.schema.recordPath}
          getProps={props}
        />
      );
    },
  },
};

export const uiSchema = {
  "ui:title": "",
  fullName: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  physicalAddress: {
    street: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    plotNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    district: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
  },
  postalAddress: {
    street: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    line1: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    district: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    postalcode: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    country: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  complianceOfficer: {
    firstName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    lastName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    emailAddress: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    phoneNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    CellphoneNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  MLRO: {
    firstName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    lastName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    emailAddress: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    phoneNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    CellphoneNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  DMLRO: {
    firstName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    lastName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    emailAddress: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    phoneNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    CellphoneNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  officerAndDirector: {
    firstName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    lastName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    position: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    memberNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    addAnotherOfficerAndDirector: {
      items: {
        firstName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        lastName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        position: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        memberNumber: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
      },
    },
  },
  partner: {
    firstName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    lastName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    position: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    memberNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    uploadId: reUsableSchemaComp.uploadId,
    addAnotherPartner: {
      items: {
        firstName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        lastName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        idNumber: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        uploadId: reUsableSchemaComp.uploadId,
      },
    },
  },
  beneficialOwnerConditional: {
    beneficialOwnerRadio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    boFirstName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    boLastName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    boUploadId: reUsableSchemaComp.uploadId,

    addAnotherBeneficialOwner: {
      items: {
        boFirstName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        boLastName: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        boIdNumber: {
          "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
          },
        },
        boUploadId: reUsableSchemaComp.uploadId,
      },
    },
  },
  RegisterofDirectorsandOfficers: reUsableSchemaComp.uploadId,
  RegisterofMembers: reUsableSchemaComp.uploadId,
  shareholder: {
    shareFirstName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    shareLastName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  professionalAccounting: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  isSecretarialServices: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  fitQuestionaire: reUsableSchemaComp.uploadId,
  professionalIndemnity: reUsableSchemaComp.uploadId,
  undertakingByFirmAndPartners: reUsableSchemaComp.uploadId,
  passport: reUsableSchemaComp.uploadId,
  policeClearance: reUsableSchemaComp.uploadId,
  incorporationCertificate: reUsableSchemaComp.uploadId,
  authorisingSignature: reUsableSchemaComp.uploadId,
  shareHolderCertificate: reUsableSchemaComp.uploadId,
  cv: reUsableSchemaComp.uploadId,
  qualityControlManual: reUsableSchemaComp.uploadId,
  trainingProgram: reUsableSchemaComp.uploadId,
  fia: reUsableSchemaComp.uploadId,
  tradeAndBusinessLicence: reUsableSchemaComp.uploadId,
  certificateOfCIMALicence: reUsableSchemaComp.uploadId,
  businessPlanOrOverviewLetter: reUsableSchemaComp.uploadId,
  acknowledgementCheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  fnp1Conditional: {
    fnp1Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp1Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp2Conditional: {
    fnp2Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp2Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp3Conditional: {
    fnp3Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp3Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp4Conditional: {
    fnp4Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp4Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp5Conditional: {
    fnp5Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp5Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp6Conditional: {
    fnp6Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp6Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp7Conditional: {
    fnp7Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp7Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp8Conditional: {
    fnp8Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp8Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp9Conditional: {
    fnp9Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp9Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp10Conditional: {
    fnp10Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp10Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp11Conditional: {
    fnp11Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp11Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp12Conditional: {
    fnp12Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp12Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp13Conditional: {
    fnp13Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp13Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp14Conditional: {
    fnp14Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp14Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp15Conditional: {
    fnp15Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp15Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  fnp16Conditional: {
    fnp16Radio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    fnp16Detail: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
};
