import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { globalTheme } from "style/globalTheme";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginRight: theme.spacing(2),
  },
  menuButton: {
    color: "#fff",
    height: "calc(1rem + 16px)",
    marginRight: "20px",
    lineHeight: "120%",
    padding: "25px 15px",
    borderBottom: "1px solid rgba(0,0,0,.1)",
    "&::after": {
      content: '""',
      display: "inline-block",
      boxSizing: "border-box",
      position: "relative",
      width: "7px",
      height: "7px",
      borderBottom: "2px solid",
      borderRight: "2px solid",
      transform: "rotate(45deg)",
      right: "-8px",
      top: "-3px",
    },
  },
  menuLink: {
    color: globalTheme.palette.grey[800],
    textDecoration: "none",
    fontSize: "15px",
    padding: "10px 15px",
    fontWeight: "400",
    "&:hover": { color: "#fff" },
  },

  menuItem1: {
    padding: "0",
    margin: "0",
    lineHeight: "150%",
    borderRadius: "0",
    "&:hover": { color: "#fff", backgroundColor: "#01519b" },
  },
  /* menuButton: {
    color: "#fff",

   
    padding: "42px 20px",
    lineHeight: "120%",
    borderBottom: "1px solid rgba(0,0,0,.1)",
    height: "calc(1rem + 16px)",
    lineHeight: "150%",
 
    width: "200px",
    "&:hover": { color: "#fff", backgroundColor: "#01519b" },
    "&::after": {
      content: '""',
      display: "inline-block",

      boxSizing: "border-box",
      position: "relative",
      width: "7px",
      height: "7px",
      borderBottom: "2px solid",
      borderRight: "2px solid",
 
      transform: "rotate(45deg)",
      right: "-8px",
      top: "-3px",
    },
  },
  menuLink: {
    textDecoration: "none",
    color: globalTheme.palette.grey[800],
   
    fontSize: "15px",
    fontWeight: "400",
   
    float: "none",

    position: "relative",
    display: "block",
    boxshadow: "0 1rem 3rem rgba(0,0,0,.175)",
    transition: "all .5s ease-out",
  },*/
}));

export default function MenuListComposition({
  menuTitle = "menu",
  menuItems = [],
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((openState) => !openState);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open;
  // }, [open]);

  return (
    <>
      <div
        onMouseLeave={() => {
          setOpen(false);
        }}
      >
        <Button
          className={classes.menuButton}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          onMouseOver={() => {
            setOpen(true);
          }}
        >
          {menuTitle}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {menuItems.map((menuItem, index) => {
                      return (
                        <div key={menuItem.name} className={classes.menuItem1}>
                          <MenuItem
                            className={classes.menuItem}
                            onClick={handleClose}
                          >
                            <Link
                              className={classes.menuLink}
                              to={menuItem.link}
                            >
                              {menuItem.name}
                            </Link>
                          </MenuItem>
                        </div>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  );
}
