import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  btnBox: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    marginLeft: "1.5rem",
  },
  container: {
    "& h1": {
      color: theme.palette.primary.dark,
      fontSize: "30px",
    },
    "& h3": {
      fontSize: "22px",
      paddingLeft: "30px",
      fontWeight: "500",
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
      marginBottom: "0.5rem",
      position: "relative",
      color: "#01519b",
      "&::before": {
        content: '""',
        position: "absolute",
        height: "100%",
        width: "3px",
        backgroundColor: "#01519b",
        top: "0",
        left: " -24px",
      },
      "&::after": {
        position: "absolute",
        left: 0,
        top: "8px",
        fontSize: "25px",
      },
    },
    "& p": {
      fontSize: "18px",
    },
  },
  infoBlock: {
    boxShadow: "rgba(1,81,155,.2) 0 1px 4px",
    backgroundColor: "rgba(0,164,189,.03)",
    borderRadius: "8px",
    padding: "1.5rem!important",
    marginBottom: "1.5rem!important",
  },
  regBtn: {
    color: theme.palette.white.main,
    background: theme.palette.primary.dark,
    borderRadius: "47px",
    border: "0",
    padding: "20px 31px",
    fontSize: "18px",
    height: "unset",
    "&:hover": {
      color: theme.palette.primary.dark,
      background: theme.palette.secondary.light,
    },
  },
}));
