import { makeStyles } from "@material-ui/core";
import { brandPalette } from "../../../brand/theme/brandTheme";

const usePreferenceStyles = makeStyles((theme) => ({
  helperTextContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  preferenceContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2.5),
    },
  },
  footerButtons: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(10),
    maxWidth: "auto",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "250px",
    },
  },
  saveButton: {
    color: "white",
  },
  alert: {
    padding: theme.spacing(3),
    margin: theme.spacing(2.5),
    marginTop: theme.spacing(5),
    border: `1px solid ${brandPalette["Primary"]["50"]}`,
    borderWidth: "1px",
    display: "flex",
  },
  errorAlert: {
    border: `1px solid ${brandPalette["Primary"]["50"]}`,
  },
  alertContent: {
    marginLeft: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  emptyListAlert: {
    margin: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  alertMessage: {
    marginBlockStart: "0.5rem",
  },
}));

export { usePreferenceStyles };
