import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  btnBox: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    marginLeft: "1.5rem",
  },
  container: {
    margin: "20px auto",
    textAlign: "center",
    "& h1": {
      color: theme.palette.primary.dark,
      fontSize: "30px",
      marginTop: "30px",
    },
    "& p": {
      fontSize: "18px",
    },
  },
}));
