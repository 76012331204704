export const getlistPayload = {
  properties: [
    "name",
    "salesorderid",
    "statecode",
    "totalamount",
    "requestdeliveryby",
    "_transactioncurrencyid_value",
    "_ctpt_eventid_value",
  ],
  formattedFields: ["_ctpt_sonicwebformsubmission_value"],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: false,
    filterfields: [
      {
        field: "_ctpt_contactid_value",
        searchvalue: "",
        type: "guid",
      },
    ],
  },
  Aggregate: {
    AggregateFields: [
      {
        Field: "totalamount",
        Type: "sum",
        Alias: "Amount",
      },
    ],
  },
  recordtype: "salesorders",
  idfield: "salesorderid",
  continuationToken: "",
  pazesize: "10",
};
