//Import React Library
import React, { Suspense } from "react";

import { settings } from "constants/settings";
import { CircularProgress } from "@material-ui/core";

function ListWidget(props) {
  const { children, data, options } = props;

  const Comp = React.lazy(() =>
    import("./ListComponent." + settings.UI + ".js")
  );

  return (
    <Suspense fallback={<CircularProgress />}>
      <Comp data={data} options={options}>
        {children}
      </Comp>
    </Suspense>
  );
}

export default React.memo(ListWidget);
