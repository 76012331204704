import React from "react";
import { useStyles } from "../style";
function WithLoader({ showLoader, children, hasError }) {
  const classes = useStyles();
  const loadingStyles = hasError ? classes.hide : classes.show;
  if (showLoader) {
    return <div className={loadingStyles}>Loading..</div>;
  }
  return children;
}

export default WithLoader;
