// export const getEventsPayload = {
//   Parameters: {
//     ctpt_GetUpcomingEvents_EventType: "'event'",
//   },
//   functionName:
//     "ctpt_GetUpcomingEvents(ctpt_GetUpcomingEvents_EventType=@ctpt_GetUpcomingEvents_EventType)",
// };

export const getEventsPayload = {
  properties: [
    "msmedia_mediaeventid",
    "msmedia_name",
    "msmedia_startdate",
    "msmedia_enddate",
    "msmedia_directions",
    "msmedia_description",
    "_msmedia_primaryvenue_value",
    "msmedia_eventsubtitle",
    "msmedia_eventtopic",
    "msmedia_eventtimezone",
    "msmedia_eventtype",
    "statecode",
    "msmedia_registrationopenson",
    "msmedia_registrationcloseson",
    "ctpt_cancellationduedate",
    "ctpt_discountedpriceuntil",
    "msmedia_registrationportalbannerimage",
  ],
  formattedFields: ["msmedia_totalcost"],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldOrOperator: true,
    filterfields: [
      {
        field: "statecode",
        searchvalue: "0",
        type: "guid",
      },
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  sortfield: {
    field: "msmedia_startdate",
    sorttype: "desc",
  },
  recordtype: "msmedia_mediaevents",
  idfield: "msmedia_mediaeventid",
  continuationToken: "",
  pazesize: "10",
};
// properties: [
//   "msmedia_mediaeventid",
//   "msmedia_name",
//   "msmedia_startdate",
//   "msmedia_enddate",
//   "msmedia_primaryvenue",
//   "msmedia_description",
//   "msmedia_eventsubtitle",
//   "msmedia_eventtopic",
//   "msmedia_eventtimezone",
//   "msmedia_eventtype",
//   "statecode",
//   "msmedia_registrationopenson",
//   "msmedia_registrationcloseson",
//   "ctpt_cancellationduedate",
//   "ctpt_discountedpriceuntil",
// ],
// formattedFields: ["msmedia_totalcost"],
// filter: {
//   globalfilter: "",
//   globalAndOperator: false,
//   fieldOrOperator: true,
//   filterfields: [
//     {
//       field: "msmedia_eventtype",
//       searchvalue: "766860002",
//       type: "Picklist",
//     },
//     {
//       field: "msmedia_eventtype",
//       searchvalue: "766860006",
//       type: "Picklist",
//     },
//     {
//       field: "msmedia_eventtype",
//       searchvalue: "766860009",
//       type: "Picklist",
//     },
//     {
//       field: "msmedia_eventtype",
//       searchvalue: "766860010",
//       type: "Picklist",
//     },
//     {
//       field: "msmedia_eventtype",
//       searchvalue: "766860003",
//       type: "Picklist",
//     },
//     {
//       field: "msmedia_eventtype",
//       searchvalue: "766860012",
//       type: "Picklist",
//     },
//     {
//       field: "msmedia_eventtype",
//       searchvalue: "766860007",
//       type: "Picklist",
//     },
//     {
//       field: "msmedia_eventtype",
//       searchvalue: "766860011",
//       type: "Picklist",
//     },
//     {
//       field: "msmedia_eventtype",
//       searchvalue: "766860000",
//       type: "Picklist",
//     },
//     {
//       field: "msmedia_eventtype",
//       searchvalue: "766860001",
//       type: "Picklist",
//     },
//     {
//       field: "msmedia_eventtype",
//       searchvalue: "766860008",
//       type: "Picklist",
//     },
//     {
//       field: "msmedia_eventtype",
//       searchvalue: "766860005",
//       type: "Picklist",
//     },
//     {
//       field: "msmedia_eventtype",
//       searchvalue: "766860004",
//       type: "Picklist",
//     },
//   ],
// },
// Aggregate: {
//   AggregateFields: [],
// },
// sortfield: {
//   field: "msmedia_startdate",
//   sorttype: "asc",
// },
// recordtype: "msmedia_mediaevents",
// idfield: "msmedia_mediaeventid",
// continuationToken: "",
// pazesize: "10",
// };

export const getEventRegPayload = (contactID) => ({
  properties: [
    "_msmedia_contact_value",
    "_msmedia_mediaevent_value",
    "msmedia_mediaregistrationid",
    "msmedia_registrationstatus",
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: true,
    filterfields: [
      {
        field: "_msmedia_contact_value",
        searchvalue: contactID,
        type: "lookup",
      },
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "msmedia_mediaregistrations",
  idfield: "msmedia_mediaregistrationid",
  continuationToken: "",
  pazesize: "10",
});

export const getRegEventsPayload = (registeredEvents) => {
  const filterfields = registeredEvents.map((eventID) => ({
    field: "msmedia_mediaeventid",
    searchvalue: eventID,
    type: "guid",
  }));

  if (filterfields.length <= 0) {
    return null;
  }

  return {
    properties: [
      "msmedia_mediaeventid",
      "msmedia_name",
      "msmedia_startdate",
      "msmedia_enddate",
      "msmedia_directions",
      "_msmedia_primaryvenue_value",
      "msmedia_eventsubtitle",
      "msmedia_eventtopic",
      "msmedia_eventtimezone",
      "msmedia_eventtype",
      "statecode",
      "msmedia_registrationopenson",
      "msmedia_registrationcloseson",
      "ctpt_cancellationduedate",
      "ctpt_discountedpriceuntil",
    ],
    formattedFields: ["msmedia_totalcost"],
    filter: {
      globalfilter: "",
      globalAndOperator: false,
      fieldOrOperator: true,
      filterfields: filterfields,
    },
    Aggregate: {
      AggregateFields: [],
    },
    sortfield: {
      field: "msmedia_startdate",
      sorttype: "asc",
    },
    recordtype: "msmedia_mediaevents",
    idfield: "msmedia_mediaeventid",
    continuationToken: "",
    pazesize: "10",
  };
};

export const getTimeZoneDefsPayload = () => ({
  properties: ["timezonecode", "standardname"],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: false,
    filterfields: [],
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "timezonedefinitions",
  idfield: "timezonecode",
  continuationToken: "",
  pazesize: "1000",
});
