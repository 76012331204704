import Upload from "app/components/atoms/upload";
import CustomTextBox from "app/components/atoms/customTextBox";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import CustomRadio from "app/components/atoms/customRadio";
import CustomDate from "app/components/atoms/customDate";
import CustomSelect from "app/components/atoms/customSelect";

const componentType = {
  upload: {
    "ui:widget": (props) => {
      return (
        <Upload
          recordType={props.schema.containerName}
          recordPath={props.schema.recordPath}
          getProps={props}
        />
      );
    },
  },
};

export const uiSchema = {
  "ui:title": "",
  membershipclass: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  title: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  testmobilenumber: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  firstname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  lastname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  othername: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  dateofbirth: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  birthcountry: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  nationality: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  uploadCaymanstatus: componentType.upload,
  addAnotherNationality: {
    items: {
      nationality: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
    },
  },
  iscountryresident: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },

  citizenConditional: {
    citizenRadio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    citizenid: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    citizenidexpiry: {
      "ui:widget": (props) => {
        return <CustomDate getProps={props} />;
      },
    },
    uploadPermanantResidoc: componentType.upload,
    residentcountry: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    arrivaldate: {
      "ui:widget": (props) => {
        return <CustomDate getProps={props} />;
      },
    },
    uploadResidencyVerify: componentType.upload,
    passportnumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    uploadPassportVerify: componentType.upload,
    passportexpiry: {
      "ui:widget": (props) => {
        return <CustomDate getProps={props} />;
      },
    },
    workpermitnumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    permitexpiry: {
      "ui:widget": (props) => {
        return <CustomDate getProps={props} />;
      },
    },
    exemptioncertnumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    uploadWorkpermitdoc: componentType.upload,
  },
  residentialaddress: {
    resLine1: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    resLine2: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    rescity: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },

    resdistrict: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    resPostalCode: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    resCountry: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
  },
  postaladdress: {
    line1: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    line2: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    city: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    district: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    postalCode: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    country: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
  },
  contactinformation: {
    contactworkphonenumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    contactmobile: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    contactemailaddress: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    contactfax: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  yourrole: {
    jobtitle: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    natureofbusiness: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    responsibility: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    jobstartdate: {
      "ui:widget": (props) => {
        return <CustomDate getProps={props} />;
      },
    },
  },
  organisation: {
    orgname: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    whoisemployer: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    wrkLine1: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    wrkLine2: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    wrkcity: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    wrkdistrict: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    wrkPostalCode: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    wrkCountry: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    orgsize: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    employeeConditional: {
      employeeCategoryRadio: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      dirincountry: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      dirinothercountry: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      othercountry: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      addanothercountry2: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },
  },
  businessConditional: {
    prevEmploymentRadio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    business: {
      businessName: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },

      jobTitle: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      businessNature: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      businessStartDate: {
        "ui:widget": (props) => {
          return <CustomDate getProps={props} />;
        },
      },
      businessFinishDate: {
        "ui:widget": (props) => {
          return <CustomDate getProps={props} />;
        },
      },
      businessAddressline1: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      businessAddressline2: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      businessCity: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      businessPostalcode: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      businessCountry: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      businessDistrict: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      addAnotherBusiness: {
        items: {
          businessName: {
            "ui:widget": (props) => {
              return <CustomTextBox getProps={props} />;
            },
          },
          businessNature: {
            "ui:widget": (props) => {
              return <CustomSelect getProps={props} />;
            },
          },
          jobTitle: {
            "ui:widget": (props) => {
              return <CustomTextBox getProps={props} />;
            },
          },
          businessStartDate: {
            "ui:widget": (props) => {
              return <CustomDate getProps={props} />;
            },
          },
          businessFinishDate: {
            "ui:widget": (props) => {
              return <CustomDate getProps={props} />;
            },
          },
          businessAddressline1: {
            "ui:widget": (props) => {
              return <CustomTextBox getProps={props} />;
            },
          },
          businessAddressline2: {
            "ui:widget": (props) => {
              return <CustomTextBox getProps={props} />;
            },
          },
          businessCity: {
            "ui:widget": (props) => {
              return <CustomTextBox getProps={props} />;
            },
          },
          businessPostalcode: {
            "ui:widget": (props) => {
              return <CustomTextBox getProps={props} />;
            },
          },
          businessCountry: {
            "ui:widget": (props) => {
              return <CustomTextBox getProps={props} />;
            },
          },
        },
      },
    },
  },

  qualificationType: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  qualificationName: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  qualificationStartDate: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  qualificationEndDate: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  upload: componentType.upload,
  addAnotherQualification: {
    items: {
      qualificationType: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      qualificationName: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      qualificationStartDate: {
        "ui:widget": (props) => {
          return <CustomDate getProps={props} />;
        },
      },
      qualificationEndDate: {
        "ui:widget": (props) => {
          return <CustomDate getProps={props} />;
        },
      },
      upload: componentType.upload,
    },
  },
  profMembershipConditional: {
    profMembershipRadio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    profBody: {
      profBodyName: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      profMembershipConditional: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      nasbamember: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      cpacannadaprovinces: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      profBodyUpload: componentType.upload,
      addAnotherProfBody: {
        items: {
          profMembershipConditional: {
            "ui:widget": (props) => {
              return <CustomSelect getProps={props} />;
            },
          },
          nasbamember: {
            "ui:widget": (props) => {
              return <CustomSelect getProps={props} />;
            },
          },
          cpacannadaprovinces: {
            "ui:widget": (props) => {
              return <CustomSelect getProps={props} />;
            },
          },
          profBodyUpload: componentType.upload,
        },
      },
    },
  },
  ref1: {
    membershipNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    letterOfReferenceUpload: componentType.upload,
  },
  ref2: {
    membershipNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    letterOfReferenceUpload: componentType.upload,
  },
  ref3: {
    membershipNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    letterOfReferenceUpload: componentType.upload,
  },
  confirmationLetterUpload: componentType.upload,
  identificationUpload: componentType.upload,
  changeOfNameUpload: componentType.upload,
  policeClearanceUpload: componentType.upload,
  acknowledgement2CheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  applyAdmissionCheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  complyActCheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  designationCheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  publicDutyCheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  disciplineHearingCheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  ethicsCheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  attendWokrshopCheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  acknowledgementCheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  acknowledgement1CheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  licencedeclaration: {
    acknowledgement2CheckBox: {
      "ui:widget": (props) => {
        return <CustomCheckBox getProps={props} />;
      },
    },
    acknowledgement3CheckBox: {
      "ui:widget": (props) => {
        return <CustomCheckBox getProps={props} />;
      },
    },
    indemnitydeclaration: {
      acknowledgement4CheckBox: {
        "ui:widget": (props) => {
          return <CustomCheckBox getProps={props} />;
        },
      },
    },
  },
};
