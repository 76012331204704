//Please note new menu structure MUST BE ONLY ONE LEVEL!

export const appmenuItemsdata = (accessModules, getItems) => {
  const Apprenticeship = {
    name: "Apprenticeship",
    items: [
      {
        name: "New Training Employer Application​",
        link: "/application/trainingemployer",
      },
      {
        name: "Training Agreement",
        link: "/membership/student-training-agreement",
      },
      {
        name: "Student Placement",
        link: "/student-placement",
      },
    ],
  };

  const DevelopmentCPD = {
    name: "Development (CPD)​",
    items: [{ name: "Manage Declarations​", link: "/cpd/declaration" }],
  };

  const Registration = {
    name: "Registration",
    items: [
      {
        name: "Event Registration​",
        link: "/event/registration",
      },
    ],
  };
  const Account = {
    name: "Account",
    items: [
      {
        name: "Invoices & Payments",
        link: "/invoices",
      },
    ],
  };

  const Applications = {
    name: "Applications",
    items: [
      { name: "Manage Existing Applications", link: "/applications" },
      {
        name: "New Individual Application​",
        link: "/application/individualMembership",
      },
      // {
      //   name: "New Firm Application",
      //   link: "/application/firmMembership",
      // },
    ],
  };

  const newItems = [];

  if (typeof accessModules !== "string" && accessModules.length > 0) {
    accessModules.map((item, index) => {
      const evalItem = eval(item);
      newItems.push(evalItem);
    });
  } else {
    newItems.push(eval(eval(JSON.stringify(accessModules))));
  }
  return getItems ? newItems[0].items : newItems;
};
