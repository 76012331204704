import React from "react";
import { Grid, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { config } from "utilities/config";

import ListWidget from "app/components/mvp/listWidget";

import { columns } from "../model/data/nationalityColumn";
import { getNationalitySchema } from "../model/schema/getNationalitySchema";
import {
  getlistPayload,
  deleteListPayload,
} from "../model/payload/nationalityPayload";
import { AddNationalityPayload } from "../model/payload/addNationalityPayload";
import { getNationalityUiSchema } from "../model/schema/getNationalityUiSchema";

export const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));
function Nationalities() {
  const classes = useStyles();
  const contactid = useSelector((state) => state.contactid);
  const userRole = useSelector((state) => state.role);
  let history = useHistory();

  const options = {
    apiURL: config.teleportal.submitForm.get,
    apiUrlDel: config.teleportal.submitForm.delete,
    columns: columns,
    ssearchPayload: "searchFilter",
    getlistPayload: getlistPayload,
    deleteListPayload: deleteListPayload,
    listHeading: "Nationality List",
    formwidgetOptions: {
      widgetType: "update",
      formSchema: getNationalitySchema,
      uiSchema: getNationalityUiSchema,
      apiURL: config.teleportal.submitForm.Create,
      entityType: "ctpt_contactnationalities",
      formUischema: "",
      button1Label: "Add",
      button2Label: "Cancel",
      button3Label: "Clear",
      payloadSubmit: AddNationalityPayload,
      columns: columns,
      contactId: contactid,
      historyUrl: "",
    },
    nonRecord: false,
    role: userRole,
    rows: "",
    localDataStore: true,
    contactId: contactid,
    goBackPage: "/profile",
    hasPayloadfilter: true,
    hideSearch: true,
    hideModalOnSubmit: true,
  };

  return (
    <Grid container spacing={0} justify="center">
      <Grid
        item
        xs={12}
        md={9}
        spacing={10}
        style={{ maxWidth: "fit-content", marginTop: "20px" }}
      >
        {contactid && <ListWidget options={options} />}
        <Button
          size="medium"
          onClick={() => history.push("/profile")}
          style={{ marginTop: "20rem" }}
          className={classes.margin}
        >
          Go Back
        </Button>
      </Grid>
    </Grid>
  );
}

export default Nationalities;
