import React from "react";
import Cluster from "layout/Cluster";
import Menu from "./MenuListComposition";
import styled from "styled-components";
import { appmenuItemsdata} from "./model/commonMenuData";
import {appData} from "data/appData";
const MainNav = styled(Cluster)`
  margin-top: 1rem;
  color:black
`;

const SiteMenu = () => {
  const menuItems=appmenuItemsdata(appData[0].pageConfig.enableModules, false);
  return (
    <MainNav element="nav" justify="end" gap="xs">
      {menuItems.map((menu, index) => {
        return (
          <Menu menuTitle={menu.name} menuItems={menu.items} key={index} />
        );
      })}
    </MainNav>
  );
};

export default SiteMenu;
