import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";
import { brand, brandPalette } from "brand/theme/brandTheme";
import { globalSizes } from "theme/units";
// Create a theme instance.
export const globalTheme = createMuiTheme({
  typography: brand.typography,
  palette: {
    primary: {
      main: brandPalette.Primary[50],
      light: brandPalette.Primary[100],
      dark: brandPalette.Primary[200],
    },
    secondary: {
      main: brandPalette.Secondary[50],
      dark: brandPalette.Primary[300],
      light: brandPalette.Secondary[300],
      lighter: brandPalette.Secondary[400],
    },
    primaryTint: brand.palette.primaryTint,
    secondaryTint: brand.palette.secondaryTint,
    error: {
      main: brand.palette.error,
    },
    info: {
      main: brand.palette.desaturated,
    },
    warning: {
      main: brand.palette.warning,
    },
    lightBg: {
      main: "#fafafa",
    },
    midlightBg: {
      main: "#e0e0e0",
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main: "#000000",
    },
  },
  spacing: 4,
  globalSizes: { ...globalSizes },
});

/*export const globalThemenew = createMuiTheme({
  palette: {
    primary: {
      main: brandPalette.Primary[50], //cobaltBlue
      light: brandPalette.Primary[100], //bondiBlue
      dark: brandPalette.Primary[200], //frenchBlue
    },
    secondary: {
      Rhythm: brandPalette.Secondary[50],
    },
    typography: brand.typography,
    //typography: {
    // USAFABlue: "#01519B", // Typograhy Links, Typography Heading
    //blackCoral: "#585D73", // Typography
    //},
  },
});*/
