import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .statusTblheader": {
      backgroundColor: "rgba(255, 7, 0, 0.55)",
    },
  },
  snackbarSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  statusTblheader: {
    backgroundColor: "rgba(255, 7, 0, 0.55)",
  },
  snackbarError: {
    backgroundColor: theme.palette.error.main,
  },
  listSection: {
    // maxWidth: "65.6rem",
    margin: "0 auto",
    "& h5": {
      marginBottom: "3rem",
      marginLeft: "1.5rem",
    },
  },
  paidInvoiceList: {
    maxWidth: "68.4rem",
    margin: "0 auto",
  },
  formList: {
    marginTop: "20px",
  },
  formLink: {
    textDecoration: "none",
    color: theme.palette.primary.dark,
  },
  h2heading: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  listContent: {},
  primaryBtn: {
    display: "inline-block",
    border: "1px solid #000",
    color: "green",
  },
  actionsButtons: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    minHeight: "50rem",
    padding: "3rem 0rem",
    "& div[class^=MuiTabs-flexContainer]": {
      margin: "0 5rem 3rem 5rem",
    },
  },
  dueListHeader: {
    minWidth: 190,
  },
  paidListHeader: {
    minWidth: 90,
  },
  dueColumHeader: {
    minWidth: 80,
  },
  alignCenter: {
    textAlign: "center",
    minWidth: 57,
  },
  invoiceName: {
    minWidth: "22rem",
    textAlign: "center",
  },
  background1: {
    backgroundColor: "#0055a5",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "23vh",
    zIndex: 0,
  },
}));
