import env from "../environments/env";

export const msalConfig = {
  auth: {
    clientId: "b0a0a6e7-c31c-47aa-a30c-50f41a68d9c0",
    authority: "https://prodciipab2c.b2clogin.com/prodciipab2c.onmicrosoft.com",
    knownAuthorities: ["prodciipab2c.b2clogin.com"],
    redirectUri: `${env.REDIRECT_URI}`,
    postLogoutRedirectUri: `${env.REDIRECT_URI}`,
    //redirectUri: "http://localhost:3000/",
    //postLogoutRedirectUri: "http://localhost:3000/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  authority: msalConfig.auth.authority + "/B2C_1_signup",
};

export const signupRequest = {
  authority: msalConfig.auth.authority + "/B2C_1_registration",
};

export const forgotPasswordRequest = {
  authority: msalConfig.auth.authority + "/B2C_1_signup",
};

export const resetPasswordRequest = {
  authority: msalConfig.auth.authority + "/B2C_1_passReset",
};

export const logoutRequest = {
  authority: msalConfig.auth.authority + "/B2C_1_signup",
};

export const tokenRequest = {
  authority: msalConfig.auth.authority + "/B2C_1_signup",
  scopes: [
    "openid",
    "profile",
    "https://membershippowerupb2c.b2clogin.com/api/demo.read",
  ],
};

export const tokenRequestRegistration = {
  authority: msalConfig.auth.authority + "/B2C_1_registration",
  scopes: [
    "openid",
    "profile",
    "https://membershippowerupb2c.b2clogin.com/api/demo.read",
  ],
};

export const edittokenRequest = {
  authority: msalConfig.auth.authority + "/B2C_1_editProfile",
  scopes: [
    "openid",
    "profile",
    "https://membershippowerupb2c.b2clogin.com/api/demo.read",
  ],
};
