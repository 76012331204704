import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import FormWidget from "app/components/mvp/formWidget";
import {
  getemailrecordPayload,
  updateEmailPayload,
} from "../model/payload/emailPayload";
import { config } from "utilities/config";
import { useLocation } from "react-router-dom";
import { post } from "app/services/api";
import { useStyles } from "../style";
import { usePreLoader } from "app/services/hooks/usePreLoader";

import { contactSchema } from "../model/schema/emailUpdateSchema";
import { contactUiSchema } from "../model/schema/emailUpdateUISchema";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ViewEmailDetails() {
  const [recordId, setrecordId] = useState("");
  const [contact2FormData, setContact2FormData] = useState({});
  const getcontactId = useSelector((state) => state.contactid);
  let query = useQuery();
  const classes = useStyles();
  let formDataa = {};
  const { enableLoader } = usePreLoader();
  const dispatch = useDispatch();

  const getRecordDetails = (payload) => {
    let api = config.teleportal.submitForm.getId;
    if (recordId !== "") {
      enableLoader(true, "Loading Contact Details");
      post(api, payload)
        .then((res) => {
          res.data.data.forEach((item) => {
            formDataa[item.name] = item.value;
          });
          updateFormData({}, formDataa);
          //setContact2FormData(formDataa);
        })
        .catch(console.log)
        .finally(() => {
          enableLoader(false);
        });
    }
  };
  const options = {
    widgetType: "update",
    formSchema: contactSchema,
    uiSchema: contactUiSchema,
    entityType: "ctpt_channels",
    formUischema: "",
    button1Label: "Save",
    button2Label: "Go Back",
    goBackPage: "/profile/email",
    apiURL: config.teleportal.submitForm.Update,
    payloadSubmit: updateEmailPayload,
    historyUrl: "",
    contactId: getcontactId,
    formData: contact2FormData,
    button2GoBack: true,
    recordId: recordId,
  };

  const [contactOptions, setContactOptions] = useState(options);

  useEffect(() => {
    if (window.location.href.indexOf("?") > -1) {
      setrecordId(query.get("id"));
    }
  }, []);

  useEffect(() => {
    let payload = {
      ...getemailrecordPayload,
      id: recordId,
    };

    getRecordDetails(payload);
  }, [recordId]);

  const updateFormData = (entityType, formData) => {
    options.formData = formData;
    options.initialFormData = formData;
    setContactOptions(options);

    setContact2FormData(formData);
  };

  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record updated successfully",
          code: "",
          display: "show",
          severity: "success",
        },
      });
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record update failed",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item xs={12} md={9}>
        <div className={classes.componentHolder}>
          <FormWidget
            options={contactOptions}
            updateFormData={updateFormData}
            successResponse={getResponse}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default ViewEmailDetails;
