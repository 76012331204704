import React from "react";

import { profileTilesData } from "./model/data/profile.constants";
import { useProfileStyles } from "./style";

import { useMsal } from "@azure/msal-react";
import { resetPasswordRequest } from "constants/msalConfig";
import { callRedirectAPI } from "utilities/utils";
import { globalTheme } from "style/globalTheme";
import styled from "styled-components";
import { useSelector } from "react-redux";
import ProfileTileCard from "./components/profileTile";
import { Grid } from "@material-ui/core";
const Heading = styled.div`

  border-radius: 4px; 
  height: 100px;
  //padding: 1rem 1.2rem;
  
  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;


  h2 {
    max-width: max-content;   
    //margin: 4rem 2.5rem;
    margin: 0;
    font-size: 2.6rem;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 1rem;
    // border-radius: 8px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    );

     color:${globalTheme.palette.primary.main}
  }

  ${globalTheme.breakpoints.down("sm")} {
    position: relative;
    width: 100%;
    h2 {
      margin-left: 0;
      font-size: 2.5rem;
    }
  }

  ${globalTheme.breakpoints.down("xs")} {
    height: 15rem;
    h2 {
     margin-left: 1rem;
     margin-right: 1rem;
    }
  }
}
`;

export default function ProfileComponent() {
  const classes = useProfileStyles();
  const { instance } = useMsal();
  const contactid = useSelector((state) => state.contactid);
  const contactDet = useSelector((state) => state.contactDet);

  const handleChange = (val) => {
    callRedirectAPI(instance, resetPasswordRequest, true);
  };

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item xs={12} md={9}>
        <div className={classes.componentHolder}>
          <div>
            <Heading>
              <h2>Manage your Profile</h2>
            </Heading>

            <div className={classes.root}>
              <h4
                style={{
                  color: "grey",
                  textAlign: "center",
                  borderWidth: 1,
                  maxWidth: "max-content",
                  lineHeight: "1.5rem",
                  paddingTop: "2.8rem",
                }}
              >
                To change your password click{" "}
                <a className={classes.link} onClick={handleChange}>
                  here
                </a>
                , or update other profile information using the areas below.​
              </h4>
              <div className={classes.flexContainer}>
                {profileTilesData.map(
                  (
                    { title, linkText, linkURI, items, dialogue, description },
                    i
                  ) => (
                    <div className={classes.flexItems}>
                      <ProfileTileCard
                        title={title}
                        description={description}
                        linkURL={linkURI}
                        linkText={linkText}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
