import { makeStyles } from "@material-ui/core/styles";
import { brandPalette } from "brand/theme/brandTheme";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
      overflow: "visible",
    },
  },
  paymentWrapper: {
    border: "1px solid grey",
    padding: "10px",
  },
  tableDesc: {
    display: "block",
    padding: "10px 0",
  },
  tablePrice: {
    marginLeft: "auto",
    padding: "10px 0",
  },
  total: {
    borderTop: "1px solid black",
    padding: "10px 0",
    fontWeight: "800",
  },
  downloadWrapper: {
    border: "0px solid black",
    marginTop: "20px",
  },
  downloadBtn: {
    cursor: "pointer",
    color: theme.palette.primary.dark,
  },
  alert: {
    padding: theme.spacing(3),
    margin: theme.spacing(2.5),
    marginTop: theme.spacing(10),
    marginLeft: 0,
    border: `1px solid ${brandPalette["Analogous-2"]["600"]}`,
    borderWidth: "1px",
    display: "flex",
  },
  errorAlert: {
    border: `1px solid ${brandPalette["Triadic-2"]["300"]}`,
  },
  alertContent: {
    marginLeft: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  alertMessage: {
    marginBlockStart: "0.5rem",
  },
  showFlex: {
    display: "flex",
    marginBottom: "30px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  hide: {
    display: "none",
  },
  select: {
    paddingBottom: "30px",
    padding: "10px 0",
  },
  makePayment: {
    marginTop: "20px",
    marginBottom: "20px",
    paddingLeft: "30px",
  },
  buttonSection: {
    margin: "30px",
    marginLeft: 0,
    Button: {
      marginRight: "10px",
    },
  },
  noteTxt: {
    fontSize: "13px",
    fontStyle: "italic",
    paddingLeft: "30px",
  },
  itlTxt: {
    fontSize: "13px",
    fontStyle: "italic",
  },
  customLabel: {
    paddingLeft: "10px",
  },
  customRadio: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  finishBtn: {
    marginRight: "20px",
  },
}));
