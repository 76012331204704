import { GetNationalityPayload } from "app/services/payloadSubmit/getNationalityPayload";
import { config } from "utilities/config";
export const getNationalitySchema = {
  title: "Edit Contact",
  logicalname: "Contact2",
  type: "object",
  properties: {
    ctpt_nationalityid: {
      title: "Nationalities",
      type: "string",
      datatype: "array",
      enum: [],
      enumNames: [],
      isDynamic: true,
      payload: GetNationalityPayload,
      apiURL: config.teleportal.submitForm.get,
    }
  }
};
