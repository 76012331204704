import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import AppMenuItem from "./AppMenuItem";

import { appmenuItemsdata } from "./model/commonMenuData";
import { appData } from "data/appData";

const drawerWidth = "auto";

const useStyles = makeStyles((theme) =>
  createStyles({
    appMenu: {
      width: "100%",
      paddingLeft: "0px",
    },
    navList: {
      width: drawerWidth,
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
        height: "100vh",
      },
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: "#97c05c",
    },
  })
);

const AppMenu = () => {
  function resetAll(e) {
    const newState = initialState.map((_item, index) =>
      e.currentTarget.dataset.navId === index.toString() ? !open[index] : false
    );

    setOpen(newState);
  }

  const classes = useStyles();
  const initialState = [];
  const menuItems = appmenuItemsdata(
    appData[0].pageConfig.enableModules,
    false
  );

  menuItems.forEach(() => initialState.push(false));
  const [open, setOpen] = useState(initialState);

  return (
    <List component="nav" className={classes.appMenu}>
      {menuItems.map((item, index) => (
        <AppMenuItem
          {...item}
          key={index}
          listId={index}
          handleNavCollapse={resetAll}
          isOpen={open[index]}
        />
      ))}
    </List>
  );
};

export default AppMenu;
