import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  hide: {
    display: "none",
  },
  show: {
    display: "block",
  },
}));
