import { createChannelPayload } from "./channelCreatePayload";

export const WorkAddressPayload = (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.recordId,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "ctpt_line1",
        value: details[0].value || "",
        type: "string",
      },
      {
        name: "ctpt_city",
        value: details[1].value || "",
        type: "string",
      },
      {
        name: "ctpt_county",
        value: details[2].value || "",
        type: "string",
      },
    ],
  };

  if (!JsonData.recordId) {
    // INFO: theres no channel record to update, so create the channel record.
    return createChannelPayload(JsonData.contactid, 727530000, payload.data);
  }
  return payload;
};
