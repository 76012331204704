import React, { useEffect, useState } from "react";
import { useStyles } from "../style";
import { Button } from "@material-ui/core";
import { postPayment } from "app/services/api";
import DangerouslySetHtmlContent from "../../../../utilities/htmlParser";
import { config } from "utilities/config";

function PayUsingDebitCard({ payDetails, payloads }) {
  const classes = useStyles();
  const [cardContent, setCardcontent] = useState([]);
  const Authtoken = localStorage.getItem("token");
  const oId = localStorage.getItem("oid");

  const makePayement = () => {
    const payload = {
      Amount: `${payDetails?.invoiceTotal?.invoideTotal}`,
      OrderId: `${oId}`,
      Currency: "840",
      AuthToken: Authtoken,
    };

    // const payload = {
    //   TransactionIdentifier: "218ee104-a301-4c2f-a380-273cfeef61cd",
    //   TotalAmount: 1,
    //   CurrencyCode: "840",
    //   ThreeDSecure: false,
    //   Source: {},
    //   OrderIdentifier: `INT-${oId}-Orc 3569`,
    //   AddressMatch: false,
    //   ExtendedData: {
    //     ThreeDSecure: {
    //       ChallengeWindowSize: 4,
    //       ChallengeIndicator: "03",
    //     },
    //     MerchantResponseUrl:
    //       "https://zealous-water-0f1013710.3.azurestaticapps.net/application/prepayment/sucess",
    //     HostedPage: {
    //       PageSet: "Payment",
    //       PageName: "Payment",
    //     },
    //   },
    // };

    postPayment(config.payment.endPoint, payload)
      .then((res) => {
        setCardcontent(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const redirectHPP = () => {
    if (cardContent.length !== 0) {
      document.getElementById("powertranz_spi").submit();
    }
  };
  useEffect(() => {
    redirectHPP();
  }, [cardContent]);

  return (
    <div className={classes.makePay}>
      <Button
        disabled={!payDetails?.invoiceTotal}
        color="primary"
        variant="contained"
        onClick={makePayement}
      >
        Make Secure Payment Now
      </Button>

      <p className={classes.noteTxt}>
        Payment goes to a secure external site, you’ll be sent back to this site
        after payment
      </p>

      <div>
        {cardContent.length === 0 ? (
          ""
        ) : (
          <DangerouslySetHtmlContent html={cardContent} />
        )}
      </div>
    </div>
  );
}

export default PayUsingDebitCard;
