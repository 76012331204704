import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import FormWidget from "app/components/mvp/formWidget";
import {
  getidentityrecordPayload,
  updateIdentityPayload,
} from "data/payload/contactInfoPayload";
import { config } from "utilities/config";
import { useLocation, useHistory } from "react-router-dom";
import { post } from "app/services/api";
import { useStyles } from "../style";
import { usePreLoader } from "app/services/hooks/usePreLoader";
import clsx from "clsx";
import { getIdentityDetailsSchema } from "../model/schema/schema";
import { identityUiSchema } from "../model/schema/contactUpdateUiSchema";
import { IDENTITY_TYPE_MAP } from "../model/data/constants";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 2rem 8rem 4rem 8rem;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function ViewIdentityDetails() {
  const [recordId, setrecordId] = useState("");
  const [contact2FormData, setContact2FormData] = useState({});
  const getcontactId = useSelector((state) => state.contactid);
  let query = useQuery();
  let formDataa = {};
  const { enableLoader } = usePreLoader();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const getRecordDetails = (payload) => {
    let api = config.teleportal.submitForm.getId;
    if (recordId !== "") {
      enableLoader(true, "Loading Identity Details");
      post(api, payload)
        .then((res) => {
          res.data.data.forEach((item) => {
           formDataa[item.name] = item.value;
          });
          updateFormData({}, formDataa);
          //setContact2FormData(formDataa);
        })
        .catch(console.log)
        .finally(() => {
          enableLoader(false);
        });
    }
  };
  const options = {
    widgetType: "update",
    formSchema: getIdentityDetailsSchema,
    uiSchema: identityUiSchema,
    entityType: "ctpt_identities",
    formUischema: "",
    button1Label: "Save",
    button2Label: "Go Back",
    goBackPage: "/profile/identityinformation",
    apiURL: config.teleportal.submitForm.Update,
    payloadSubmit: updateIdentityPayload,
    historyUrl: "",
    contactId: getcontactId,
    formData: contact2FormData,
    button2GoBack: true,
    recordId: recordId,
  };

  const [contactOptions, setContactOptions] = useState(options);

  useEffect(() => {
    if (window.location.href.indexOf("?") > -1) {
      setrecordId(query.get("id"));
    }
  }, []);

  useEffect(() => {
    let payload = {
      ...getidentityrecordPayload,
      id: recordId,
    };

    getRecordDetails(payload);
  }, [recordId]);

  const updateFormData = (entityType, formData) => {
    options.formData = formData;
    options.initialFormData = formData;
    setContactOptions(options);
    setContact2FormData(formData);
  };

  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record updated successfully",
          code: "",
          display: "show",
          severity: "success",
        },
      });
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record update failed",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item xs={12} md={9}>
        <div className={classes.componentHolder}>
          <FormWidget
            options={contactOptions}
            updateFormData={updateFormData}
            successResponse={getResponse}
          ></FormWidget>
        </div>
      </Grid>
    </Grid>
  );
}

export default ViewIdentityDetails;
