import axios from "axios";
import { handleError } from "./handleError";
import { config } from "utilities/config";
import store from "app/services/store";
store.subscribe(listener);
const SERVER_DOMAIN = config.teleportal.env;
const SERVER_DOMAIN_ENTITLE = config.entitlement.env;
const SERVER_DOMAIN_SWF = config.sonicwebform.env;
const SERVER_DOMAIN_PAY = config.payment.env;

function select(state) {
  return state.token;
}

function listener() {
  let token = select(store.getState());
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

// HTTP GET Request - Returns Resolved or Rejected Promise
export const get = (path) => {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem("token")) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
    }

    axios
      .get(`${SERVER_DOMAIN}${path}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};

//
export const getEntitle = (path) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_DOMAIN_ENTITLE}${path}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};

export const postEntitle = (path, data, header) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_DOMAIN_ENTITLE}${path}`, data, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};

export const post = (path, data, header) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_DOMAIN}${path}`, data, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};

export const postSwf = (path, data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem("token")) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
    }
    axios
      .post(`${SERVER_DOMAIN_SWF}${path}`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};

export const postPay = (path, data) => {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem("token")) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
    }
    axios
      .post(`${SERVER_DOMAIN_PAY}${path}`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};

export const postPayment = (path, data) => {
  return new Promise((resolve, reject) => {
    /* const headers = {
      "PowerTranz-PowerTranzId": "88805581",
      "PowerTranz-PowerTranzPassword":
        "ZFC57DPlCyLSJRDwLubyzHKLsjMti6ArOfgTTUnrS01gZmCAtwjksK",
      "Content-Type": "application/json;charset=utf-8",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "Access-Control-Allow-Credentials": "true",
    };

    axios.defaults.headers.common["PowerTranz-PowerTranzId"] = "88805581";
    axios.defaults.headers.common["PowerTranz-PowerTranzPassword"] =
      "ZFC57DPlCyLSJRDwLubyzHKLsjMti6ArOfgTTUnrS01gZmCAtwjksK";
    axios.defaults.headers.common["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";*/
    axios
      .post(path, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};

export const postAll = (path, getdata, payload) => {
  let promises = [];
  getdata.forEach(function (id) {
    let pload = { ...payload, id };
    promises.push(axios.post(`${SERVER_DOMAIN}${path}`, pload));
  });

  return promises;
};
