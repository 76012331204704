import React from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import styled from "styled-components";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";
import { Button, Box } from "@material-ui/core";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 300px;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function PayLater() {
  const classes = useStyles();
  let history = useHistory();

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <StartPage>
          <SuccessIcon />
          <h1 className={classes.headingTxt}>
            Thank you. Make sure you pay within registration deadline.
          </h1>
          <div className={classes.notes}>
            <h5>How to pay?</h5>
            <p>
              In the Account menu click Invoices & Payments. In the Due Invoices
              list find the order to make payment and press the Pay button.
            </p>
          </div>
          <Box sx={{ p: 2 }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push("/")}
            >
              {" "}
              Go to homepage
            </Button>
          </Box>
        </StartPage>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default PayLater;
