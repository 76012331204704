import { GetNationalityPayload } from "app/services/payloadSubmit/getNationalityPayload";
import { config } from "utilities/config";
export const uploadCVSchema = {
  schema: {
    title: "",
    helpText: "",
    tootltipheading: "head",
    tootltipText: "text",
    type: "string",
    datatype: "upload",
    format: "string",
    requiredCheck: false,
    containerName: "contacts",
    fileName: "id",
    extensions: ".doc,.pdf",
    acceptType: [
      "application/pdf",
      "application/msword",
      "image/png",
      "image/jpeg",
    ],
    acceptExtension: [".pdf", ".doc", ".docx", ".png", ".jpeg"],
    recordPath: "",
    onChange: {},
  },
};

export const getQualificationDetailsSchema = {
  title: "Qualification Details",
  logicalname: "qualifications",
  type: "object",
  properties: {
    _mshied_studentid_value: {
      title: "StudentId",
      type: "string",
      datatype: "string",
      requiredCheck: true,
      pattern: "",
      message: "Only string allowed",
    },
    mshied_name: {
      title: "Qualification",
      type: "string",
      datatype: "string",
      requiredCheck: true,
      pattern: "",
      message: "Only string allowed",
    },
    ctpt_level: {
      title: "Level",
      type: "string",
      datatype: "string",
      requiredCheck: false,
      pattern: "",
      message: "Only string allowed",
    },
    ctpt_startdate: {
      title: "Start Date",
      type: "string",
      datatype: "number",
      requiredCheck: true,
      disableFuture: true,
    },
    ctpt_enddate: {
      title: "End Date",
      type: "string",
      datatype: "number",
      requiredCheck: false,
      disableFuture: false,
    },
  },
};

export const getEmploymentHistoryDetailsSchema = {
  title: "Employment History",
  logicalname: "employmenthistory",
  type: "object",
  properties: {
    _ctpt_employerid_value: {
      title: "Employer",
      type: "string",
      schemaname: "_ctpt_employerid_value",
      datatype: "array",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
      enum: [
        "351bf4f1-21da-ee11-904d-000d3a53dec8",
        "311bf4f1-21da-ee11-904d-000d3a53dec8",
        "371bf4f1-21da-ee11-904d-000d3a53dec8",
        "2d1bf4f1-21da-ee11-904d-000d3a53dec8",
        "d9f58278-20da-ee11-904c-000d3a19245c",
        "fdf58278-20da-ee11-904c-000d3a19245c"
       ],
      enumNames: [
         "CIG Ministry of Commerce- Planning & Infrastructure",
         "CIG Ministry of Health-Environment-Culture & Housing",
         "CIG Ministry of Investment- Innovation and Social Development",
         "CIG Ministry of Planning-Agriculture-Housing",
         "MUFG Fund Services (Cayman)",
         "Premier Fiduciary Service"
       ]
    },
    ctpt_jobtitle: {
      title: "Title",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
    },
    ctpt_businessnature: {
      title: "Business Nature",
      type: "string",
      schemaname: "ctpt_businessnature",
      datatype: "array",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
    },
    ctpt_levelofresponsibility: {
      title: "Level of responsibility",
      type: "string",
      schemaname: "ctpt_levelofresponsibility",
      datatype: "array",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
    },
    ctpt_typeofemployment: {
      title: "Type of employment",
      type: "string",
      schemaname: "ctpt_typeofemployment",
      datatype: "array",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
      enum: [
        "100000000",
        "100000001",
        "100000002",
        "533910001",
        "533910002",
        "533910003",
        "533910004",
      ],
      enumNames: [
        "Permanent",
        "Contract",
        "Fixed Contract",
        "OPRT",
        "Tuition Provider",
        "ATE",
        "Parent Institute",
      ],
    },
    ctpt_startdate: {
      title: "Start Date",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
    },
    ctpt_enddate: {
      title: "End Date",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: false,
      isReadonly: false,
      disabled: false,
    },
  },
};

export const addNewEmploymentHistoryDetailsSchema = {
  title: "Employment History",
  logicalname: "employmenthistory",
  type: "object",
  properties: {
    ctpt_professionalbody: {
      title: "Organisation Name",
      type: "string",
      schemaname: "ctpt_professionalbody",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
    },
    ctpt_jobtitle: {
      title: "Title",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
    },

    ctpt_typeofemployment: {
      title: "Type",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "array",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
      enum: ["100000000", "100000001", "100000002"],
      enumNames: ["Permanent", "Contract", "Fixed Contract"],
    },

    ctpt_startdate: {
      title: "Start Date",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
    },
    ctpt_enddate: {
      title: "End Date",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: false,
      isReadonly: false,
      disabled: false,
    },
  },
};

export const getProfessionalHistoryDetailsSchema = {
  title: "Professional History",
  logicalname: "professionalhistory",
  type: "object",
  properties: {
    ctpt_professionalbody: {
      title: "Professional Body Name",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
    },
    ctpt_jobtitle: {
      title: "Title",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
    },
    ctpt_jobdescription: {
      title: "Description",
      type: "string",
      schemaname: "ctpt_jobdescription",
      datatype: "string",
      requiredCheck: false,
      isReadonly: false,
      disabled: false,
    },
    ctpt_startdate: {
      title: "Start Date",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
    },
    ctpt_enddate: {
      title: "End Date",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: false,
      isReadonly: false,
      disabled: false,
    },
  },
};

export const addNewProfessionalHistoryDetailsSchema = {
  title: "Professional History",
  logicalname: "professionalhistory",
  type: "object",
  properties: {
    ctpt_professionalbody: {
      title: "Professional Body Name",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
    },
    ctpt_jobtitle: {
      title: "Title",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
    },
    ctpt_jobdescription: {
      title: "Description",
      type: "string",
      schemaname: "ctpt_jobdescription",
      datatype: "string",
      requiredCheck: false,
      isReadonly: false,
      disabled: false,
    },
    ctpt_startdate: {
      title: "Start Date",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
    },
    ctpt_enddate: {
      title: "End Date",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: false,
      isReadonly: false,
      disabled: false,
    },
  },
};

export const addNewQualificationDetailsSchema = {
  title: "Qualification Details",
  logicalname: "qualifications",
  type: "object",
  properties: {
    mshied_name: {
      title: "Previous Education Name",
      type: "string",

      datatype: "string",
      requiredCheck: true,
      pattern: "",
      message: "Only string allowed",
    },
    ctpt_schoolname: {
      title: "School Name",
      type: "string",
      datatype: "string",
      requiredCheck: false,
      pattern: "",
      message: "Only string allowed",
    },
    ctpt_startdate: {
      title: "Start Date",
      type: "string",
      datatype: "number",
      requiredCheck: true,
      disableFuture: true,
    },
    ctpt_enddate: {
      title: "End Date",
      type: "string",
      datatype: "number",
      requiredCheck: false,
      disableFuture: false,
    },
    mshied_educationlevelid: {
      title: "Education Level",
      type: "string",

      datatype: "string",
      requiredCheck: false,
      pattern: "",
      message: "Only string allowed",
      isDynamic: true,
      apiURL: "/api/GetAllAsync",
      payload: {
        properties: ["mshied_name", "mshied_educationlevelid"],
        filter: {
          globalfilter: "",
          globalAndOperator: false,
          fieldAndOperator: false,
          filterfields: [],
        },
        Aggregate: {
          AggregateFields: [],
        },
        sortfield: {
          field: "mshied_name",
          sorttype: "asc",
        },
        recordtype: "mshied_educationlevels",
        idfield: "mshied_code",
        continuationToken: "",
        pazesize: "1000",
      },
      enum: [],
      enumNames: [],
    },
    ctpt_grade: {
      title: "Grade",
      type: "string",
      datatype: "string",
      requiredCheck: true,
      pattern: "",
      message: "Only string allowed",
    },

    ctpt_type: {
      title: "Type",
      type: "string",
      datatype: "array",
      requiredCheck: true,
      pattern: "",
      message: "Only string allowed",
      enum: ["533910000", "533910001"],
      enumNames: ["Full Time", "Part Time"],
    },
    mshied_graduationdate: {
      title: "Graduation Date",
      type: "string",
      datatype: "string",
      requiredCheck: false,
      pattern: "",
      message: "Only string allowed",
    },
  },
};

export const getContactDetailsSchema = {
  title: "Edit Residential Address",
  logicalname: "contactdetails",
  type: "object",
  properties: {
    ctpt_addresstype: {
      title: "Address type",
      schemaname: "ctpt_addresstype",
      type: "string",
      datatype: "array",
      enum: ["727530000", "727530001", "727530002", "727530003"],
      enumNames: ["Work", "Home", "Other", "Shipping"],
    },
    ctpt_line1: {
      title: "Line 1",
      type: "string",
      schemaname: "ctpt_line1",
      datatype: "string",
      pattern: "",
      helpText: "",
      message: "",
      isReadonly: false,
      disabled: false,
    },
    ctpt_line2: {
      title: "Line 2",
      type: "string",
      schemaname: "ctpt_line2",
      datatype: "string",
      pattern: "",
      helpText: "",
      message: "",
      isReadonly: false,
      disabled: false,
    },
    ctpt_city: {
      title: "City",
      type: "string",
      schemaname: " ctpt_city",
      datatype: "string",
      pattern: "",
      helpText: "",
      message: "",
      isReadonly: false,
      disabled: false,
    },
    ctpt_county: {
      title: "County",
      type: "string",
      schemaname: "ctpt_county",
      datatype: "string",
      pattern: "",
      helpText: "",
      message: "",
      isReadonly: false,
      disabled: false,
    },
    ctpt_postalcode: {
      title: "Postal code",
      type: "string",
      schemaname: " ctpt_postalcode",
      datatype: "string",
      pattern: "",
      helpText: "",
      message: "",
      isReadonly: false,
      disabled: false,
    },
    ctpt_country: {
      title: "Country",
      type: "string",
      datatype: "array",
      enum: [],
      enumNames: [],
      isDynamic: true,
      payload: GetNationalityPayload,
      apiURL: config.teleportal.submitForm.get,
    },
  },
};

export const getIdentityDetailsSchema = {
  title: "Identity Details",
  logicalname: "ctpt_identity",
  type: "object",
  properties: {
    ctpt_type: {
      title: "Type",
      type: "string",
      schemaname: "ctpt_type",
      datatype: "array",
      enum: ["100000000","100000001","100000002"],
      enumNames: ["Citizen Identity", "Passport","Work Permit"],
    },
  
    ctpt_identitynumber: {
      title: "Number",
      type: "string",
      schemaname: "ctpt_identitynumber",
      datatype: "string",
      isReadonly: false,
      disabled: false,
    },
  },
};
