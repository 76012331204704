import React, { useState, useEffect } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useSelector, useDispatch } from "react-redux";
import ListWidget from "app/components/mvp/listWidget";
import {
  getListPayload,
  deleteListPayload,
  updateRecordStatusPayload,
  getDeclarationCompliancePayload,
  CreateMemberDeclarationrequestPayload,
  createPleadedguiltyDocAttachmentPayload,
} from "./model/payload";
import { config } from "utilities/config";
import {
  declarationComplianceSchema,
  getEvidenceSchema,
} from "./model/evidenceSchema";
import { getComplianceUiSchema } from "./model/complianceUISchema";
import {
  CreaterequestPayload,
  createDocAttachmentPayload,
} from "./model/createPayload";
import { Button, Grid } from "@material-ui/core";
import { useStyles } from "./style";
import { useHistory, useLocation } from "react-router-dom";
import { post } from "app/services/api";
import { usePreLoader } from "app/services/hooks/usePreLoader";
import FormWidget from "app/components/mvp/formWidget/FormWidget.mui";

function EvidenceDetails() {
  const contactID = useSelector((state) => state.contactid);
  const { state: params } = useLocation();
  const [editEnabled, setEditEnabled] = useState(!!params?.editable);
  const dispatch = useDispatch();
  //const { enableLoader } = usePreLoader();
  const [contactFormData, setContactFormData] = useState({});
  const [renderForm, setRenderForm] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  const onPressAddEvidence = () => {
    history.push({
      pathname: "/add-evidence",
      state: {
        id: params?.data?.ctpt_memberdeclarationid,
      },
    });
  };

  const contact = {
    widgetType: "updateContact",
    formSchema: declarationComplianceSchema(true),
    uiSchema: getComplianceUiSchema,
    formData: contactFormData,
    apiURL: config.teleportal.submitForm.Create,
    payloadSchema: {},
    entityType: "ctpt_memberdeclarations",
    formUischema: "",
    button1Label: "Submit",
    button2Label: "Go Back",
    payloadSubmit: CreateMemberDeclarationrequestPayload,
    contactId: params?.data?.ctpt_memberdeclarationid,
    goBackPage: "/cpd/declaration",
    button2GoBack: true,
    historyUrl: "",
    payloadSecondform: createPleadedguiltyDocAttachmentPayload,
    isMemberDeclaration: true,
    hasMultiplecall: true,
    hasModel: true,
  };
  const [contactOptions, setContactOptions] = useState(contact);

  const validateFormData = (formData, key1, key2) => {
    if (formData[key1] === "True" || formData[key2] === "True") {
      formData[key1] = formData[key1] === "True" ? "True" : "False";
      formData[key2] = formData[key1] === "True" ? "False" : "True";
    }
  };

  const updateFormData = (entityType, formData) => {
    validateFormData(formData, "ctpt_cpdcomplied", "ctpt_cpdnoncomplied");
    validateFormData(
      formData,
      "ctpt_compliedbicacodeofethics",
      "ctpt_notcompliedbicacodeofethics"
    );
    validateFormData(
      formData,
      "ctpt_undertakencpdbybica",
      "ctpt_undertakencpdbyifac"
    );
    if (formData.hasOwnProperty("ctpt_pleadedguilty")) {
      formData["pleadConditional"] = {
        pleadRadio: formData["ctpt_pleadedguilty"] == false ? "False" : "True",
      };
    }
    if (
      formData.hasOwnProperty("ctpt_cpdcomplied") &&
      formData.hasOwnProperty("ctpt_cpdnoncomplied") &&
      formData["ctpt_cpdcomplied"] == false &&
      formData["ctpt_cpdnoncomplied"] == false
    ) {
      formData["ctpt_cpdcomplied"] = "True";
    }
    contact.formData = formData;
    contact.initialFormData = formData;
    setContactOptions(contact);
    setContactFormData(formData);
  };

  useEffect(() => {
    const getRecordDetails = (payload) => {
      const formData = {};
      let api = config.teleportal.submitForm.getId;
      if (params?.data?.ctpt_memberdeclarationid) {
        //enableLoader(true, "Loading declaration compliance details");
        post(api, payload)
          .then((res) => {
            res.data.data.forEach((item) => {
              formData[item.name] =
                item.value === "False" || item.value === ""
                  ? false
                  : item.value;
              updateFormData({}, formData);
            });
          })
          .catch(console.log)
          .finally(() => {
            //enableLoader(false);
            setRenderForm(true);
          });
      }
    };

    getRecordDetails(
      getDeclarationCompliancePayload(params?.data?.ctpt_memberdeclarationid)
    );
  }, []);

  const viewRecord = (params) => {
    window.open(params.row.ctpt_evidenceurl, "_blank");
  };
  const viewDocument = (params) => {
    window.location.href = "/viewEvidence?id=" + params.row.ctpt_evidenceid;
  };
  const columns = [
    { field: "ctpt_evidenceid", flex: 1, hide: true },
    { field: "_ctpt_memberdeclaration_value", flex: 1, hide: true },
    { field: "ctpt_name", flex: 1, headerName: "Evidence Name" },
    {
      field: "ctpt_whatdidyoulearnandhowdidwillyouapplyit",
      flex: 1,
      headerName: "Description",
      hide: true,
    },
    {
      field: "ctpt_whendidthisactivitytakeplace",
      flex: 1,
      headerName: "Description1",
      hide: true,
    },
    {
      field: "ctpt_whydidyouchoosethisactivityandhowisitre",
      flex: 1,
      headerName: "Description2",
      hide: true,
    },
    {
      field: "ctpt_howmanyoftheseunitscouldyouverifywithsup",
      flex: 1,
      headerName: "Description3",
      hide: true,
    },
    {
      field: "ctpt_howmanyunitsareyourclaimingforthisactivit",
      flex: 1,
      headerName: "Description4",
      hide: true,
    },
    {
      field: "ctpt_evidenceurl",
      flex: 1,
      headerName: <p className={classes.btnHeader}>Evidence Document</p>,
      excludeFromSearch: true,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            className="primaryBtn"
            onClick={() => {
              viewRecord(params);
            }}
          >
            Evidence Document
          </Button>
        </div>
      ),
    },
    {
      field: "action",
      flex: 1,
      headerName: <p className={classes.btnHeader}>Action</p>,
      excludeFromSearch: true,
      renderCell: (params) => (
        <div>
          <Button
            variant="outlined"
            color="primary"
            className="primaryBtn"
            onClick={() => {
              viewDocument(params);
            }}
          >
            View Details
          </Button>
        </div>
      ),
    },
    {
      field: "ctpt_cpdhours",
      flex: 1,
      headerName: "Hours",
      hide: false,
    },
  ];

  const updateDeclarationStatus = (status = 533910001) => {
    //NOTE: 533910001 = "In progress"
    post(
      config.teleportal.submitForm.Update,
      updateRecordStatusPayload(params?.data?.ctpt_memberdeclarationid, status)
    )
      .then(() => {
        if (status === 533910004) {
          params.editable = false;
          setEditEnabled(false);
          dispatch({
            type: "SET_STATUS",
            payload: {
              message: "Your CPD Declaration Submitted Successfully",
              code: "",
              display: "show",
              severity: "success",
            },
          });
          history.push("/cpd/declaration");
        } else {
          setEditEnabled(true);
        }
      })
      .catch((err) => {
        setEditEnabled(true);
        console.log("Failed to update declaration status", err);
      });
  };

  const configuration = {
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: columns,
    searchPayload: "searchFilter",
    getlistPayload: getListPayload(params?.data?.ctpt_memberdeclarationid),
    deleteListPayload: deleteListPayload,
    listHeading: "Evidence details",
    onPressAddButton: onPressAddEvidence,
    formwidgetOptions: {
      widgetType: "add",
      formSchema: getEvidenceSchema,
      uiSchema: getComplianceUiSchema,
      apiURL: config.teleportal.submitForm.Create,
      entityType: "ctpt_supportingdocuments",
      formUischema: "",
      button1Label: "Submit",
      button2Label: "Cancel",
      button3Label: "Clear",
      payloadSubmit: CreaterequestPayload(params?.data?.id),
      columns: [],
      contactId: contactID,
      historyUrl: "",
      payloadSecondform: createDocAttachmentPayload,
      isMemberDeclaration: false,
      hasMultiplecall: true,
      hasModel: true,
    },
    onRecordEdit: updateDeclarationStatus,
    nonRecord: false,
    rows: "",
    localDataStore: false,
    hideAdd: !editEnabled,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: contactID,
    filteredValue: "",
    hasFilter: false,
    filterField: "_ctpt_customerid_value",
    filterValue: [],
    aggregateColumns: [],
    aggregateField: "",
    hasPayloadfilter: false,
    payloadModifyForbidden: true,
    hideDelete: false,
    hideSearch: false,
    enableLocalSearch: true,
    mediumHeightWidget: true,
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12} style={{ marginTop: "2rem" }}>
              {renderForm && (
                <FormWidget
                  options={contactOptions}
                  updateFormData={updateFormData}
                  successResponse={(res) => {
                    if (res.data.id) {
                      updateDeclarationStatus(533910004); //NOTE: 533910004 = Submitted
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={12} style={{ marginTop: "2rem" }}>
              <ListWidget options={configuration} />
            </Grid>
          </Grid>
        </div>
        {/* <div style={{ marginTop: "-2rem" }}>
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            onClick={() => history.push("/cpd/declaration")}
            className={clsx(classes.margin, classes.cta)}
          >
            Go Back
          </Button>
          {editEnabled && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updateDeclarationStatus(533910004); //NOTE: 533910004 = Submitted
              }}
              className={classes.cta}
            >
              Submit
            </Button>
          )}
        </div> */}
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default EvidenceDetails;
