import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { config } from "utilities/config";
import { post } from "app/services/api";
import { useStyles } from "../style";
import clsx from "clsx";
import {
  MEMBERSHIP_CLASS_MAP
} from "../model/applicationNameMap";
const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 2rem 8rem 4rem 8rem;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;
const Heading = styled.div`

  border-radius: 4px; 
  height: 100px;
  //padding: 1rem 1.2rem;
  
  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;


  h2 {
    max-width: max-content;   
    //margin: 4rem 2.5rem;
    margin: 0;
    font-size: 1.5rem;
    letter-spacing: -0.16px;
    text-transform: uppercase;
    font-weight: 700;
    
    // border-radius: 8px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    );

     color:${globalTheme.palette.primary.main};
     margin-bottom: 25px;
  }

  ${globalTheme.breakpoints.down("sm")} {
    position: relative;
    width: 100%;
    h2 {
      margin-left: 0;
      font-size: 2.5rem;
    }
  }

  ${globalTheme.breakpoints.down("xs")} {
    height: 15rem;
    h2 {
     margin-left: 1rem;
     margin-right: 1rem;
    }
  }
}
`;
function ViewRenewal() {
  const history = useHistory();

  const contactId = useSelector((state) => state.contactid);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isRenewal, setIsrenewal] = useState(false);

  const classes = useStyles();
  const contactDetails = useSelector((state) => state.contactDet);
  const renewAccount = () => {
    var payload = {
      id: contactId,
      recordtype: "contacts",
      data: [
        {
          name: "ctpt_renewalconfirmed",
          value: "true",
          type: "string",
        },
        {
          name: "ctpt_renewalconfirmedon",
          value: new Date(),
          type: "string",
        },
      ],
    };
    post(config.teleportal.submitForm.Update, payload)
      .then((res) => {
        if (res.data.id !== "") {
          setIsrenewal(true);
          setOpen(false);
          dispatch({
            type: "SET_STATUS",
            payload: {
              message: "Account renewed successfully",
              code: "",
              display: "show",
              severity: "success",
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_STATUS",
          payload: {
            message: "Renew account failed",
            code: "",
            display: "show",
            severity: "error",
          },
        });
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renewOpen = () => {
    setOpen(true);
  };
  useEffect(() => {}, []);
  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <StartPage>
              <div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <div className={classes.declareSection}>
                        <p>
                          By submitting your renewal now you agree to the
                          statements, Are you sure you want to renew your
                          account?
                        </p>
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={renewAccount} autoFocus>
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
                <Button
            size="medium"
            variant="outlined"
            onClick={() => {
              history.push("/profile");
            }}
           
          >
            Go Back
          </Button>
          <Heading>
                <h2>Membership Renewal</h2>
                </Heading>
                {Object.keys(contactDetails).length !== 0 ? (
                  <div>
                    <div className={classes.viewContainer}>
                      {contactDetails.renewalConfirm ? (
                        <div>
                          <p>
                            You are currently :
                            {contactDetails.membershipType !== ""
                              ? MEMBERSHIP_CLASS_MAP[contactDetails.membershipType]
                              : "N/A"}
                          </p>

                          {contactDetails.renewalConfirm && !isRenewal ? (
                            <p>
                              Please ensure you have completed your{" "}
                              <a href="/declarations/2">renewal declarations</a>{" "}
                              and confirm you wish to renew your membership by
                              clicking the confirm renewal button below.
                            </p>
                          ) : (
                            <p>
                              Your renewal is next due on &nbsp;
                              {contactDetails.renewalDue}, we'll notify you to
                              confirm your renewal declarations nearer this
                              time.
                            </p>
                          )}
                        </div>
                      ) : (
                        <p>
                          Your next renewal date:
                          <span className={classes.renewdate}></span>
                        </p>
                      )}

                      {contactDetails.renewalConfirm && !isRenewal ? (
                        <Button
                          variant="Contained"
                          className={clsx(classes.smallBig)}
                          onClick={() => renewOpen()}
                        >
                          Confirm Renewal
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <>Renewal done</>
                )}
              </div>
            </StartPage>
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default ViewRenewal;
