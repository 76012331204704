import React from "react";
import { Grid } from "@material-ui/core";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import ProfileComponent from "./profile";
import { globalTheme } from "style/globalTheme";
import { useSelector } from "react-redux";

function Profile() {
  const getcontactId = useSelector((state) => state.contactid);
  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <Grid container spacing={0}>
          {/* NOTE:layout */}
          {/* <Grid item xs={12} md={2}></Grid> */}
          <Grid item xs={12} md={12}>
            <ProfileComponent candidateId={getcontactId} />
          </Grid>
          {/* <Grid item xs={12} md={2}></Grid> */}
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default Profile;
