import React, { Suspense } from "react";

import { settings } from "constants/settings";
import { CircularProgress } from "@material-ui/core";

function RecordWidget(props) {
  const {
    data,
    options,
    updateFormData,
    successResponse,
    openModel,
    handleModalClose,
  } = props;

  const Comp = React.lazy(() => import("./FormWidget." + settings.UI + ".js"));

  return (
    <Suspense fallback={<CircularProgress />}>
      <Comp
        data={data}
        options={options}
        updateFormData={updateFormData}
        successResponse={successResponse}
        openModel={openModel}
        handleModalClose={handleModalClose}
      ></Comp>
    </Suspense>
  );
}

export default RecordWidget;
