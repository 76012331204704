import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Box from "../LayoutBox";
import {
  globalSizes,
  justifyMap,
  alignMap,
  alignContentMap,
} from "../../theme/units";
import { globalTheme } from "style/globalTheme";

const Container = styled(Box)((props) => {
  const childMargin =
    props.gap === "none" ? "0" : `calc(${globalSizes[props.gap]} / 2 * -1)`;
  const grandChildMargin =
    props.gap === "none" ? "0" : `calc(${globalSizes[props.gap]} / 2)`;

  return css`
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;

    ${globalTheme.breakpoints.down("xs")} {
      width: auto;
    }

    > * {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% + ${globalSizes[props.gap] ?? "0rem"});
      align-items: ${alignMap[props.align]};
      justify-content: ${justifyMap[props.justify]};
      align-content: ${alignContentMap[props.alignContent]};
      margin: ${childMargin};
    }

    > * > * {
    }
  `;
});

/**
 * ### Layout primitive component
 *
 * The **Cluster** is a container component that displays its children in rows and columns which wrap left-to-right, top-to-bottom.
 *
 * The `align` and `justify` props can be used to position child contents relative to the **Cluster** direction.
 *
 * `gap` tells the cluster how much space to apply between children.
 */
const Cluster = React.forwardRef(function Cluster(
  {
    children,
    className,
    element = "div",
    align = "stretch",
    alignContent = "stretch",
    justify = "start",
    padding = "none",
    gap = "none",
    height = "auto",
    width = "100%",
    ...rest
  },
  ref
) {
  return (
    <Container
      element={element}
      align={align}
      alignContent={alignContent}
      className={className}
      justify={justify}
      padding={padding}
      gap={gap}
      height={height}
      width={width}
      ref={ref}
      {...rest}
    >
      <div>{children}</div>
    </Container>
  );
});

Cluster.propTypes = {
  /** Any components to subscribe to the flex styling set by Cluster. */
  children: PropTypes.node,
  /** To be used to override or extend the styles on this component. */
  className: PropTypes.string,
  /** Defines the type of container element rendered to the dom. */
  element: PropTypes.string,
  /** the padding of the Cluster should take one of our globalSize constants
   *
   *  choose from : [ none | smallest | tiny | xxs | xs | small | base | medium | large | xl | xxl | largest ] */
  padding: PropTypes.oneOf(Object.keys(globalSizes)),
  /** gap between elements should take one of our globalSize constants
   *
   *  choose from : [ none | smallest | tiny | xxs | xs | small | base | medium | large | xl | xxl | largest ] */
  gap: PropTypes.oneOf(Object.keys(globalSizes)),
  /** Will align the contents on the axis perpendicular to the direction.
   *
   *  choose from : [ start | end | center | stretch ] */
  align: PropTypes.oneOf(Object.keys(alignMap)),
  /** Will align rows of content within the cluster
   *
   * choose from : [ start | end | center | stretch | between | around ] */
  alignContent: PropTypes.oneOf(Object.keys(alignContentMap)),
  /** height of the cluster (arbitrary value ie: 'auto', '100%', '40rem' etc) */
  height: PropTypes.string,
  /** Will justify the contents along the axis of the direction.
   *
   * choose from : [ start | end | center | between | around | evenly ] */
  justify: PropTypes.oneOf(Object.keys(justifyMap)),
  /** width of the cluster (arbitrary value ie: 'auto', '100%', '40rem' etc) */
  width: PropTypes.string,
};

export default Cluster;
