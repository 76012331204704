import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import FormWidget from "app/components/mvp/formWidget";

import { config } from "utilities/config";
import { post } from "app/services/api";

import { personalAddressSchema } from "../model/schema/personalAddressJson";
import { personalAddressUiSchema } from "../model/schema/personalAddressUiSchema";
import { PersonalAddressPayload } from "../model/payload/personalAddressPayload";

function PersonalAddress(props) {
  const contactid = useSelector((state) => state.contactid);
  const [contactFormData, setContactFormData] = useState({});
  const userEntitlement = useSelector((state) => state.entitleMents);
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.role);
  let permissions =
    userEntitlement &&
    userEntitlement.find((x) => x.route === props.match.path);

  const contact = {
    widgetType: "updatePersonalAddress",
    formSchema: personalAddressSchema,
    uiSchema: personalAddressUiSchema,
    formData: contactFormData,
    apiURL: config.teleportal.submitForm.Update,
    payloadSchema: {},
    entityType: "ctpt_channels",
    formUischema: "",
    button1Label:
      userRole && userRole.name && !userRole.name.includes("Individual")
        ? "Save"
        : "Submit",
    button2Label: "Go Back",
    payloadSubmit: PersonalAddressPayload,
    contactId: contactid,
    goBackPage: "/profile",
    historyUrl: "",
    recordId: "",
    button2GoBack: true,
  };

  const [contactOptions, setContactOptions] = useState(contact);

  useEffect(() => {
    if (contactid.length > 0) {
      getContactDetails();
    }
  }, [contactid]);

  const getContactDetails = () => {
    let api;
    let payloads;
    const formData = {};
    api = config.teleportal.submitForm.get;

    payloads = {
      properties: [
        "ctpt_line1",
        "ctpt_line2",
        "ctpt_city",
        "ctpt_county",
        "ctpt_postalcode",
        "ctpt_country",
        "ctpt_addresstype",
        "ctpt_channelid",
      ],
      recordType: "ctpt_channels",
      formattedFields: ["ctpt_addresstype"],
      filter: {
        globalfilter: "",
        globalAndOperator: false,
        fieldAndOperator: true,
        filterfields: [
          {
            field: "_ctpt_customerid_value",
            searchvalue: contactid,
            type: "guid",
          },
          {
            field: "ctpt_addresstype",
            searchvalue: "727530001",
            type: "guid",
          },
        ],
      },
      Aggregate: {
        AggregateFields: [],
      },
      idfield: "ctpt_channelid",
      continuationToken: "",
      pazesize: "10",
    };
    post(api, payloads)
      .then((res) => {
        res.data.data?.[0]?.data?.forEach((x) => {
          formData[x.name] = x.value || "";
        });
        contact.formData = formData;
        contact.initialFormData = formData;
        contact.recordId = res?.data?.data?.[0]?.id;
        if (!contact.recordId) {
          contact.apiURL = config.teleportal.submitForm.Create;
        }
        updateFormData({}, formData);
      })
      .catch(console.log);
  };

  const updateFormData = (_entityType, formData) => {
    if (
      userRole &&
      userRole.name &&
      userRole.name.includes("Individual") &&
      permissions &&
      permissions.permissionsets != null &&
      permissions.permissionsets.length > 0 &&
      permissions.permissionsets[0] != null &&
      permissions.permissionsets[0].canwrite == "0" &&
      permissions.permissionsets[0].cancreate == "0"
    ) {
      contact.formSchema.properties.ctpt_city.disabled = true;
      contact.formSchema.properties.ctpt_county.disabled = true;
      contact.formSchema.properties.ctpt_line1.disabled = true;
      contact.formSchema.properties.ctpt_line2.disabled = true;
      contact.formSchema.properties.ctpt_county.disabled = true;
      contact.formSchema.properties.ctpt_postalcode.disabled = true;
    }
    contact.formData = formData;
    contact.initialFormData = formData;
    setContactOptions(contact);
    setContactFormData(formData);
  };

  const getResponse = (res) => {
    if (res.data.id) {
      setContactOptions((data) => ({
        ...data,
        apiURL: config.teleportal.submitForm.Update,
        recordId: res.data.id,
      }));

      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Successfully submitted",
          code: "",
          display: "show",
          severity: "success",
        },
      });
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Submission failed",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };

  return (
    <Grid container spacing={0} justify="center">
      <Grid item xs={12} md={9}>
        <FormWidget
          options={contactOptions}
          updateFormData={updateFormData}
          successResponse={getResponse}
        />
      </Grid>
    </Grid>
  );
}

export default PersonalAddress;
