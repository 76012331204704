import CustomTextBox from "app/components/atoms/customTextBox";
import CustomSelect from "app/components/atoms/customSelect";

export const contactUiSchema = {
  "ui:title": "",
  ctpt_emailtype: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  emailaddress1: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
};

export const telephoneUiSchema = {
  "ui:title": "",
  ctpt_telephonetype: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  ctpt_telephone: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
};
