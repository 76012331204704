import { Button } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
function PayLater({ payDetails }) {
  const history = useHistory();

  return (
    <div style={{ marginLeft: "2rem", marginTop: "1rem" }}>
      <Button
        disabled={!payDetails?.invoiceTotal}
        color="primary"
        variant="contained"
        onClick={() => {
          history.push("/application/prepayment/pay-later");
        }}
      >
        Continue
      </Button>
      <p
        style={{
          fontSize: "13px",
          fontStyle: "italic",
        }}
      >
        You can make payment from the Invoices & Payments section later.
      </p>
    </div>
  );
}

export default PayLater;
