export const navbarList = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Edit Record",
    href: "/update",
  },
  {
    label: "Contact",
    href: "/contact",
  },
  {
    label: "Sonic Web Form",
    href: "/sonicform",
  },
  {
    label: "Sonic Web Form Update",
    href: "/sonicformupdate",
  },
];

export const navbarItems = [
  {
    logo: {
      imageUrl: "",
      altText: "",
      text: "Teleportal",
    },
    items: [
      {
        labelText: "Profile",
        linkUrl: "/profile",
        iconUrl: "account_circle",
      },
    ],
  },
];

export const footerItems = {
  logo: {
    imageUrl: "",
    altText: "",
    text: "Teleportal",
  },
  verticalSections: [
    {
      title: "About CIIPA",
      items: [
        {
          labelText: "About Us",
          linkUrl: "https://www.ciipa.ky/About",
        },
        {
          labelText: "CIIPA Council",
          linkUrl: "https://www.ciipa.ky/CIIPA-Council",
        },
        {
          labelText: "CIIPA Committee",
          linkUrl: "https://www.ciipa.ky/Committees",
        },
      ],
    },
    {
      title: "Useful Links",
      items: [
        {
          labelText: "Contact Us",
          linkUrl: "https://www.ciipa.ky/Contact",
        },
      ],
    },
  ],
  horizontalSection: {
    title: "",
    items: [
      {
        labelText: "Reports",
        linkUrl: "https://www.ciipa.netcluesdemo.com/ciipa-reports",
        iconUrl: "Notifications",
      },
      {
        labelText: "Privacy Policy",
        linkUrl: "https://www.ciipa.netcluesdemo.com/privacy-policy",
        iconUrl: "Notifications",
      },
      {
        labelText: "Site Map",
        linkUrl: "https://www.ciipa.netcluesdemo.com/site-map",
        iconUrl: "Notifications",
      },
    ],
  },
};
