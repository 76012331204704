import CustomTextBox from "app/components/atoms/customTextBox";
import CustomDate from "app/components/atoms/customDate";
import CustomRadio from "app/components/atoms/customRadio";

export const contactUiSchema = {
  "ui:title": "",
  salutation: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  firstname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  lastname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  fullname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_othername: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  birthdate: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  fax: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_isresidentofbotswana: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  ctpt_profiledetailsconfirmedaccurateon: {
    "ui:widget": "hidden",
  },
};
