import React, { useState } from "react";
import { settings } from "constants/settings";

function loadComponent() {
  return import("./SessionComponent." + settings.UI + ".js");
}

function SessionComponent(props) {
  const { children, data } = props;

  const [component, setComponent] = useState(null);

  loadComponent().then((x) => setComponent(x));

  if (!component) return null;
  return <component.default data={data}>{children}</component.default>;
}

export default SessionComponent;
