export const applicationNameMap = {
  individualMembership: "Individual Membership",
  firmMembership_nonaat: "Firm Application (ACCTECH)",
  firmMembership_nonaudit: "Firm Application",
  duplicateCertificate: "Duplicate Certificate",
  studentApplication: "Student Application",
  trainingemployer: " Training Employer",
  tutionProvider: "Tuition Provider",
  upgradeApplication: "Upgrade Application",
  exemptionCertificate: "Exemption Certificate",
  clearanceCertificate: "Clearance Certificate",
};

export const MEMBER_CLASS = {
  1: "Application",
  2: "Renewal",
  3: "Other",
};

export const declrationTilesData = [
  {
    title: "Membership Declarations",
    description: "Manage declaration statements relating to your membership application here.​",
    linkText: "",
    linkURI: "/declarations/1",
    dialogue: false,
  },
  {
    title: "Renewal Declarations",
    linkText: "",
    linkURI: "/declarations/2",
    description: "Manage declarations relating to your membership renewal here​",
  },
];
export const MEMBERSHIP_CLASS_MAP = {
  5: "Regular Member",
  4: "Associate Member",
  3: "Student Member",
  1: "Practitioner Member"
};
