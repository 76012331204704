import CustomTextBox from "app/components/atoms/customTextBox";
import CustomDate from "app/components/atoms/customDate";
import Upload from "app/components/atoms/upload";

export const getStudentUiSchema = {
  "ui:title": "",
  ctpt_name: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },

  ctpt_startdate: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  ctpt_duration: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_enddate: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  ctpt_studenttrainingagreementid: {
    "ui:widget": "hidden",
  },
  document: {
    "ui:widget": (props) => {
      return (
        <div style={{ marginTop: -16, marginBottom: 20 }}>
          <Upload
            recordType={props.schema.containerName}
            recordPath={props.schema.recordPath}
            getProps={props}
          />
        </div>
      );
    },
  },
};
