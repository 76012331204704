import moment from "moment";
export const getrecordPayload = {
  properties: [
    "ctpt_statement",
    "ctpt_memberdeclarationtype",
    "_ctpt_initialstatementid_value",
    "_ctpt_member_value",
    "ctpt_comment",
    "ctpt_declarationagreedto",
    "ctpt_declarationsource",
    "ctpt_declarationsubmittedon",
    "ctpt_memberdeclarationsid",
  ],
  formattedFields: [],
  recordType: "ctpt_memberdeclarationses",
  id: "",
};

export const getdocPayload = {
  properties: [
    "ctpt_caseid",
    "ctpt_casenumber",
    "ctpt_title",
    "ctpt_details",
    "createdon",
    "statuscode",
    "modifiedon",
    "statecode",
    "ctpt_caseresolutiondetails",
  ],
  formattedFields: [],
  recordType: "ctpt_evidences",
  id: "",
};

export const getlistPayload = (id, type) => {
  return {
    properties: [
      "ctpt_statement",
      "ctpt_memberdeclarationtype",
      "_ctpt_initialstatementid_value",
      "_ctpt_member_value",
      "ctpt_comment",
      "ctpt_declarationagreedto",
      "ctpt_declarationsource",
      "ctpt_declarationsubmittedon",
      "ctpt_memberdeclarationsid",
    ],
    formattedFields: [],
    filter: {
      globalfilter: "",
      globalAndOperator: false,
      fieldAndOperator: true,

      filterfields: [
        {
          field: "_ctpt_member_value",
          searchvalue: id,
          type: "guid",
        },
        {
          field: "ctpt_memberdeclarationtype",
          searchvalue: type,
        },
      ],
    },
    sortfield: {
      field: "ctpt_order",
      sorttype: "asc",
    },
    Aggregate: { AggregateFields: [] },
    recordtype: "ctpt_memberdeclarationses",
    idfield: "ctpt_memberdeclarationsid",
    continuationToken: "",
    pazesize: "10",
  };
};

export const updateDeclaration = (idFiled, comment, agreedTo) => {
  return {
    id: idFiled,
    recordtype: "ctpt_memberdeclarationses",
    data: [
      {
        name: "ctpt_comment",
        value: comment,
        type: "string",
      },
      {
        name: "ctpt_declarationsource",
        value: "1",
        type: "string",
      },
      {
        name: "ctpt_declarationagreedto",
        value: agreedTo !== null ? true : false,
        type: "bool",
      },
      {
        name: "ctpt_declarationsubmittedon",
        value: moment.utc(new Date()).format("DD MMM YYYY, h:mm"),
        type: "string",
      },
    ],
  };
};
