import React from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { Grid } from "@material-ui/core";
import { globalTheme } from "style/globalTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import Button from "app/components/atoms/Button/RoundCornerButton";
import { useStyles } from "./style";

const ErrorPage = () => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div id="cTTP_sonicForm" className={classes.container}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <h1>Something's wrong here...</h1>
              <p>Please try again....</p>
              <Button
                variant="contained"
                onClick={() => (window.location.href = "/")}
                style={{
                  marginLeft: "1.5rem",
                  backgroundColor: globalTheme.palette.primary.dark,
                }}
              >
                Back to Homepage
              </Button>
            </Grid>
          </Grid>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
};

export default ErrorPage;
