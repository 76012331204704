import CustomTextBox from "app/components/atoms/customTextBox";

export const postalAddressUiSchema = {
    "ui:title": "",
    ctpt_line1: {
        "ui:widget": props => {
            return <CustomTextBox getProps={props} />;
        }
    },
    ctpt_line2: {
        "ui:widget": props => {
            return <CustomTextBox getProps={props} />;
        }
    }

};
