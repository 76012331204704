import React, { useState, useEffect } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useStyles } from "./style";
import { Grid } from "@material-ui/core";

import { useSelector } from "react-redux";
import { config } from "utilities/config";

import { Button } from "@material-ui/core";
import Sonicwebform from "app/components/mvp/sonicwebform";
import { uiSchema } from "./model/UiSchema";
import CreateOrder from "brand/components/order";
import { break_addressbar } from "utilities/splitUrl";

import {
  getFormschemapayload,
  createFormsubmitpayload,
  getFormsubmitpayload,
} from "data/payload";
import styled from "styled-components";

const StartPage = styled.section`
  li {
    //margin-top: 1rem;
    p {
      margin: 0;
      padding: 0;
    }
  }
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function ExamRegister(props) {
  const classes = useStyles();
  const role = useSelector((state) => state.role);
  const contactID = useSelector((state) => state.contactid);
  const [isStarted, setStartApplication] = useState(0);
  const [eventDetail, setEventDetail] = useState({});
  const [urlParam, seturlParam] = useState({
    formName: "eventRegistration",
    subId: "",
  });
  const [isNew, setisNew] = useState(true);

  const options = {
    formTitle: "Event Registration",
    getFormschemaURL: config.sonicwebform.getFormschema,
    createFormsubmitURL: config.sonicwebform.createFormsubmit,
    getFormsubmitURL: config.sonicwebform.getFormsubmit,
    getFormschemapayload: getFormschemapayload,
    createFormsubmitpayload: createFormsubmitpayload,
    getFormsubmitpayload: getFormsubmitpayload,
    WebFormName: urlParam.formName,
    uiSchema: uiSchema,
    submissionId: "",
    stepCount: 0,
    formschema: {},
    swfdata: {},
    swfId: "",
    createForm: isNew,
    unAuthenticate: false,
    handleChange: props.handleChange,
    emptyOrder: false,
    hasPayment: true,
    hasMultipayment: true,
  };
  const startApp = () => {
    setStartApplication(1);
  };

  useEffect(() => {
    localStorage.setItem("oid", "");
    setEventDetail(JSON.parse(localStorage.getItem("evnts")));

    if (window.location.href.indexOf("?") > -1) {
      seturlParam(break_addressbar(window.location.href));

      //setisNew(false);
    } else {
      //setisNew(true);
    }
  }, []);
  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <div className={classes.container}>
              {isStarted === 0 ? (
                <StartPage>
                  <h1>EVENT REGISTRATION APPLICATION</h1>

                  <div className={classes.infoBlock}>
                    <h3>{eventDetail.name}</h3>
                    <ul>
                      <li>{eventDetail.desc}</li>
                    </ul>
                  </div>

                  <br />
                  <Button
                    variant="contained"
                    onClick={startApp}
                    className={classes.regBtn}
                  >
                    {isNew ? "Register" : "Resume Application"}
                  </Button>
                </StartPage>
              ) : (
                <div>
                  <Sonicwebform options={options} />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default CreateOrder(ExamRegister);
