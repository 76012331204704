import local from "./env.local";
import development from "./env.dev";
import production from "./env.prod";

let config;
if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
  config = production;
} else if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
  config = development;
} else if (process.env.REACT_APP_ENVIRONMENT === "LOCAL") {
  config = local;
} else {
  config = development;
}
export default {
  // Add common config values below
  ...config,
};
