import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},
  headingTxt: {
    color: theme.palette.warning.light,
  },
  errorIcon: {
    color: "red",
  },
  desc: {
    padding: "0px",
  },
}));
