import React from "react";

import { Card, CardContent, Typography } from "@material-ui/core";
import styled, { css } from "styled-components";

import { globalTheme } from "style/globalTheme";
import { brandPalette } from "brand/theme/brandTheme";
import { useProfileStyles } from "../style";

const ProfileTileCard = ({
  title,
  description,
  headingLevel = "h5",
  items,
  itemLinks,
  imageUrl,
  altTxt,
  linkURL = "#",
  linkText,
  hasDialogue,
  colour = globalTheme.palette.secondary.main,
  variant = "DEFAULT",
  handleChange,
}) => {
  const classes = useProfileStyles();
  return (
    <div className={classes.profileTileBox}>
      <div>
        <h4>{title}</h4>
        <p>{description}</p>
        <a
          href={linkURL}
          style={{ cursor: "pointer" }}
          alt={linkText}
          className={classes.profileTileBtn}
        >
          {linkText !== "" ? linkText : "Update"}
        </a>
      </div>
    </div>
  );
};

export default ProfileTileCard;
