//Import React Library
import React from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";

//Import Material-UI Library
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import styled from "styled-components";
import { brandPalette } from "brand/theme/brandTheme";

const CustomTab = styled(Tab)`
  cursor: pointer;
  color: ${brandPalette.Primary[900]};
  ${(props) => {
    return (
      props.selected &&
      `
      transition: all .2s;
       background-color: ${brandPalette.Primary[100]};
       color: ${brandPalette.Primary[900]};
       &::before {
         content: '';
         height: .9rem;
         width: .9rem;
         color: #fff;
         background-color: ${brandPalette.Secondary[50]};
         border-radius: 50%;
         overflow: hidden;
         margin-right: 8px;
       }
     `
    );
  }};
  padding: 10px 14px;
  margin: 6px 6px;
  border: none;
  border-radius: 36px;
  display: flex;
  justify-content: center;
  //box-shadow: 2px 5px 10px #e4e4e4;
  opacity: 0.85;
  transition: all 0.2s;

  font-weight: bold;

  &:hover {
    transform: scale(1.05);
  }
  &:focus {
    background-color: ${brandPalette.Primary[100]};
    color: ${brandPalette.Primary[900]};
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      borderRadius: "25px",
    },
  },
  table: {
    minWidth: 650,
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    borderRadius: "25px",
  },
  tabStyle: {
    marginLeft: "0rem",
    marginBottom: "1rem",
    borderRadius: "15px",

    "& div[class^=MuiTabs-flexContainer]": {
      backgroundColor: brandPalette["Secondary"]["50"],
      // boxShadow: "2px 5px 10px #e4e4e4",
      width: "max-content",
      borderRadius: "2rem",
    },
    "& .MuiTabs-flexContainer": {
      background: brandPalette["Primary"]["100"],
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        "& button": { maxWidth: "100%" },
      },
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        "& button+button::before": {
          marginLeft: "-12px",
          marginRight: "12px",

          content: "'|'",
        },
      },
    },
  },
}));

function TabWidget(props) {
  const classes = useStyles();
  const [selectedTabID, setSelectedTabID] = React.useState(
    props.options.defaultTabID
  );
  const { options } = props;
  var list = options.tabNavJson;
  const handleChange = (_event, newID) => {
    setSelectedTabID(newID);
  };
  return (
    <div className={classes.root}>
      <AuthenticatedTemplate>
        <div class={classes.container}>
          <Tabs
            value={selectedTabID}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
            TabIndicatorProps={{ style: { display: "none" } }}
            className={classes.tabStyle}
          >
            {list.map(({ label, id }) => {
              return (
                <CustomTab
                  value={id}
                  label={label}
                  key={id}
                  wrapped
                  {...a11yProps(id)}
                />
              );
            })}
          </Tabs>
          {list.map((listItem) => {
            return (
              <TabPanel
                value={listItem.id}
                options={listItem.options}
                index={selectedTabID}
                key={listItem.id}
              >
                {listItem.entity}
              </TabPanel>
            );
          })}
        </div>
      </AuthenticatedTemplate>
    </div>
  );
}

export default React.memo(TabWidget);
