import React from "react";
import styled from "styled-components";
import { Typography, Grid } from "@material-ui/core";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import { globalTheme } from "style/globalTheme";
import PropTypes from "prop-types";

const Container = styled.div`
  padding: 1rem;
  background-color: #ffffff;
  ${globalTheme.breakpoints.up("md")} {
    border: 1px solid #808080;
    border-radius: ${globalTheme.globalSizes.small};
  }
`;

const Title = styled(Typography)``;
const Description = styled(Typography)`
  margin-bottom: 0.5rem;
`;

export default function Preference(props) {
  const onChangePreference = (id, value) => {
    if (props.onChange) {
      props.onChange(id, value);
    }
  };

  const maxItemsInOneRow = props.maxItemsInOneRow || 3;

  return (
    <Container>
      <Title variant="h6">{props.title}</Title>
      <Description variant="subtitle1">{props.description}</Description>
      <Grid container>
        {props.preferences?.map(({ id, label, value }) => (
          <Grid item xs={12} md={12 / maxItemsInOneRow}>
            <CustomCheckBox
              getProps={{
                value,
                schema: {},
                label,
                onChange: (newValue) => onChangePreference(id, newValue),
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

Preference.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  /** Array of objects each describing individual preference. id(unique identifier for preference), label(label for the preference), value(preference state)  */
  preferences: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.bool,
    })
  ),
  /** Will be called with preference id and preference value, whenever any preference's state changes */
  onChange: PropTypes.string,
  /** Maximum preference options to be rendered in single row */
  maxItemsInOneRow: PropTypes.number,
};
