//Import React Library
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useStyles } from "../style";
//Loading components
import { Button } from "@material-ui/core";
import { config } from "utilities/config";
import { Grid } from "@material-ui/core";
import ListComponent from "app/components/mvp/listWidget";
import moment from "moment";
//import { DECLARATION_STATUS_MAP } from "./model/constants";

import { getEmploymentHistoryDetailsSchema } from "../model/schema/schema";
import { viewEmploymentHistoryDetailsUiSchema } from "../model/schema/viewemploymenthistorydetailsuiSchema";
import {
  addNewEmploymentDetailsPayload,
  viewexemptedsPayload,
  getemploymentlistPayload,
} from "../model/payload/payload";

function EmploymentHistory() {
  const classes = useStyles();
  const history = useHistory();
  const getcontactId = useSelector((state) => state.contactid);
  const getcontactName = useSelector((state) => state.user);

  const userRole = useSelector((state) => state.role);

  const onClickViewDetails = (params) => {
    window.location.href =
      "/profile/viewemploymenthistorydetails?id=" +
      params.row.ctpt_professionalhistoryid;
  };

  const columns = [
    {
      field: "_ctpt_employerid_value",
      flex: 1.5,
      headerName: "Employer",
      hide: false,
    },
    {
      field: "ctpt_jobtitle",
      flex: 1,
      headerName: "Job Title",
      hide: false,
    },

    {
      field: "ctpt_businessnature",
      flex: 1.5,
      headerName: "Business Nature",
      hide: false,
    },
    {
      field: "ctpt_levelofresponsibility",
      flex: 1,
      headerName: "Level",
      hide: false,
      
    },
    {
      field: "ctpt_startdate",
      flex: 1.5,
      headerName: "Start Date",
      hide: false,
      renderCell: (params) => {
        return (
          <span>
            {moment.utc(params.row.ctpt_startdate).format("DD MMM YYYY")}
          </span>
        );
      },
    },
    {
      field: "ctpt_enddate",
      flex: 1.5,
      headerName: "End Date",
      hide: false,
      renderCell: (params) => {
        return (
          <span>
            {moment.utc(params.row.ctpt_enddate).format("DD MMM YYYY")}
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: <p className={classes.paidListHeader}>Action</p>,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={classes.actionsButtons}>
            <Button
              variant="outlined"
              color="primary"
              className="primaryBtn"
              onClick={() => {
                onClickViewDetails(params);
              }}
              disabled={false}
            >
              {userRole &&
              userRole.name &&
              !userRole.name.includes("Individual")
                ? "View & Update"
                : "View"}
            </Button>
          </div>
        );
      },
    },
  ];

  const options = {
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: columns,
    searchPayload: "searchFilter",
    getlistPayload: getemploymentlistPayload(getcontactId),
    deleteListPayload: {},
    listHeading: "Employment Details",
    formwidgetOptions: {
      widgetType: "add",
      formSchema: getEmploymentHistoryDetailsSchema,
      uiSchema: viewEmploymentHistoryDetailsUiSchema,
      apiURL: config.teleportal.submitForm.Create,
      entityType: "ctpt_professionalhistories",
      formUischema: "",
      button1Label: "Create",
      button2Label: "Cancel",
      button3Label: "Clear",
      payloadSubmit: addNewEmploymentDetailsPayload(getcontactId),
      columns: [],
      contactId: getcontactId,
      contactName: getcontactName.name,
      historyUrl: "",
      hasMultiplecall: true,
      hasModel: true,
    },
    nonRecord: false,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    formatStaticRow: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "_ctpt_employeeid_value",
    filterValue: [getcontactId],
    aggregateField: "",
    hideSearch: true,
    pageSize: 6,
    hasPayloadfilter: true,
    hideAdd: false,
    controlLists: false,
  };

  const options1 = {
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: columns,
    searchPayload: "searchFilter",
    getlistPayload: viewexemptedsPayload(getcontactId),
    deleteListPayload: {},
    listHeading: "Exempted Modules",
    nonRecord: false,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "",
    filterValue: [],
    aggregateField: "",
    hideSearch: true,
    pageSize: 6,
    hasPayloadfilter: false,
    hideAdd: false,
    controlLists: false,
  };

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item xs={12} md={9}>
        <div className={classes.componentHolder}>
          <Button
            size="medium"
            variant="outlined"
            onClick={() => {
              history.push("/profile");
            }}
            style={{ marginBottom: "1.5rem" }}
          >
            Go Back
          </Button>
          <ListComponent options={options} />
        </div>
      </Grid>
    </Grid>
  );
}

export default EmploymentHistory;
