export const getEventSchedulePayload = (eventID) => ({
  properties: [
    "msmedia_eventscheduleid",
    "_msmedia_mediaevent_value",
    "msmedia_name",
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: false,
    filterfields: [
      {
        field: "_msmedia_mediaevent_value",
        searchvalue: eventID,
        type: "lookup",
      },
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "msmedia_eventschedules",
  idfield: "msmedia_eventscheduleid",
  continuationToken: "",
  pazesize: "10",
});

export const getAttractionSchedulePayload = (eventScheduleIds) => {
  const filters = eventScheduleIds.map((scheduleID) => ({
    field: "_msmedia_eventschedule_value",
    searchvalue: scheduleID,
    type: "lookup",
  }));

  return {
    properties: [
      "msmedia_attractionscheduleid",
      "msmedia_enddatetime",
      "_msmedia_eventschedule_value",
      "msmedia_name",
      "msmedia_startdatetime",
    ],
    formattedFields: [],
    filter: {
      globalfilter: "",
      globalAndOperator: false,
      fieldOrOperator: true,
      filterfields: filters,
    },
    Aggregate: {
      AggregateFields: [],
    },
    recordtype: "msmedia_attractionschedules",
    idfield: "msmedia_attractionscheduleid",
    continuationToken: "",
    pazesize: "10",
  };
};

export const updateEventRegPayload = (recordID) => ({
  id: recordID,
  recordtype: "msmedia_mediaregistrations",
  data: [
    {
      name: "msmedia_registrationstatus",
      value: 766860005,
      type: "string",
    },
  ],
});

export const getSupportingDocPayload = (eventID) => ({
  properties: [
    "_ctpt_eventid_value",
    "ctpt_name",
    "ctpt_supportingdocumentid",
    "ctpt_documenturl",
    "ctpt_documenttype",
    "ctpt_documentdate",
    "ctpt_description",
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: true,
    filterfields: [
      {
        field: "_ctpt_eventid_value",
        searchvalue: eventID,
        type: "guid",
      },
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "ctpt_supportingdocuments",
  idfield: "ctpt_SupportingDocumentId",
  continuationToken: "",
  pazesize: "10",
});

export const getSpeakerEngPayload = (eventID) => ({
  properties: [
    "ctpt_speakerengagementid",
    "ctpt_speakerengagement",
    "_ctpt_speaker_value",
    "_ctpt_eventid_value",
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: true,
    filterfields: [
      {
        field: "_ctpt_eventid_value",
        searchvalue: eventID,
        type: "guid",
      },
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "ctpt_speakerengagements",
  idfield: "ctpt_speakerengagementid",
  continuationToken: "",
  pazesize: "10",
});

export const getSpeakersPayload = (speakerIDs = []) => {
  if (speakerIDs?.length <= 0) {
    return null;
  }

  const filterFields = speakerIDs.map((id) => ({
    field: "ctpt_speakerid",
    searchvalue: id,
    type: "guid",
  }));

  return {
    properties: [
      "ctpt_about",
      "ctpt_accomplishment",
      "ctpt_blog",
      "_ctpt_contactid_value",
      "ctpt_linkedin",
      "ctpt_publications",
      "ctpt_speakerid",
      "ctpt_twitter",
      "ctpt_website",
      "ctpt_speaker",
    ],
    formattedFields: [],
    filter: {
      globalfilter: "",
      globalAndOperator: false,
      fieldOrOperator: true,
      filterfields: filterFields,
    },
    Aggregate: {
      AggregateFields: [],
    },
    recordtype: "ctpt_speakers",
    idfield: "ctpt_speakerid",
    continuationToken: "",
    pazesize: "10",
  };
};

export const getSWFSubmissionIDPayload = (contactID, swfID) => ({
  submissionId: "",
  formId: swfID,
  contactId: contactID,
  webFormName: "examreg",
  isSubmit: false,
  isCancel: false,
  data: {},
  formName: "examreg",
});
