import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import NavBar from "app/components/molecules/navbar/NavBar.mui";
import Statusbar from "app/components/atoms/statusbar/Statusbar.mui";
import { useStyles } from "./style";
import { useSelector } from "react-redux";

const PublicRoute = ({
  component: Component,
  restricted,
  siteNav,
  ...rest
}) => {
  const accountStatus = useSelector((state) => state.accountStatus);
  const isAuthenticated = useIsAuthenticated();

  const classes = useStyles();

  const renderRoute = (props) => {
    if (
      accountStatus === "Inactive" &&
      props.location.pathname !== "/inactive" &&
      isAuthenticated
    ) {
      return <Redirect to={"/inactive"} />;
    }
    return isAuthenticated && restricted ? (
      <Redirect to="/" />
    ) : (
      <Component {...props} />
    );
  };

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <div className={classes.content}>
      <NavBar siteNav={siteNav} />
      <div className={classes.withoutNav}>
        <Statusbar />

        <Route {...rest} render={renderRoute} />
      </div>
    </div>
  );
};

export default PublicRoute;
