import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { config } from "utilities/config";
import {
  getFormschemapayload,
  createFormsubmitpayload,
  getFormsubmitpayload,
} from "data/payload";
import Sonicwebform from "app/components/mvp/sonicwebform";
import { uiSchema } from "app/pages/firmmembership/model/uiSchema";
import styled from "styled-components";
import { Button, Grid } from "@material-ui/core";
import CreateOrder from "brand/components/order";
import { break_addressbar } from "utilities/splitUrl";
import { useStyles } from "./style";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function FirmMembership(props) {
  const [isStarted, setStartApplication] = useState(0);
  const [isNew, setisNew] = useState(true);
  const URL = window.location.href;
  const [urlParam, seturlParam] = useState({
    formName: window.location.href.includes("aat")
      ? "firmMembership"
      : "firmMembership",
    subId: "",
  });
  const classes = useStyles();
  const options = {
    formTitle: "Firm Application",
    getFormschemaURL: config.sonicwebform.getFormschema,
    createFormsubmitURL: config.sonicwebform.createFormsubmit,
    getFormsubmitURL: config.sonicwebform.getFormsubmit,
    getFormschemapayload: getFormschemapayload,
    createFormsubmitpayload: createFormsubmitpayload,
    getFormsubmitpayload: getFormsubmitpayload,
    WebFormName: urlParam.formName,
    uiSchema: uiSchema,
    submissionId: urlParam.subId,
    stepCount: 0,
    formschema: {},
    swfdata: {},
    swfId: "",
    createForm: isNew,
    unAuthenticate: false,
    handleChange: props.handleChange,
    emptyOrder: true,
    hasPayment: true,
    hasMultipayment: false,
  };

  const startApp = () => {
    setStartApplication(1);
  };
  useEffect(() => {
    const formName = window.location.href.includes("firmMembership_nonaat")
      ? "firmMembership"
      : "firmMembership";

    localStorage.setItem("oid", "");

    if (window.location.href.indexOf("?") > -1) {
      seturlParam(break_addressbar(window.location.href));

      setisNew(false);
    } else {
      setisNew(true);
    }
    seturlParam((urlParam) => ({ ...urlParam, formName }));
  }, [URL]);

  useEffect(() => {
    console.log("url parameters" + JSON.stringify(urlParam));
  }, [urlParam]);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div id="cTTP_sonicForm" className={classes.container}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              {/* <Sonicwebform options={options} /> */}
              {isStarted === 0 ? (
                <StartPage>
                  <h1>FIRM APPLICATION</h1>
                  <p style={{ fontWeight: "700", marginTop: "2rem" }}>
                    Kindly NOTE that you will be required to attach the
                    following documents during the application.
                  </p>
                  <div className={classes.infoBlock}>
                    <h3>Accounting Service Provider – Registration</h3>
                    <ul>
                      <li>
                        Certified copy of registration or certificate of
                        incorporation, and change of name certificate (as
                        applicable)e
                      </li>
                      <li>Copy of Trade & Business license</li>

                      <li>Business plan or Business overview letter</li>
                      <li>Signed services declaration</li>
                      <li>
                        Written confirmation that a senior employee is
                        registered on the Financial Reporting Unit’s online
                        platform to receive sanctions notices and file
                        suspicious activity reports{" "}
                      </li>
                    </ul>
                  </div>
                  <div className={classes.infoBlock}>
                    <h3>
                      {" "}
                      If conducting “relevant financial business” as defined ,
                      please also submit:
                    </h3>
                    <ul>
                      <li>Business Risk Assessment</li>
                      <li>AML, policies, and procedures manual</li>
                      <li>
                        Fit & Proper Questionnaire, and accompanying documents
                        for ALMCO and MLRO, controlling persons. If CIMA
                        registered, submit CIIPA PQ and CIMA approval letter.
                      </li>
                    </ul>
                  </div>
                  <div className={classes.infoBlock}>
                    <h3>Public Practice Firm – Application</h3>
                    <ul>
                      <li>
                        Certified copy of registration or certificate of
                        incorporation, and change of name certificate (as
                        applicable)
                      </li>
                      <li>
                        Registers of controlling persons (members, directors,
                        managers, partners), looking through to natural persons
                      </li>
                      <li>
                        Name and license number of all licensed practitioners
                        employed by the firm
                      </li>
                      <li>
                        Copy of DCI licenses (Trade & Business and Local
                        Companies Control Licenses)
                      </li>
                      <li>Copy of CIMA license</li>
                      <li>
                        Copy of business plan, inclusive of staffing plan and
                        staff structure chart
                      </li>
                      <li>Group structure chart (as applicable)</li>
                      <li>Business Risk Assessment</li>
                      <li>AML, policies, and procedures manual</li>
                      <li>
                        Fit & Proper Questionnaire, and accompanying documents
                        for ALMCO and MLRO, controlling persons. If CIMA
                        registered, submit CIIPA PQ and CIMA approval letter
                      </li>
                      <li>
                        Written confirmation of the value of professional
                        indemnity insurance, signed by appropriate controlling
                        persons
                      </li>
                      <li>
                        Written undertaking by the firm’s controlling persons to
                        be individually bound by the disciplinary provisions of
                        relevant Cayman Islands laws and regulation
                      </li>
                      <li>
                        Written confirmation that the AMLCO, MLRO, DMLRO are
                        registered on the Financial Reporting Unit’s online
                        platform to receive sanctions notices and file
                        suspicious activity reports
                      </li>
                    </ul>
                  </div>

                  <p style={{ fontStyle: "italic" }}>
                    Supported file types:- PDF, JPG, JPEG, PNG{" "}
                  </p>

                  <br />
                  <Button
                    variant="contained"
                    onClick={startApp}
                    className={classes.regBtn}
                  >
                    {isNew ? "Start Application" : "Resume Application"}
                  </Button>
                </StartPage>
              ) : (
                <div>
                  <Sonicwebform options={options} />
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default CreateOrder(FirmMembership);
