import React, { useEffect, useState, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
} from "@material-ui/core";

import Upload from "app/components/atoms/upload";
import { usePreLoader } from "app/services/hooks/usePreLoader";

import { post } from "app/services/api";
import { config } from "utilities/config";
import { formatData } from "utilities/datatable";

import {
  deleteSupportingDocPayload,
  getSupportingDocPayload,
  updateSupportingDocPayload,
  uploadSupportingDocPayload,
} from "../model/payload/uploadCvpayload";
import { uploadCVSchema } from "../model/schema/uploadCvschema";
import { createDocAttachmentPayload } from "../model/payload/createPayload";

const { log } = console;

function UploadCV() {
  const contactID = useSelector((state) => state.contactid);
  const selectedDocURL = useSelector((state) => state.docUrl);
  const base64Doc = useSelector((state) => state.base64Doc);
  const userRole = useSelector((state) => state.role);

  const dispatch = useDispatch();
  const { enableLoader } = usePreLoader();

  const [uploadedCV, setUploadedCV] = useState({});
  const [enableUpdateButton, setEnableUpdateButton] = useState(false);
  const [showDocDeleteAlert, setShowDocDeleteAlert] = useState(false);
  let history = useHistory();

  const uploadSchema = {
    ...uploadCVSchema,
    onChange: () => setEnableUpdateButton(true),
    onPressDelete: () => {
      setEnableUpdateButton(!!uploadedCV.id);
    },
    stateProps: {
      uriValue: uploadedCV.ctpt_documenturl || "",
      selectedFilename: (uploadedCV.ctpt_name || "").replace("SD-CV-", ""),
    },
  };

  const loadSupportingDocs = useCallback(async () => {
    if (contactID) {
      enableLoader(true);
      post(config.teleportal.file.list, getSupportingDocPayload(contactID))
        .then((response) => {
          if (response.data) {
            setUploadedCV(formatData(response.data)[0] || {});
          }
        })
        .catch((err) => {
          log("Supporting Document fetch failed:", err);
        })
        .finally(() => {
          enableLoader(false);
        });
    }
  }, [contactID, enableLoader]);

  const deleteSupportingDoc = () => {
    enableLoader(true);
    post(
      config.teleportal.submitForm.delete,
      deleteSupportingDocPayload(uploadedCV.id)
    )
      .then(() => {
        dispatch({
          type: "SET_STATUS",
          payload: {
            message: "Successfully Deleted",
            code: "",
            display: "show",
            severity: "success",
          },
        });
        setEnableUpdateButton(false);
        setShowDocDeleteAlert(false);
        loadSupportingDocs();
      })
      .catch((err) => {
        console.log(err);
        enableLoader(false);
        setShowDocDeleteAlert(false);
        dispatch({
          type: "SET_STATUS",
          payload: {
            message: "Unable to delete, Please try again Later...",
            code: "",
            display: "show",
            severity: "error",
          },
        });
      });
  };

  const updateRecord = useCallback(() => {
    if (uploadedCV.id && !base64Doc.fileName) {
      return setShowDocDeleteAlert(true);
    }
    enableLoader(true);
    const api = uploadedCV.id
      ? config.teleportal.submitForm.Update
      : config.teleportal.submitForm.Create;
    const payload = uploadedCV.id
      ? updateSupportingDocPayload(
          uploadedCV.id,
          base64Doc.fileName,
          selectedDocURL
        )
      : uploadSupportingDocPayload(
          selectedDocURL,
          base64Doc.fileName,
          contactID
        );
    post(api, payload)
      .then((res) => {
        if (res.data.id) {
          post(
            config.teleportal.submitForm.Create,
            createDocAttachmentPayload(base64Doc, { id: res.data.id })
          ).finally(() => {
            dispatch({
              type: "SET_STATUS",
              payload: {
                message: "Successfully updated",
                code: "",
                display: "show",
                severity: "success",
              },
            });
            setEnableUpdateButton(false);
            loadSupportingDocs().catch(log);
          });
        }
      })
      .catch((err) => {
        console.log(err);
        enableLoader(false);
        dispatch({
          type: "SET_STATUS",
          payload: {
            message: "Unable to update Document, Try again later",
            code: "",
            display: "show",
            severity: "error",
          },
        });
      });
  }, [
    loadSupportingDocs,
    contactID,
    dispatch,
    selectedDocURL,
    uploadedCV,
    base64Doc,
    enableLoader,
  ]);

  useEffect(() => {
    loadSupportingDocs().catch(console.log);
  }, [loadSupportingDocs]);

  return (
    <div style={{ margin: "4rem" }}>
      <Grid container spacing={0} justify="center">
        <Dialog
          open={showDocDeleteAlert}
          onClose={() => setShowDocDeleteAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the Document?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDocDeleteAlert(false)}>No</Button>
            <Button onClick={deleteSupportingDoc} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Grid item xs={12} md={8} spacing={10}>
          <Typography variant="h2">{"Upload your CV"}</Typography>

          <div style={{ marginTop: "2.5rem", marginBottom: "2rem" }}>
            {userRole &&
              userRole.name &&
              !userRole.name.includes("Individual") && (
                <Upload
                  recordType={uploadSchema.schema.containerName}
                  recordPath={uploadSchema.schema.recordPath}
                  getProps={uploadSchema}
                  disabled={
                    userRole &&
                    userRole.name &&
                    userRole.name.includes("Individual")
                  }
                ></Upload>
              )}
            <Upload
              recordType={uploadSchema.schema.containerName}
              recordPath={uploadSchema.schema.recordPath}
              getProps={uploadSchema}
              disabled={
                userRole &&
                userRole.name &&
                userRole.name.includes("Individual")
              }
            ></Upload>
          </div>
          <Button
            variant="contained"
            color="primary"
            disabled={!enableUpdateButton}
            onClick={updateRecord}
          >
            {"Update"}
          </Button>
          <Button
            size="medium"
            onClick={() => history.push("/profile")}
            style={{ marginTop: "0rem" }}
          >
            Go Back
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default UploadCV;
