import React from "react";
import styled from "styled-components";
import { Button, Box, useMediaQuery, Grid } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { appData } from "data/appData";
import { globalTheme } from "style/globalTheme";
import { footerItems } from "app/components/molecules/navbar/model/sideNavData";
import Stack from "layout/Stack";
import FooterSections from "./components/footer-sections";
import FooterSvg from "assets/MemberofIFAC.png";

const Ftr = styled(Stack)`
  max-width: 1320px;
  margin: 0 auto;
  padding: 35px 0;
  #tctext {
    small {
      display: block;
      padding-right: 25px;
      font-size: 11px;
    }
  }
`;

const FtrBox = styled.div`
  width: 100%;
  background: transparent linear-gradient(180deg, #f0f1f4 0, #e2e3e8 100%) 0 0
    no-repeat;
  min-height: 10rem;
  position: relative;
  bottom: 0;
  left: 0;

  ul {
    margin: 0;
    padding: 0;
  }

  h6 {
    margin: 0;
    margin-bottom: 25px;
    font-size: 18px;
    font-weight: 400;
    line-height: 120%;
    word-spacing: 0;
    text-transform: inherit;
    padding: 0;
    color: ${globalTheme.palette.secondary.dark};
  }
  a {
    color: ${globalTheme.palette.secondary.lighter};
    position: relative;
    padding-left: 12px;
    margin-right: 13px;
    margin-bottom: 12px;
    display: inline-block;
    text-decoration: none;
    &:hover,
    &:focus {
      backgroundcolor: none;
      color: ${globalTheme.palette.secondary.dark};
    }
  }
  li {
    list-style: none;
    display: inline-block;

    padding-left: 10px;
    line-height: 1;
    color: rgba(255, 255, 255, 0.7);
    a {
      position: relative;
      padding-left: 12px;
      margin-right: 13px;
      margin-bottom: 12px;
      display: inline-block;
      text-decoration: none;
      &:after {
        content: "";
        background-color: #a8abbc;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: 5px;
        left: -2px;
      }
    }
  }
  p {
    font-weight: 400;
    line-height: 150%;
    font-size: 18px;
    margin: 0;
  }

  .copyRightText {
    font-size: 1rem;
    float: right;
    color: ${globalTheme.palette.secondary.lighter};
    ${globalTheme.breakpoints.down("xs")} {
      padding-left: 1rem;
    }
  }
  .footer-top {
    padding-block: 35px;
    border-bottom: 1px solid #d3d5dd;
  }

  .footer-bottom {
    padding-block: 20px;
  }
  .gridContainer {
    border-left: 2px solid #6e7490;
    padding-left: 22px;
    margin-block: 15px;
  }
`;
const getLogo = (logoGlobalFooterSVG, alt) => {
  return (
    <img
      src="/brand/MemberofIFAC.png"
      alt={alt}
      style={{ maxWidth: "230px", maxHeoght: "100px" }}
    />
  );
};

export default function FooterComponent() {
  const matchesPrint = useMediaQuery("print");
  return (
    <ThemeProvider theme={globalTheme}>
      {!matchesPrint && (
        <FtrBox>
          <Ftr element="footer" gap="medium">
            <Grid
              container
              className="footer-top"
              spacing={0}
              justifyContent="flex-start"
            >
              <Grid item xs={12} sm={3}>
                {getLogo()}
              </Grid>
              <Grid item xs={12} sm={3} className="gridContainer">
                <h6>Our headquarters</h6>
                <p>
                  43 Beckz Close Unit 302, P.O. Box 1577, Grand Cayman KY1-1110,
                  Cayman Islands
                </p>
              </Grid>
              <Grid item xs={12} sm={3} className="gridContainer">
                <h6>Contact</h6>
                <p>
                  <a href="tel:(345) 749-3364" title="(345) 749-3364">
                    (345) 749-3364
                  </a>
                  <br />
                  <a href="mailto:admin@ciipa.ky" title="admin@ciipa.ky">
                    admin@ciipa.ky
                  </a>
                </p>
              </Grid>
              <Grid item xs={12} sm={3} className="gridContainer">
                <h6>Follow us</h6>
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/CIInstituteofProfessionalAccountants"
                      target="_blank"
                      title="Facebook"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/CIIPACayman"
                      target="_blank"
                      title="Twitter"
                      rel="noreferrer"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/cayman-islands-institute-of-professional-accountants/"
                      target="_blank"
                      title="LinkedIn"
                      rel="noreferrer"
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCK29j0MC-TKSGqDWSb59QGQ?view_as=subscriber"
                      target="_blank"
                      title="YouTube"
                      rel="noreferrer"
                    >
                      YouTube
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/ciipa345/"
                      target="_blank"
                      title="Instagram"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                </ul>
              </Grid>
            </Grid>
            <Grid container className="footer-bottom" spacing={0}>
              <Grid item xs={12} sm={6}>
                {getnavItems()}
              </Grid>
              <Grid item xs={12} sm={6}>
                <small className="copyRightText">
                  <p>© 2024 CIIPA. All rights reserved.</p>
                </small>
              </Grid>
            </Grid>
          </Ftr>
        </FtrBox>
      )}
    </ThemeProvider>
  );
}

//Navigation list items
const getnavItems = () => {
  let list = footerItems.horizontalSection.items;
  return list.map(({ labelText, linkUrl, iconUrl }) => {
    return (
      <a
        {...{
          href: linkUrl,
          key: labelText,
          target: "_blank",
        }}
      >
        {labelText}
      </a>
    );
  });
};
