const thisDate = new Date().toISOString().split("T")[0];

export const contactSchema = {
  title: "",
  logicalname: "Contact",
  type: "object",
  description:
    "Update your personal information using the form below and click Save",
  //required: ["name", "address", "primaryemail", "primarycontact"],
  properties: {
    salutation: {
      title: "Title",
      type: "string",
      schemaname: "salutation",
      datatype: "string",
    },
    firstname: {
      title: "First Name",
      type: "string",
      schemaname: "firstname",
      datatype: "string",
    },
    lastname: {
      title: "Last Name",
      type: "string",
      datatype: "string",
    },
    fullname: {
      title: "Full Name",
      type: "string",
      datatype: "string",
    },
    ctpt_othername: {
      title: "Other Name",
      type: "string",
      datatype: "string",
    },
    birthdate: {
      title: "Date of birth",
      type: "string",
      datatype: "number",
    },

    ctpt_isresidentofbotswana: {
      title: "Is resident of Cayman Islands?",
      type: "string",
      enum: ["True", "False"],
      enumNames: ["Yes", "No"],
      default: "True",
    },
    ctpt_profiledetailsconfirmedaccurateon: {
      title: "update date",
      type: "string",
      datatype: "string",
      default: thisDate,
    },
  },
};
