export const TELEPHONE_TYPE_MAP = {
  1: {
    727530000: "Work",
    727530001: "Work 2",
    727530002: "Work 3",
    727530003: "Mobile",
  },
  2: {
    727530000: "Work",
    727530001: "Home",
    727530002: "Other",
    727530003: "Shipping",
  },
  3: {
    1: "Personal", //email
    2: "Work",
  },
  4: {
    1: "Work", // telephone
    2: "Personal",
  },
  5: {
    100000000: "Permanent",
    100000001: "Contract",
    100000002: "Fixed Contract",
    533910001: "OPRT",
    533910002: "Tuition Provider",
    533910003: "ATE",
    533910004: "Parent Institute",
  },
};

export const IDENTITY_TYPE_MAP = {
  100000000: "Omang Number",
  100000001: "Passport Number",
  100000002: "Work Permit",
  100000003: "Exemption Certificate",
};

export const IDENTITY_NAME_MAP = {
  100000000: "Citizen Identity",
  100000001: "Passport",
  100000002: "Work Permit",
};
