import React from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FooterComponent from "app/components/molecules/footer/FooterComponent.mui";
import { appData } from "data/appData";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "100vh",

    position: "relative",
    zIndex: "1200",
  },
  stickyHeader: {
    position: "fixed",

    minHeight: "4rem",
    //background: globalTheme.palette.primary.main,
    opacity: "0.8",
  },
  pillarBox: {
    position: "relative",
    padding: 0,
    //display: "flex",

    backgroundColor: "white",
    // [globalTheme.breakpoints.up("sm")]: {
    //   background: `linear-gradient(90deg, ${globalTheme.palette.secondaryTint[50]} 0%, ${globalTheme.palette.secondaryTint[50]} ${drawerWidth} , gray ${drawerWidthPlusOne} , white ${drawerWidthPlusOne} , white 100%)`,
    // },
    minHeight: "100vh",
  },
}));

function PageLayout(props) {
  const classes = useStyles();
  const { children } = props;
  return (
    <>
      <div className={classes.root}>
        <div className={classes.stickyHeader}>&nbsp;</div>
        <Container className={classes.pillarBox} maxWidth={false}>
          {children}
        </Container>
      </div>
      <FooterComponent />
    </>
  );
}

export default PageLayout;
