import moment from "moment";
import { post } from "app/services/api";
import { config } from "utilities/config";

export const getListPayload = (declarationID) => ({
  properties: [
    "ctpt_evidenceid",
    "ctpt_evidenceurl",
    "ctpt_howmanyoftheseunitscouldyouverifywithsup",
    "ctpt_howmanyunitsareyourclaimingforthisactivit",
    "ctpt_name",
    "ctpt_whatdidyoulearnandhowdidwillyouapplyit",
    "ctpt_whendidthisactivitytakeplace",
    "ctpt_whydidyouchoosethisactivityandhowisitre",
    "ctpt_supportingevidence",
    "_ctpt_memberdeclaration_value",
    "ctpt_cpdhours",
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: true,
    filterfields: [
      {
        field: "_ctpt_memberdeclaration_value",
        searchvalue: declarationID,
        type: "lookup",
      },
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "ctpt_evidences",
  idfield: "ctpt_evidenceid",
  continuationToken: "",
  pazesize: "10",
});

export const deleteListPayload = {
  recordtype: "ctpt_evidences",
};

export const updateRecordStatusPayload = (recordID, status) => {
  let fields = [
    {
      name: "statuscode",
      value: status,
      type: "number",
    },
  ];

  fields.push({
    name: "ctpt_declartationdate",
    value: moment().format("YYYY-MM-DD"),
    type: "string",
  });

  return {
    id: recordID,
    recordtype: "ctpt_memberdeclarations",
    data: fields,
  };
};

export const getDeclarationCompliancePayload = (id) => ({
  properties: [
    "ctpt_cpdcomplied",
    "ctpt_cpdnoncomplied",
    "ctpt_compliedbicacodeofethics",
    "ctpt_notcompliedbicacodeofethics",
    "ctpt_undertakencpdbybica",
    "ctpt_undertakencpdbyifac",
    "ctpt_certifiedauditor",
    "ctpt_responsibleforfinancialstatements",
    "ctpt_responsibleforcompanysecretarialwork",
    "ctpt_anaccountingofficerofacompany",
    "ctpt_otherspecify",
    "ctpt_ownorandworkfornonauditingmemberfirm",
    "ctpt_pleadedguilty",
    "ctpt_submittedkyc",
  ],
  formattedFields: [],
  recordType: "ctpt_memberdeclarations",
  id: id,
});

export const updateCompliancePayload = (JsonData) => {
  return {
    id: JsonData.id,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "ctpt_cpdcomplied",
        value: JsonData.data[0].value,
        type: "string",
      },
      {
        name: "ctpt_cpdnoncomplied",
        value:
          JsonData.data[0].value === false
            ? null
            : JsonData.data[0].value === "True"
            ? "False"
            : "True",
        type: "string",
      },
      {
        name: "ctpt_compliedbicacodeofethics",
        value: JsonData.data[1].value,
        type: "string",
      },
      {
        name: "ctpt_notcompliedbicacodeofethics",
        value:
          JsonData.data[1].value === false
            ? null
            : JsonData.data[1].value === "True"
            ? "False"
            : "True",
        type: "string",
      },
      {
        name: "ctpt_undertakencpdbybica",
        value: JsonData.data[2].value,
        type: "string",
      },
      {
        name: "ctpt_undertakencpdbyifac",
        value:
          JsonData.data[2].value === false
            ? null
            : JsonData.data[2].value === "True"
            ? "False"
            : "True",
        type: "string",
      },
      {
        name: "ctpt_certifiedauditor",
        value: JsonData.data[3].value,
        type: "string",
      },
      {
        name: "ctpt_responsibleforfinancialstatements",
        value: JsonData.data[4].value,
        type: "string",
      },
      {
        name: "ctpt_responsibleforcompanysecretarialwork",
        value: JsonData.data[5].value,
        type: "string",
      },
      {
        name: "ctpt_anaccountingofficerofacompany",
        value: JsonData.data[6].value,
        type: "string",
      },
      {
        name: "ctpt_otherspecify",
        value: JsonData.data[7].value,
        type: "string",
      },
      {
        name: "ctpt_ownorandworkfornonauditingmemberfirm",
        value: JsonData.data[8].value,
        type: "string",
      },
      {
        name: "ctpt_pleadedguilty",
        value: JsonData.data[9].value.pleadRadio,
        type: "string",
      },
      // {
      //   name: "ctpt_documenturl",
      //   value: JsonData.docUrl,
      //   type: "string"
      // },
      {
        name: "ctpt_submittedkyc",
        value: JsonData.data[10].value,
        type: "string",
      },
    ],
  };
};

export const CreateMemberDeclarationrequestPayload = (JsonData) => {
  // JsonData.data[3].value = !JsonData.data[3].value
  //   ? new Date().toISOString().slice(0, 10)
  //   : JsonData.data[3].value;
  // const details = JsonData.data;
  // console.log("JSON Data:", JsonData);

  return post(
    config.teleportal.submitForm.Update,
    updateCompliancePayload(JsonData)
  )
    .then((res) => {
      if (res.data?.id) {
        return {
          recordtype: "ctpt_supportingdocuments",
          data: [
            {
              name: "ctpt_name",
              value: "pleadedGuilty",
              type: "string",
            },
            {
              name: "ctpt_documenttype",
              value: 100000001,
              type: "picklist",
            },
            {
              name: "ctpt_MemberDeclaration",
              value: res.data.id,
              type: "lookup",
              relatedentity: "ctpt_memberdeclarations",
            },
            {
              name: "ctpt_documenturl",
              value: JsonData.docUrl,
              type: "string",
            },
          ],
        };
      }
    })
    .catch((err) => {
      console.log("ERROR", err);
      return {};
    });
};

export const createPleadedguiltyDocAttachmentPayload = (
  _contactName,
  supportingDocID,
  _docUrl,
  JsonData
) => {
  console.log({ supportingDocID, JsonData });
  const base64Doc = JsonData.base64Doc;
  if (!(base64Doc?.data && base64Doc?.fileName && supportingDocID)) {
    return null;
  }
  return {
    recordtype: "annotations",
    data: [
      {
        name: "subject",
        value: "pleadedGuilty",
        type: "string",
      },
      {
        name: "filename",
        value: base64Doc.fileName,
        type: "string",
      },
      {
        name: "documentbody",
        value: base64Doc.data,
        type: "string",
      },
      {
        name: "objectid_ctpt_supportingdocument",
        value: supportingDocID,
        type: "lookup",
        relatedentity: "ctpt_supportingdocuments",
      },
      {
        name: "mimetype",
        value: base64Doc.fileType,
        type: "string",
      },
    ],
  };
};
