import _ from "lodash";
export const formatData = (obj) => {
  let objkey;
  let newRows = [];
  let rowObj1 = {};
  _.forEach(obj.data, function (value, key) {
    rowObj1 = {}
    _.forEach(value, function (val, index) {
      if (index === "id") {
        // rowObj1 = {
        //   id: value[index],
        // };
        rowObj1["" + "id" + ""] = value[index]
      } else {
        _.forEach(val, function (va) {
          objkey = va["name"];
          rowObj1["" + objkey + ""] = va["value"];
        });
      }
    });
    //newRows[key] = rowObj1;
    newRows.push(rowObj1);
  });
  return newRows;
};

export const getColum = () => {
  // This is intentional
};

export const getSonicrow = (obj) => {
  let rowObj = {
    id: "",
    formName: "",
    type: "Wizard Entry",
    primaryEntity: "Contact",
    launch: "",
    submissionName: "",
    status: "",
    formId: "",
  };
  _.forEach(obj, function (value, key) {
    if (
      ["formName", "id", "submissionName", "status", "formId"].includes(key)
    ) {
      rowObj[key] = value;
    }
  });
  return rowObj;
};

export const getApplicationrow = (obj) => {
  let rowObj = {};
  let rows = [];
  let statusName;
  _.forEach(obj.data, function (value) {
    switch (value.data[2].value) {
      case 100000000:
        statusName = "Pending";
        break;
      case 100000001:
        statusName = "Complete";
        break;
      case 100000002:
        statusName = "Cancelled";
        break;
      default:
    }
    rowObj = {
      id: value.id,
      ctpt_name: value.data[0].value,
      _ctpt_sonicwebformid_value: value.data[1].value,
      ctpt_submissionstatus: statusName,
      ctpt_sonicwebformsubmissionid: value.data[3].value,
      ctpt_submissiondate: value.data[4].value,
    };
    rows.push(rowObj);
  });
  return rows;
};
