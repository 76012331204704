import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";
import { Button, Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { postPay } from "app/services/api";
import { config } from "utilities/config";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import env from "../../../environments/env";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 300px;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function Paymentsucess() {
  const classes = useStyles();
  const [payDetails, setpayDetails] = useState(null);
  const location = useLocation();
  const [payloads, setpayloads] = useState({
    orderId: "",
  });
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const dispatch = useDispatch();
  let history = useHistory();
  const userName = useSelector((state) => state.user);

  const parseJwt = (token) => {
    return JSON.stringify(atob(token));
  };
  const getInvoice = () => {
    postPay(config.payment.orderDetails, payloads)
      .then((res) => {
        setpayDetails(res.data.data);
        //createPayment(res.data.data);
        dispatch({ type: "SET_PAYSUMMARY", payload: res.data.data });
      })
      .catch((err) => {
        dispatch({
          type: "SET_STATUS",
          payload: {
            message: "Oops something went wrong!",
            code: "",
            display: "show",
            severity: "error",
          },
        });
      });
  };
  
  const completePayment = (spt) => {
    var data = spt;

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        console.log(this.responseText);
      }
    });
    console.log(env.FAC);
    xhr.open("POST", config.payment.paymentComplete);
    xhr.setRequestHeader("accept", "application/json");
    xhr.setRequestHeader("content-type", "application/json");
    xhr.send(data);
  };

  useEffect(() => {
   // setInvoiceNumber(localStorage.getItem("invoiceNumber"));
  }, []);

  useEffect(() => {
    let spt = parseJwt(new URLSearchParams(location.search).get("spt"));
    //completePayment(spt);
  }, []);

  useEffect(() => {
    if (payloads.orderId !== "") {
      //getInvoice();
    }
  }, [payloads]);

  return (
    <ThemeProvider theme={globalTheme}>
      {/*} <AuthenticatedTemplate>*/}
      <StartPage>
        <h1 className={classes.headingTxt}>Thank you for your Application</h1>

        <div className={classes.notes}>
          <p>
            Your application has now been submitted to our membership team for
            review. You will be notified by email once it is approved or a
            member of our team will contact you if required. If you have any
            queries in relation to your application, please contact us.
          </p>
        </div>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push("/")}
          >
            {" "}
            Return to Membership Homepage
          </Button>
        </Box>
      </StartPage>
      {/*</AuthenticatedTemplate>*/}
    </ThemeProvider>
  );
}

export default Paymentsucess;
