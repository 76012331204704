export const getEvidenceSchema = {
  title: "Add New Evidence",
  logicalname: "ctpt_supportingdocuments",
  type: "object",
  properties: {
    ctpt_name: {
      title: "Name",
      type: "string",
      schemaname: "ctpt_name",
      datatype: "string",
      requiredCheck: true,
    },
    ctpt_descriptionofdevelopmentlearningactivity: {
      title: "Description of development/learning activity",
      type: "string",
      schemaname: "ctpt_descriptionofdevelopmentlearningactivity",
      datatype: "string",
      ismultiline: true,
      multilineRow: 4,
      pattern: "^.{1,100}$",
      message: "Text is too long.",
    },
    ctpt_whydidyouchoosethisactivityandhowisitre: {
      title: "Why did you choose this activity and how is it relevant to you?",
      type: "string",
      schemaname: "ctpt_whydidyouchoosethisactivityandhowisitre",
      datatype: "string",
      requiredCheck: true,
    },
    ctpt_whendidthisactivitytakeplace: {
      title: "When did this activity take place?",
      type: "string",
      schemaname: "ctpt_whendidthisactivitytakeplace",
      datatype: "number",
      requiredCheck: true,
      disableFuture: true,
      tootltipheading: "",
      tootltipText: "",
      helpText: "(DD/MM/YYYY)",
      defaultValue: "",
      pattern: "",
      message: "",
    },
    ctpt_howmanyunitsareyourclaimingforthisactivit: {
      title: "How many units are your claiming for this activity?",
      type: "number",
      schemaname: "ctpt_howmanyunitsareyourclaimingforthisactivit",
      datatype: "string",
      requiredCheck: true,
      pattern: "^[0-9]*$",
      message: "Only numbers allowed",
    },
    ctpt_howmanyoftheseunitscouldyouverifywithsup: {
      title:
        "How many of these units could you verify with supporting evidence?",
      type: "number",
      schemaname: "ctpt_howmanyoftheseunitscouldyouverifywithsup",
      datatype: "string",
      requiredCheck: true,
      pattern: "^[0-9]*$",
      message: "Only numbers allowed",
    },
    ctpt_whatdidyoulearnandhowdidwillyouapplyit: {
      title: "What did you learn and how did/will you apply it?",
      type: "string",
      schemaname: "ctpt_whatdidyoulearnandhowdidwillyouapplyit",
      datatype: "string",
      requiredCheck: true,
      multilineRow: 4,
    },
    ctpt_cpdhours: {
      title: "CPD Hours",
      type: "number",
      schemaname: "ctpt_cpdhours",
      datatype: "decimal",
      requiredCheck: true,
      pattern: "^[0-9]*(.[0-9]{0,2})?$",
      message: "Only numbers allowed",
    },
    ctpt_supportingevidence: {
      title: "Supporting Evidence",
      type: "string",
      schemaname: "ctpt_supportingevidence",
      datatype: "string",
      helpText:
        "You can record details/location of any supporting evidence here or the contact details of a third party who can substantiate completion of the activity.",
      ismultiline: true,
      multilineRow: 4,
      pattern: "^.{1,100}$",
      message: "Text is too long.",
    },
    ctpt_documenturl: {
      title: "Attach document",
      type: "string",
      schemaname: "ctpt_documenturl",
      datatype: "upload",
      helpText: "",
      tootltipheading: "",
      requiredCheck: true,
      format: "string",
      containerName: "channels",
      fileName: "cpdEvidence",
      acceptExtension: [
        ".pdf",
        ".doc",
        ".docx",
        ".png",
        ".jpeg",
        ".xlsx",
        ".xls",
        ".csv",
      ],
      acceptType: [
        "application/pdf",
        "application/msword",
        "image/png",
        "image/jpeg",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "application/csv",
      ],
      recordPath: "/",
      requireBoburl: true,
    },
  },
};

export const getDocumentSchema = {
  title: " ",
  logicalname: "Contact2",
  type: "object",
  properties: {
    ctpt_name: {
      title: "Name",
      type: "string",
      schemaname: "ctpt_customerid",
      datatype: "string",
      isDynamic: false,
    },
    ctpt_documenttype: {
      title: "Document Type",
      type: "string",
      schemaname: "ctpt_documenttype",
      datatype: "string",
    },
    ctpt_case: {
      title: "Case",
      type: "string",
      schemaname: "ctpt_case",
      datatype: "string",
    },
    ctpt_documenturl: {
      title: "Student attendance register",
      type: "string",
      schemaname: "ctpt_documenturl",
      datatype: "string",
      requireBoburl: true,
    },
  },
};

export const declarationComplianceSchema = (editEnabled) => ({
  title: "Declaration and Ethics",
  logicalname: "declaration",
  type: "object",
  description: "",
  //required: ["name", "address", "primaryemail", "primarycontact"],
  properties: {
    ctpt_cpdcomplied: {
      title:
        "Tick one of the two boxes in this section (provide reasons for non-compliance with evidence).",
      type: "string",
      enum: ["True", "False"],
      enumNames: [
        "I confirm that I have completed my CPD requirements.",
        "I confirm that I have not complied with my CPD requirements.",
      ],
      default: "True",
      requiredCheck: false,
      disabled: !editEnabled,
    },

    ctpt_compliedbicacodeofethics: {
      title: "Tick one of the two boxes in this section.",
      type: "string",
      enum: ["True", "False"],
      enumNames: [
        "I confirm that I have read the Code of Ethics for Professional Accountants and complied with its requirements.",
        "I confirm that I have not read the Code of Ethics for Professional Accountants and not complied with its requirements.",
      ],
      //default: "True",
      requiredCheck: false,
      disabled: !editEnabled,
    },

    ctpt_undertakencpdbybica: {
      title: "Tick one of the two boxes in this section.",
      type: "string",
      enum: ["True", "False"],
      enumNames: [
        "I have undertaken my CPD requirements by following CIIP's unit route.",
        "I am a member of an IFAC body which is fully compliant with IFAC`s International Education Standard (IES 7) and I have followed the CPD scheme of that body",
      ],
      //default: "True",
      disabled: !editEnabled,
    },
    ctpt_certifiedauditor: {
      title:
        "Please indicate by ticking in the box if you are a Certified Auditor",
      type: "boolean",
      requiredCheck: false,
      disabled: !editEnabled,
    },
    ctpt_responsibleforfinancialstatements: {
      heading: "Please indicate by ticking in the box if you are :",
      title:
        "Responsible for the preparation or presentation for statutory purposes of financial statements and/or annual reports of listed companies, financial services organizations, or indeed any organization deemed to be a public interest body such as insurance companies, building societies and banks.",
      type: "boolean",
      requiredCheck: false,
      disabled: !editEnabled,
    },
    ctpt_responsibleforcompanysecretarialwork: {
      title: "Responsible for company secretarial work",
      type: "boolean",
      requiredCheck: false,
      disabled: !editEnabled,
    },
    ctpt_anaccountingofficerofacompany: {
      title: "An Accounting Officer of a company",
      type: "boolean",
      requiredCheck: false,
      disabled: !editEnabled,
    },
    ctpt_otherspecify: {
      title: "Other (Specify):",
      type: "string",
      schemaname: "ctpt_otherspecify",
      datatype: "string",
      disabled: !editEnabled,
    },
    ctpt_ownorandworkfornonauditingmemberfirm: {
      //showHorizontalSeparator: true,
      title:
        "Please indicate by ticking in the box if you own and/or work for a Non-auditing Member firm of the Institute.",
      type: "boolean",
      requiredCheck: false,
      disabled: !editEnabled,
    },
    pleadConditional: {
      $ref: "#/definitions/plead",
    },
    ctpt_submittedkyc: {
      title:
        "I confirm that I have submitted all KYC documents as required in the Financial Intelligence Act 2019.",
      type: "boolean",
      requiredCheck: false,
      disabled: !editEnabled,
    },
  },
  definitions: {
    plead: {
      title: "",
      type: "object",
      properties: {
        pleadRadio: {
          title:
            "Have you pleaded guilty or been found guilty of any offence during the year?If so give details of the court which convicted you, the offence, the penalty imposed and the date of conviction (Please attach additional sheet if necessary).",
          type: "string",
          enum: ["True", "False"],
          enumNames: ["Yes", "No"],
          default: "False",
          disabled: !editEnabled,
        },
      },
      dependencies: {
        pleadRadio: {
          oneOf: [
            {
              properties: {
                pleadRadio: {
                  enum: ["True"],
                },
                ctpt_documenturl: {
                  title: "Upload Supporting Document",
                  helpText: "",
                  tootltipheading: "head",
                  tootltipText: "text",
                  type: "string",
                  datatype: "upload",
                  format: "string",
                  requiredCheck: true,
                  containerName: "channels",
                  recordPath: "/",
                  fileName: "pleadedGuilty",
                  acceptExtension: [".pdf", ".png", ".jpeg", ".jpg"],
                  acceptType: [
                    "application/pdf",
                    "image/png",
                    "image/jpeg",
                    "image/jpg",
                  ],
                  disabled: !editEnabled,
                },
              },
            },
            {
              properties: {
                pleadRadio: {
                  enum: ["False"],
                },
              },
            },
          ],
        },
      },
    },
  },
});
