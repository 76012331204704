export const getlistPayload = {
  properties: [
    "ctpt_statement",
    "ctpt_memberdeclarationtype",
    "_ctpt_initialstatementid_value",
    "_ctpt_member_value",
    "ctpt_comment",
    "ctpt_declarationagreedto",
    "ctpt_declarationsource",
    "ctpt_declarationsubmittedon",
    "ctpt_memberdeclarationsid",
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: false,
    filterfields: [
      {
        field: "_ctpt_member_value",
        searchvalue: "dc7bbf50-c5f7-ee11-a1ff-6045bdd0ef58",
        type: "guid",
      },
    ],
  },
  Aggregate: { AggregateFields: [] },
  recordtype: "ctpt_memberdeclarationses",
  idfield: "ctpt_memberdeclarationsid",
  continuationToken: "",
  pazesize: "10",
};
