import { appmenuItemsdata } from "app/components/molecules/navbar/model/commonMenuData";
import { appData } from "data/appData";

export const MEMBERSHIP_TYPE_MAP = {
  1: "Fellow (FCA)",
  2: "Fellow (FCPA)",
  3: "Associate Chartered Accountant",
  4: "Associate (ACPA)",
  5: "Accounting Technician",
  6: "Student",
};

export const HOME_TILES_DATA = [
  {
    title: "Membership",
    items: appmenuItemsdata(
      appData[0].pageConfig.enableModules.filter(
        (element) => element === "Applications"
      ),
      true
    ),
  },
];

export const RENEWAL_DECLARATION = [
  {
    declaration: [
      "I hereby confirm that I have taken the Cayman Islands Business Law Course and have attained the required pass mark",
      "The information provided in this form and throughout this application is true, complete and accurate to the best of my knowledge",
      "I have no objection to CIIPA contacting my Overseas Professional Accounting Institute, at any time during my membership, to confirm that I am in good standing or any other information relevant to my membership",
      "I have no objection to CIIPA confirming the current status of my membership with CIIPA to third parties upon receipt of a written request from such third parties",
      "I have never been excluded from membership of a professional body on disciplinary grounds",
      "I have never been subject to a disciplinary order by any professional body",
      "I have never been declared bankrupt in any jurisdiction",
      "I have never been convicted of a criminal offence in any jurisdiction",
      "I am aware that certain information I have provided will be maintained on an electronic database for the purposes of administering CIIPA's membership activities and that this data will reside on the server of a third-party service provider, which may be located outside the Cayman Islands",
      "I agree to notify the CIIPA of any changes to the information provided in this form occurring after submission and before approval of the application and thereafter for as long as I am registered",
      "I confirm that I have successfully completed CIIPA's Business Environment Course attaining the requisite passing grade",
    ],
    licenceDeclaration: [
      "To be completed for Regular and Licenced Practioner Membership Application and Renewal. I further confirm that",
      "I have read and comply with the requirements of the IFAC Code of Ethics (please refer to www.ifac.org/ethics)",
      "I have complied with the Continuing Professional Development requirements of my Overseas Professional Accounting Institute and can make copies of all records in support available to Council for inspection in the Cayman Islands within a reasonable time; or where none applicable -. I have complied in full with the requirements of IES7: Continuing Professional Development, and have completed at least 20 hours of CPD in the last year , and at least 120 hours in the previous 3 years  and shall as requested make copies of all records in support available to Council for inspection in the Cayman Islands within a reasonable time",
    ],
    waiverDeclaration: [
      "I hereby agree to a waive, release, and indemnify CIIPA, its directors, officers, employees, committee members, agents and assigns (“Indemnified Parties”) from any and all liability, including any and all claims, demands, causes of action (known or unknown), suits, or judgments of any kind (including attorney's fees), arising from the Indemnified Parties actions, decisions or activities deemed appropriate or necessary to enable CIIPA to effectively and efficiently discharge its obligations under Cayman Islands laws and regulations",
    ],
  },
];
