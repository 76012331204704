//Import React Library
import React from "react";
import { useSelector } from "react-redux";

import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useStyles } from "../style";
//Loading components
import { Button } from "@material-ui/core";
import { config } from "utilities/config";
import moment from "moment";
import ListComponent from "app/components/mvp/listWidget";

import {
  getqualificationlistPayload,
  addNewQualificationDetailsPayload,
} from "../model/payload/payload";
import { addNewQualificationDetailsSchema } from "../model/schema/qualificationsschema";
import { addNewQualificationDetailsUiSchema } from "../model/schema/viewqualificationdetailsuiSchema";

function Qualifications() {
  const classes = useStyles();
  const history = useHistory();
  const getcontactId = useSelector((state) => state.contactid);
  const getcontactName = useSelector((state) => state.user);

  const userRole = useSelector((state) => state.role);

  const onClickViewDetails = (params) => {
    window.location.href =
      "/profile/viewqualificationdetails?id=" +
      params.row.mshied_previouseducationid +
      "&sid=" +
      params.row._mshied_studentid_value;
  };

  const columns = [
    {
      field: "_mshied_studentid_value",
      flex: 2,
      headerName: "",
      hide: true,
    },
    {
      field: "mshied_name",
      flex: 2,
      headerName: "Qualifications",
      hide: false,
    },
    {
      field: "ctpt_level",
      flex: 2,
      headerName: "Level",
      hide: false,
    },
    {
      field: "ctpt_startdate",
      flex: 2,
      headerName: "Start Date",
      hide: false,
      renderCell: (params) => {
        return (
          <span>
            {moment.utc(params.row.ctpt_startdate).format("DD MMM YYYY")}
          </span>
        );
      },
    },
    {
      field: "ctpt_enddate",
      flex: 2,
      headerName: "End Date",
      hide: false,
      renderCell: (params) => {
        return (
          <span>
            {moment.utc(params.row.ctpt_enddate).format("DD MMM YYYY")}
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: <p class={classes.paidListHeader}>Action</p>,
      flex: 1,
      renderCell: (params) => {
        return (
          <div class={classes.actionsButtons}>
            <Button
              variant="outlined"
              color="primary"
              className="primaryBtn"
              onClick={() => {
                onClickViewDetails(params);
              }}
              disabled={false}
            >
              {userRole &&
              userRole.name &&
              !userRole.name.includes("Individual")
                ? "View & Update"
                : "View"}
            </Button>
          </div>
        );
      },
    },
  ];

  const options = {
    apiURL: config.teleportal.submitForm.get,
    apiUrlDel: config.teleportal.file.delete,
    columns: columns,
    searchPayload: "searchFilter",
    getlistPayload: getqualificationlistPayload(getcontactId),
    deleteListPayload: {},
    listHeading: "Qualifications",
    formwidgetOptions: {
      widgetType: "add",
      formSchema: addNewQualificationDetailsSchema,
      uiSchema: addNewQualificationDetailsUiSchema,
      apiURL: config.teleportal.submitForm.Create,
      entityType: "mshied_previouseducations",
      formUischema: "",
      button1Label: "Create",
      button2Label: "Cancel",
      button3Label: "Clear",
      payloadSubmit: addNewQualificationDetailsPayload(getcontactId),
      columns: [],
      contactId: getcontactId,
      contactName: getcontactName.name,
      historyUrl: "",
      hasMultiplecall: true,
      hasModel: true,
    },
    nonRecord: false,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "",
    filterValue: [],
    aggregateField: "",
    hideSearch: true,
    pageSize: 6,
    hasPayloadfilter: true,
    hideAdd: false,
    controlLists: false,
  };

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item xs={12} md={9}>
        <div className={classes.componentHolder}>
          <Button
            size="medium"
            variant="outlined"
            onClick={() => {
              history.push("/profile");
            }}
            style={{ marginBottom: "1.5rem" }}
          >
            Go Back
          </Button>
          <ListComponent options={options} />
        </div>
      </Grid>
    </Grid>
  );
}

export default Qualifications;
