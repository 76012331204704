//Import React Library
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthenticatedTemplate } from "@azure/msal-react";

//Import Material-UI Library
import { ThemeProvider } from "@material-ui/core/styles";

//Loading Data and Style..
import { globalTheme } from "style/globalTheme";
import { useStyles } from "./style";
//Loading components
import { Button } from "@material-ui/core";
import { config } from "utilities/config";

import ListComponent from "app/components/mvp/listWidget";
import { aggregatecolumns } from "./model/column";
import { getlistPayload } from "./model/payload";
import { postPay } from "app/services/api";
import Invoice from "brand/components/invoice";
import { stateCodes } from "utilities/stateCodes";
import TabWidget from "app/components/molecules/tab/TabWidget.mui";

function Invoices(props) {
  //const userEntitlement = useContext(UserContext)
  const userEntitlement = useSelector((state) => state.entitleMents);
  const currencySymbols = useSelector((state) => state.currency);
  const permissions = userEntitlement.find((x) => x.route === props.match.path);
  const classes = useStyles();
  const role = useSelector((state) => state.role);
  const getcontactId = useSelector((state) => state.contactid);
  const dispatch = useDispatch();
  const [payDetails, setpayDetails] = useState(null);
  const downloadFunc = React.useRef(null);

  const findCurrency = (id) => {
    let items = currencySymbols.filter((item) => item.id === id);
    return items.length !== 0 ? items[0].data[1].value : "";
  };
  const continuePay = (params) => {
    localStorage.setItem("oid", params.row.salesorderid);
    //localStorage.setItem("oid", '5267744c-b093-ec11-b400-6045bd0eebfa');
    const individualForm =
      params.row._ctpt_sonicwebformsubmission_value?.includes("individual")
        ? "0"
        : "1";
    let type = params.row._ctpt_sonicwebformsubmission_value ? "0" : null;
    let path =
      window.location.protocol +
      "//" +
      window.location.host +
      "/application/prepayment?type=" +
      type +
      "&eid=" +
      params.row._ctpt_eventid_value +
      "&swfName=" +
      params.row._ctpt_sonicwebformsubmission_value;

    window.location.href = path;
  };

  const openPdf = (params) => {
    const individualForm =
      params.row._ctpt_sonicwebformsubmission_value?.includes("individual")
        ? "0"
        : "1";
    let type = params.row._ctpt_sonicwebformsubmission_value
      ? individualForm
      : null;
    let payload = {
      orderId: params.row.salesorderid,
      type: "0",
    };
    postPay(config.payment.orderDetails, payload)
      .then((res) => {
        setpayDetails(res.data.data);
        dispatch({ type: "SET_PAYSUMMARY", payload: res.data.data });
      })
      .catch(() => {
        dispatch({
          type: "SET_STATUS",
          payload: {
            message: "Oops something went wrong!",
            code: "",
            display: "show",
            severity: "error",
          },
        });
      });
  };

  useEffect(() => {
    if (payDetails !== null) downloadFunc.current();
  }, [payDetails]);

  const renderRequestDeliveryBy = (params) => (
    <div>
      {params.row.requestdeliveryby !== null
        ? new Date(params.row.requestdeliveryby).toISOString().substring(0, 10)
        : ""}
    </div>
  );

  const dueColumns = [
    { field: "salesorderid", flex: 1, headerName: " ", hide: true },
    { field: "_ctpt_eventid_value", flex: 1, headerName: " ", hide: true },
    {
      field: "name",
      headerName: <p>Invoice Name</p>,
      flex: 2,
      renderCell: (params) => <p>{params.row.name}</p>,
    },
    {
      field: "totalamount",
      flex: 1,
      headerName: "Amount",
      renderCell: (params) => (
        <p className={classes.alignCenter}>
          <span className={classes.symbolStyle}>
            {findCurrency(params.row._transactioncurrencyid_value)}
          </span>
          &nbsp;{params.row.totalamount}
        </p>
      ),
    },
    {
      field: "requestdeliveryby",
      headerName: <p className={classes.dueColumHeader}>Due</p>,
      flex: 1,
      renderCell: renderRequestDeliveryBy,
    },
    {
      field: "statecode",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.row.statecode !== null
            ? stateCodes.invoice[params.row.statecode]
            : ""}
        </div>
      ),
    },
    {
      field: "action",
      headerName: <p className={classes.dueListHeader}>Action</p>,
      flex: 1,
      renderCell: (params) => (
        <div className={classes.actionsButtons}>
          <Button
            variant="contained"
            color="primary"
            className="primaryBtn"
            onClick={() => {
              continuePay(params);
            }}
          >
            Pay
          </Button>
        </div>
      ),
    },
  ];

  const paidColumns = [
    { field: "salesorderid", flex: 1, headerName: " ", hide: true },
    { field: "name", flex: 1, headerName: "Invoice Name" },
    {
      field: "totalamount",
      flex: 1,
      headerName: "Amount",
      renderCell: (params) => (
        <p className={classes.alignCenter}>
          <span className={classes.symbolStyle}>
            {findCurrency(params.row._transactioncurrencyid_value)}
          </span>
          &nbsp;{params.row.totalamount}
        </p>
      ),
    },
    {
      field: "requestdeliveryby",
      headerName: "Due",
      flex: 1,
      renderCell: renderRequestDeliveryBy,
    },
    {
      field: "statecode",
      headerName: "Status",
      flex: 1,
      renderCell: () => <div>Paid</div>,
    },
    { field: "paidon", headerName: "Paid on", flex: 1 },
  ];

  const dueOptions = {
    listDescription:
      "Please note that if you have uploaded proof of payment or paid through sponsorship your invoices may show as due until we have verified your payment.",
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: dueColumns,
    searchPayload: "searchFilter",
    getlistPayload: getlistPayload,
    deleteListPayload: {},
    listHeading: "",
    formWidget: {},
    nonRecord: false,
    role: role,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: true,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: true,
    hasPayloadfilter: true,
    filterField: "statecode",
    filterValue: [0],
    aggregateColumns: aggregatecolumns,
    aggregateField: "totalamount",
    hideSearch: true,
    pageSize: 10,
    hideAdd: true,
    controlLists: false,
  };

  const paidOptions = {
    listDescription:
      "Please note that if you have uploaded proof of payment or paid through sponsorship your invoices may show as due until we have verified your payment.",
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: paidColumns,
    searchPayload: "searchFilter",
    getlistPayload: getlistPayload,
    deleteListPayload: {},
    listHeading: "",
    formWidget: {},
    nonRecord: false,
    role: role,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: true,
    filterField: "statecode",
    filterValue: [1, 3, 4],
    aggregateColumns: aggregatecolumns,
    aggregateField: "totalamount",
    hideSearch: true,
    pageSize: 10,
    hasPayloadfilter: true,
    hideAdd: true,
    controlLists: false,
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div className={classes.background1}></div>
        <div id="cTTP_applicationList" className={classes.container}>
          <TabWidget
            options={{
              defaultTabID: 1,
              tabNavJson: [
                {
                  label: "Due Invoices",
                  id: 1,
                  entity: (
                    <div className={classes.listSection}>
                      {/* <Button
                        variant="outlined"
                        color="primary"
                        className="primaryBtn"
                        onClick={() => {}}
                        disabled={
                          permissions &&
                          permissions.permissionsets &&
                          permissions.permissionsets.length > 0 &&
                          permissions.permissionsets[0].cancreate == "0" &&
                          permissions.permissionsets[0].canwrite == "0"
                        }
                      >
                        test entitlement
                      </Button> */}
                      <ListComponent options={dueOptions} />
                    </div>
                  ),
                },
                {
                  label: "Paid Invoices",
                  id: 2,
                  entity: (
                    <div className={classes.paidInvoiceList}>
                      <ListComponent options={paidOptions} />
                    </div>
                  ),
                },
              ],
            }}
          />
          {payDetails !== null ? (
            <Invoice
              payDetails={payDetails}
              label="Download Invoice"
              downloadFunc={downloadFunc}
            />
          ) : (
            ""
          )}
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default Invoices;
