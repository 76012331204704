export const profileTilesData = [
  {
    title: "Personal Details",
    description:
      "Manage your name, date of birth and other personal information.​",
    linkText: "Update Now",
    linkURI: "/profile/aboutyou",
    dialogue: false,
  },
  {
    title: "Contact Numbers",
    linkText: "Update Now",
    linkURI: "/profile/contactinformation",
    description: "Manage your contact numbers",
  },
  {
    title: "Identity Information",
    linkText: "Update Now",
    linkURI: "/profile/identityinformation",
    description: "Manage your Identity information",
  },
  {
    title: "Email Address",
    linkText: "Update Now",
    linkURI: "/profile/email",
    description: "Manage your email address​",
  },

  {
    title: "Addresses​",
    linkText: "Update Now",
    linkURI: "/profile/addresses",
    dialogue: false,
    description: "Manage your addresses​​",
  },
  {
    title: "Qualifications",
    description: "Manage your education qualifications.​",
    linkText: "Update Now",
    linkURI: "/profile/qualifications",
    dialogue: false,
  },
  {
    title: "Employment Details",
    description: "Manage your employment details.",
    linkText: "Update Now",
    linkURI: "/profile/employmenthistory",
    dialogue: false,
  },
  {
    title: "Member Renewals",
    description: "Manage your renewals.",
    linkText: "Update Now",
    linkURI: "/renewals",
    dialogue: false,
  },
  {
    title: "Declarations",
    description: "Manage your declarations.",
    linkText: "Update Now",
    linkURI: "/declarations",
    dialogue: false,
  },
];
