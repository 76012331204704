import { makeStyles } from "@material-ui/core/styles";
import { brandPalette } from "brand/theme/brandTheme";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,

    width: "100%",
    overflow: "visible",
    //backgroundColor: "red",
    margin: "2rem auto",
    marginTop: 0,
  },

  container: {
    minHeight: "60rem",
    padding: "12rem",

    "& div[class^=MuiTabs-flexContainer]": {
      margin: "0 5rem 3rem 1rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "3rem 10px",
      "& div[class^=MuiTabs-flexContainer]": {
        margin: 0,
      },
    },
  },
  viewContainer: {
    padding: "20px",
    border: `1px solid ${theme.palette.secondary.main}`,
    "& ul": {
      margin: 0,
      padding: 0,
      listStyle: "none",
      "& li": {
        padding: "10px",
      },
    },
    "& p": {
      paddingBottom: "50px",
      fontSize: "20px",
    },
  },
  background1: {
    backgroundColor: theme.palette.secondary.light,
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "15rem",
    zIndex: 0,
  },
  snackbarSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  statusTblheader: {
    backgroundColor: "rgba(255, 7, 0, 0.55)",
  },
  snackbarError: {
    backgroundColor: theme.palette.error.main,
  },
  listSection: {
    marginTop: "20px",
  },
  formList: {
    marginTop: "20px",
  },
  formLink: {
    textDecoration: "none",
    color: theme.palette.primary.dark,
  },
  h2heading: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  primaryBtn: {
    display: "inline-block",
    border: "1px solid #000",
    marginRight: "1rem",
  },
  btn: {
    width: "10rem",
    backgroundColor: theme.palette.primary.light,
    borderRadius: "47px",
    color: theme.palette.white.main,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  abdnBtn: {
    width: "6rem",
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "47px",
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  listContent: {
    paddingBottom: "50px",
  },
  flexContainer: {
    display: " inline-flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "stretch",
    alignContent: "stretch",
  },
  flexItems: {
    display: "block",
    flexGrow: "0",
    flexhrink: "1",
    flexBasis: "auto",
    alignelf: "auto",
    order: "0",
    margin: "10px",
    height: "260px",
  },
  formListItem: {
    position: "relative",

    borderBottom: "1px solid grey",
    padding: "0 10px 0 0!important",
  },
  viewBtncontainer: {
    marginTop: "30px",
    textAlign: "left",
    width: "100%",
    padding: "10px 10px 10px 0",
    button: {
      marginLeft: "0",
    },
  },
  formChckbox: {
    width: "60%",
    lineHeight: "26px",

    paddingLeft: "10px",
  },
  formComments: {
    width: "34%",
    lineHeight: "26px",
  },
  table: {
    minWidth: 650,
  },
  smallBig: {
    width: "200px",
    lineHeight: "20px",
    height: "2.1rem",
    borderRadius: "47px",
    padding: "20px",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
      margin: theme.spacing(5, "auto"),
    },
    fontSize: "1.125rem",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: brandPalette["Secondary"]["200"],
    },
  },
  renewdate: {
    paddingLeft: "20px",
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
  },
}));
