export const postalAddressSchema = {
    title: "Edit Postal Address",
    logicalname: "Channel",
    type: "object",
    //required: ["name", "address", "primaryemail", "primarycontact"],
    properties: {
        ctpt_line1: {
            title: "Line 1",
            type: "string",
            datatype: "string"
        },
        ctpt_line2: {
            title: "Line 2",
            type: "string",
            datatype: "string"
        }
    }
};
