import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import FormWidget from "app/components/mvp/formWidget";
import { viewQualificationDetailsUiSchema } from "../model/schema/viewqualificationdetailsuiSchema";
import {
  getQualificationDetailsPayload,
  qualificationDetailsPayload,
} from "../model/payload/payload";
import { config } from "utilities/config";
import { useLocation, useHistory } from "react-router-dom";
import { post } from "app/services/api";
import { getQualificationDetailsSchema } from "../model/schema/schema";
import { useProfileStyles } from "../style";
import { usePreLoader } from "app/services/hooks/usePreLoader";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ViewQualificationDetails(props) {
  const [recordId, setrecordId] = useState("");
  const [recordStdId, setrecordstdId] = useState("");
  const [contact2FormData, setContact2FormData] = useState({});
  const getcontactId = useSelector((state) => state.contactid);
  let query = useQuery();
  const dispatch = useDispatch();
  const userEntitlement = useSelector((state) => state.entitleMents);
  const userRole = useSelector((state) => state.role);
  let permissions =
    userEntitlement && userEntitlement.find((x) => x.route == props.match.path);

  let formDataa = {};
  const { enableLoader } = usePreLoader();

  const classes = useProfileStyles();

  const options = {
    widgetType: "update",
    formSchema: getQualificationDetailsSchema,
    uiSchema: viewQualificationDetailsUiSchema,
    entityType: "mshied_previouseducations",
    button1Label:
      userRole && userRole.name && !userRole.name.includes("Individual")
        ? "Save"
        : "Submit",
    button2Label: "Go Back",
    apiURL: config.teleportal.submitForm.Update,
    payloadSubmit: qualificationDetailsPayload,
    goBackPage: "/profile/qualifications",
    historyUrl: "",
    button2GoBack: true,
    columns: [],
    contactId: getcontactId,
    formData: contact2FormData,
    hasModel: false,
    recordId: recordId,
  };

  const [contactOptions, setContactOptions] = useState(options);

  useEffect(() => {
    if (window.location.href.indexOf("?") > -1) {
      setrecordId(query.get("id"));
      setrecordstdId(query.get("sid"));
    }
  }, []);

  useEffect(() => {
    let payload = {
      ...getQualificationDetailsPayload,
      filters: [
        {
          field: "mshied_previouseducationid",
          searchvalue: recordId,
          type: "guid",
        },
      ],
    };

    getRecordDetails(payload);
  }, [recordId]);

  const updateFormData = (entityType, formData) => {
    if (
      userRole &&
      userRole.name &&
      userRole.name.includes("Individual") &&
      permissions &&
      permissions.permissionsets != null &&
      permissions.permissionsets.length > 0 &&
      permissions.permissionsets[0] != null &&
      permissions.permissionsets[0].canwrite == "0" &&
      permissions.permissionsets[0].cancreate == "0"
    ) {
      options.formSchema.properties.ctpt_enddate.isReadonly = true;
      options.formSchema.properties.ctpt_grade.disabled = true;
      options.formSchema.properties.ctpt_startdate.isReadonly = true;
      options.formSchema.properties.ctpt_type.disabled = true;
      options.formSchema.properties.ctpt_yearofaward.disabled = true;
      options.formSchema.properties.mshied_name.disabled = true;
      options.formSchema.properties._mshied_educationlevelid_value.disabled = true;
      options.formSchema.properties._mshied_schoolnameid_value.disabled = true;
    }
    options.formData = formData;
    options.initialFormData = formData;
    setContactOptions(options);
    setContact2FormData(formData);
  };

  const getRecordDetails = (payload) => {
    let api = config.teleportal.submitForm.getId;
    if (recordId !== "") {
      enableLoader(true, "Loading Qualifications Details");
      post(api, payload)
        .then((res) => {
          res.data.data.forEach((item) => {
            formDataa[item.name] = item.id != undefined ? item.id : item.value;
          });
          updateFormData({}, formDataa);
        })
        .catch(console.log)
        .finally(() => {
          enableLoader(false);
        });
    }
  };

  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Your Qualifications Updated successfully",
          code: "",
          display: "show",
          severity: "success",
        },
      });
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Qualifications update failed",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item xs={12} md={9}>
        <div className={classes.componentHolder}>
          <FormWidget
            options={contactOptions}
            updateFormData={updateFormData}
            successResponse={getResponse}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default ViewQualificationDetails;
