import React, { useEffect, useState, useCallback } from "react";
import { Grid, Button } from "@material-ui/core";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { getlistPayload, updateDeclaration } from "./model/payload";
import { config } from "utilities/config";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { post, postAll } from "app/services/api";
import { useStyles } from "../style";
import { usePreLoader } from "app/services/hooks/usePreLoader";
import _ from "lodash";
import Checkbox from "@material-ui/core/Checkbox";
import { TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DangerouslySetHtmlContent from "../../../../utilities/htmlParser";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 2rem 8rem 4rem 8rem;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;
const Heading = styled.div`

  border-radius: 4px; 
  height: 100px;
  //padding: 1rem 1.2rem;
  
  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;


  h2 {
    max-width: max-content;   
    //margin: 4rem 2.5rem;
    margin: 0;
    font-size: 1.5rem;
    letter-spacing: -0.16px;
    text-transform: uppercase;
    font-weight: 700;
    
    // border-radius: 8px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    );

     color:${globalTheme.palette.primary.main};
     margin-bottom: 25px;
  }

  ${globalTheme.breakpoints.down("sm")} {
    position: relative;
    width: 100%;
    h2 {
      margin-left: 0;
      font-size: 2.5rem;
    }
  }

  ${globalTheme.breakpoints.down("xs")} {
    height: 15rem;
    h2 {
     margin-left: 1rem;
     margin-right: 1rem;
    }
  }
}
`;
const TextBox = styled(TextField)(() => css``);

function ViewDeclaration() {
  const [recordId, setrecordId] = useState("");
  const [declarationFormData, setDeclarationFormData] = useState([]);
  const [declarationId, setDeclarationId] = useState("");
  const [formDatas, setFormDatas] = useState("");
  const getcontactId = useSelector((state) => state.contactid);
  const { enableLoader } = usePreLoader();
  const dispatch = useDispatch();

  const classes = useStyles();
  const history = useHistory();
  let { id } = useParams();

  const filterDatas = (obj, decVal, declareId) => {
    var b = _.find(obj.data, {
      name: "ctpt_memberdeclarationtype",
      value: eval(decVal),
    });

    if (_.isObject(b)) {
      addElement(obj, declareId);
    }
  };
  const addElement = (newElement, declareId) => {
    if (declarationFormData.length === 0) {
      setDeclarationFormData((prev) => [...prev, newElement]);
      setDeclarationId(declareId);
    }
  };
  const handleChkChange = (e, i) => {
    setFormDatas(e.target.value);
  };

  const declarePost = (formData) => {
    let promises = [];
    const apiURL = config.teleportal.submitForm.Update;
    const SERVER_DOMAIN = config.teleportal.env;
    declarationFormData.map((item, index) => {
      const declareComment = formData.get(`comment-${item.id}`);
      const declareAgree = formData.get(`agree-${item.id}`);
      const payload = updateDeclaration(item.id, declareComment, declareAgree);
      promises.push(axios.post(`${SERVER_DOMAIN}${apiURL}`, payload));
    });

    return promises;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    enableLoader(true);
    Promise.all(declarePost(formData))
      .then((values) => {
        enableLoader(false);
        dispatch({
          type: "SET_STATUS",
          payload: {
            message: "Successfully updated the declaration statements",
            code: "",
            display: "show",
            severity: "success",
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_STATUS",
          payload: {
            message: "Declaration statement failed",
            code: "",
            display: "show",
            severity: "error",
          },
        });
      });
  };

  const getRecordDetails = useCallback(() => {
    let api = config.teleportal.file.list;
    if (id !== "") {
      enableLoader(true, "Loading declaration statements");
      post(api, getlistPayload(getcontactId, id))
        .then((res) => {
          res.data.data.map((o) => {
            filterDatas(o, id);
          });
        })
        .catch(console.log)
        .finally(() => {
          enableLoader(false);
        });
    }
  }, [declarationFormData]);

  useEffect(() => {}, [declarationFormData, formDatas, declarationId]);

  useEffect(() => {
    getRecordDetails();
  }, [recordId]);

  useEffect(() => {}, []);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <StartPage>
              <div>
              <Heading>
                <h2>Membership Declarations</h2>
                </Heading>
                <p>
                  Please confirm your agreement with each declaration by
                  checking the box available. If you cannot agree with the
                  statement, then please provide a comment in the text box
                  provided as part of your submission
                </p>
                <div className={classes.viewContainer}>
                  <form onSubmit={handleSubmit}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableBody>
                        {declarationFormData.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCell className={classes.formChckbox}>
                              <Checkbox
                                style={{
                                  alignSelf: "flex-start",
                                  marginRight: "4px",
                                  float: "left",
                                }}
                                id={"CheckBox-" + index}
                                name={`agree-${item.id}`}
                                data-testid={"CheckBox-" + index}
                                required={false}
                                htmlFor={"CheckBox-" + index}
                                label={item.data[0].value}
                                key={index}
                                value={item.id}
                                onChange={(e) => handleChkChange(e, index)}
                                defaultChecked={item.data[5].value}
                              />
                              <DangerouslySetHtmlContent
                                html={item.data[0].value}
                              />
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.formComments}
                            >
                              <TextBox
                                id={item.id}
                                helperText=""
                                multiline={false}
                                required={false}
                                name={`comment-${item.id}`}
                                fullWidth
                                placeholder="Add comments"
                                defaultValue={item.data[4].value}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {/*} <ul>
                      {declarationFormData.map((item, index) => (
                        <li key={index} className={classes.formListItem}>
                          <div className={classes.formChckbox}>
                            <Checkbox
                              style={{
                                alignSelf: "flex-start",
                                marginRight: "4px",
                              }}
                              id={"CheckBox-" + index}
                              name={`agree-${item.id}`}
                              data-testid={"CheckBox-" + index}
                              required={false}
                              htmlFor={"CheckBox-" + index}
                              label={item.data[0].value}
                              key={index}
                              value={item.id}
                              onChange={(e) => handleChkChange(e, index)}
                              defaultChecked={item.data[5].value}
                            />
                            {item.data[0].value}
                          </div>

                          <div className={classes.formComments}>
                            <TextBox
                              id={item.id}
                              helperText=""
                              multiline={false}
                              required={false}
                              name={`comment-${item.id}`}
                              fullWidth
                              placeholder="Add comments"
                              defaultValue={item.data[4].value}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>*/}
                    <div className={classes.viewBtncontainer}>
                      <Button
                        size="medium"
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "1rem" }}
                        type="submit"
                      >
                        Save
                      </Button>
                      <Button
                        size="medium"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          history.goBack();
                        }}
                        style={{ marginLeft: "1.5rem" }}
                      >
                        Go Back
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </StartPage>
          </Grid>
          {/* <Grid item xs={12} md={2}></Grid> */}
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default ViewDeclaration;
