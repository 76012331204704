import React, { forwardRef } from "react";
import ListItem from "@material-ui/core/ListItem";
import { NavLink } from "react-router-dom";

const AppMenuItemComponent = (props) => {
  const { className, onClick, link, children, id } = props;

  // If link is not set return the orinary ListItem
  if (!link || typeof link !== "string") {
    return (
      <ListItem
        button
        className={className}
        children={children}
        onClick={onClick}
        data-nav-id={id}
      />
    );
  }

  // Return a ListItem with a link component
  return (
    <ListItem
      button
      className={className}
      children={children}
      onClick={() => {
        //INFO: This event will be captured by NavBar component and it will close the drawer.
        window.dispatchEvent(new Event("locationChanged"));
      }}
      component={forwardRef((navProps, ref) => (
        <NavLink exact {...navProps} innerRef={ref} />
      ))}
      to={link}
      data-nav-id={id}
    />
  );
};

export default AppMenuItemComponent;
