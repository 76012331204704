import { makeStyles } from "@material-ui/core";

const drawerWidth = "90%";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 10000,

    right: "auto!important",
    minHeight: "4.5rem",
  },
  offset: theme.mixins.toolbar,
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  closeMenuButton: { width: "3rem", margin: ".5rem" },

  drawerPaper: {
    boxSizing: "border-box",
    width: drawerWidth,
    backgroundColor: theme.palette.secondaryTint[50],
    left: "auto!important",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  hide: {
    display: "none",
  },
  Link: {
    textDecoration: "none",
    color: "#fff",
  },
  titleBox: {
    position: "absolute",
    zIndex: 2,
    [theme.breakpoints.up("md")]: {
      top: "5px",
      left: "25px",
    },
    [theme.breakpoints.down("md")]: {
      top: "10px",
      left: "55px",
    },
  },
  noMenuButton: {
    left: ".5rem",
  },
  titleBox1: {
    marginLeft: "auto",
    [theme.breakpoints.down("md")]: {
      marginRight: "0px",
    },
    "& :not(:last-child)": {
      "& .iconBtn": {
        borderRight: "1px solid rgba(255,255,255,0.5)",
        paddingRight: theme.globalSizes.xs,
      },
    },
  },
  toolBar: {
    justifyContent: "left",
    paddingLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
    },
  },
}));
