import React from "react";
import { Grid } from "@material-ui/core";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import FormWidget from "app/components/mvp/formWidget";
import { getStudentUiSchema } from "../../model/UiSchema";
import { config } from "utilities/config";
import { useLocation, useHistory } from "react-router-dom";
import { CreaterequestPayload } from "../../model/createPayload";
import { getEvidenceSchema } from "../../model/evidenceSchema";
import { createDocAttachmentPayload } from "../../model/createPayload";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 2rem 4rem 4rem 4rem;
    max-width: 60rem;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function ViewRecord() {
  const contactID = useSelector((state) => state.contactid);
  const { state: params } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Successfully Added Evidence.",
          code: "",
          display: "show",
          severity: "success",
        },
      });
      history.goBack();
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Failed to Add Evidence, Please try again later!",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };

  const options = {
    widgetType: "add",
    formSchema: getEvidenceSchema,
    uiSchema: getStudentUiSchema,
    apiURL: config.teleportal.submitForm.Create,
    entityType: "ctpt_supportingdocuments",
    formUischema: "",
    button1Label: "Submit",
    button2Label: "Cancel",
    button3Label: "Clear",
    payloadSubmit: CreaterequestPayload(params?.id),
    columns: [],
    contactId: contactID,
    historyUrl: "",
    payloadSecondform: createDocAttachmentPayload,
    isMemberDeclaration:false,
    hasMultiplecall: true,
    hasModel: false,
    onClickButton2: () => history.goBack(),
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <Grid container spacing={0}>
          {/* NOTE:layout */}
          {/* <Grid item xs={12} md={2}></Grid> */}
          <Grid item xs={12} md={12}>
            <StartPage>
              <FormWidget
                options={options}
                successResponse={getResponse}
              ></FormWidget>
            </StartPage>
          </Grid>
          {/* <Grid item xs={12} md={2}></Grid> */}
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default ViewRecord;
