export const getrecordPayload = {
  properties: [
    "ctpt_evidenceid",
    "ctpt_evidenceurl",
    "ctpt_howmanyoftheseunitscouldyouverifywithsup",
    "ctpt_howmanyunitsareyourclaimingforthisactivit",
    "ctpt_name",
    "ctpt_whatdidyoulearnandhowdidwillyouapplyit",
    "ctpt_whendidthisactivitytakeplace",
    "ctpt_whydidyouchoosethisactivityandhowisitre",
    "ctpt_descriptionofdevelopmentlearningactivity",
    "ctpt_cpdhours",
    "ctpt_supportingevidence",
    "_ctpt_memberdeclaration_value",
  ],
  formattedFields: [],
  recordType: "ctpt_evidences",
  id: "",
};

export const getdocPayload = {
  properties: [
    "ctpt_caseid",
    "ctpt_casenumber",
    "ctpt_title",
    "ctpt_details",
    "createdon",
    "statuscode",
    "modifiedon",
    "statecode",
    "ctpt_caseresolutiondetails",
  ],
  formattedFields: [],
  recordType: "ctpt_evidences",
  id: "",
};
