//Import React Library
import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthenticatedTemplate } from "@azure/msal-react";

//Import Material-UI Library
import { ThemeProvider } from "@material-ui/core/styles";

//Loading Data and Style..
import { globalTheme } from "style/globalTheme";
import { useStyles } from "./style";
//Loading components
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { config } from "utilities/config";
import { postSwf } from "app/services/api";

import ListComponent from "app/components/mvp/listWidget";
import { getlistPayload } from "./model/payload";
import { applicationNameMap } from "./model/applicationNameMap";
import TabWidget from "app/components/molecules/tab/TabWidget.mui";
import _ from "lodash";

const customFilter = (objects, filterCompletedApplications = false) => {
  const newRow = objects.data;
  return _.filter(
    newRow.filter(
      (o) =>
        o.name !== "eventreg" &&
        o.name !== "examreg" &&
        o.swfStatus ===
          (filterCompletedApplications ? "100000001" : "100000000")
    )
  );
};

function Applications() {
  const classes = useStyles();
  const [getparams, setgetparams] = useState({
    submissionId: "",
    webFormName: "",
  });
  const [open, setOpen] = React.useState(false);
  const [isCancel, setisCancel] = React.useState(false);
  const [updatedKey, setupdatedKey] = React.useState(false);
  const role = useSelector((state) => state.role);
  const getcontactId = useSelector((state) => state.contactid);
  const dispatch = useDispatch();

  const continueClick = useCallback((params) => {
    localStorage.setItem("ctpt_sd", params.row.id);
    localStorage.setItem("ctpt_fm", params.row.name);
    let path =
      window.location.protocol +
      "//" +
      window.location.host +
      "/application/" +
      params.row.name +
      "?" +
      params.row.id;
    window.location.href = path;
  }, []);

  const cancelClick = () => {
    setOpen(false);
    postSwf(config.sonicwebform.cancelFormsubmit, getparams)
      .then((res) => {
        if (res.data.status === true) {
          setisCancel(true);
          setupdatedKey(!isCancel);
          dispatch({
            type: "SET_STATUS",
            payload: {
              message: "Successfully Cancelled",
              code: "",
              display: "show",
              severity: "success",
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_STATUS",
          payload: {
            message: "Cancel application failed",
            code: "",
            display: "show",
            severity: "error",
          },
        });
      });
  };

  const handleClickOpen = useCallback((params) => {
    setgetparams({
      submissionId: params.row.id,
      webFormName: params.row.name,
    });
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const renderApplicationName = (params) => {
    const applicationName = params.row.name
      ? applicationNameMap[params.row.name]
      : "No Name";

    return <div>{applicationName || params.row.name}</div>;
  };

  const completedcolumns = [
    { field: "id", flex: 1, headerName: " ", hide: true },
    {
      field: "name",
      flex: 1,
      headerName: "Application Name",
      renderCell: renderApplicationName,
    },
    { field: "sonicWebFormId", flex: 1, headerName: " ", hide: true },
  ];

  const completedOptions = {
    apiURL: config.teleportal.custom.fetch,
    apiUrlDel: config.teleportal.file.delete,
    columns: completedcolumns,
    searchPayload: "searchFilter",
    getlistPayload: getlistPayload,
    deleteListPayload: {},
    customFilter: (objects) => customFilter(objects, true),
    //listHeading: "Completed Applications​",
    listDescription:
      "We have received the applications below and they are being processed. You will be notified once they are approved.",
    formWidget: {},
    nonRecord: false,
    role: role,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: true,
    filterField: "swfStatus",
    filterValue: [100000001],
    aggregateColumns: [],
    aggregateField: "",
    isCustomFetch: true,
    hideSearch: true,
    pageSize: 10,
    hideAdd: true,
    controlLists: false,
  };

  useEffect(() => {
    localStorage.setItem("oid", "");
  }, []);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div class={classes.background1}></div>
        <div className={classes.container}>
          {getcontactId !== "" ? (
            <TabWidget
              options={{
                defaultTabID: 1,
                tabNavJson: [
                  {
                    label: "Applications In Progress",
                    id: 1,
                    entity: (
                      <div class={classes.listSection}>
                        <InProgress
                          role={role}
                          getcontactId={getcontactId}
                          continueClick={continueClick}
                          handleClickOpen={handleClickOpen}
                          _updatedKey={updatedKey}
                        />
                      </div>
                    ),
                  },
                  {
                    label: "Completed Applications",
                    id: 2,
                    entity: (
                      <div class={classes.paidInvoiceList}>
                        <ListComponent options={completedOptions} />
                      </div>
                    ),
                  },
                ],
              }}
            />
          ) : (
            <div />
          )}
        </div>
        <div id="cTTP_applicationList">
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to cancel the application?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button onClick={cancelClick} autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

const InProgress = React.memo(
  ({ role, getcontactId, continueClick, handleClickOpen, _updatedKey }) => {
    /** 
      INFO: the prop _updatedKey will be updated when abandon operation is triggered, hence triggering
      the re-render to fetch the updated application list. 
    **/

    const classes = useStyles();

    const renderApplicationName = (params) => {
      const applicationName = params.row.name
        ? applicationNameMap[params.row.name]
        : "No Name";

      return <div>{applicationName || params.row.name}</div>;
    };

    const columns = [
      { field: "id", flex: 1, headerName: " ", hide: true },
      {
        field: "name",
        flex: 1,
        headerName: "Application Name",
        renderCell: renderApplicationName,
      },

      { field: "sonicWebFormId", flex: 1, headerName: " ", hide: true },
      { field: "status", flex: 1, headerName: "Status", hide: true },
      {
        field: "action",
        headerName: " ",
        flex: 1,
        renderCell: (params) => (
          <div>
            <Button
              variant="contained"
              className={classes.btn}
              onClick={() => {
                continueClick(params);
              }}
              disabled={params.row.name == null || params.row.id == null}
            >
              Resume
            </Button>
            &nbsp;
            <Button
              variant="outlined"
              className={classes.abdnBtn}
              onClick={() => {
                handleClickOpen(params);
              }}
              disabled={params.row.name == null || params.row.id == null}
            >
              Abandon
            </Button>
          </div>
        ),
      },
    ];

    const progressOptions = {
      apiURL: config.teleportal.custom.fetch,
      apiUrlDel: config.teleportal.file.delete,
      columns: columns,
      searchPayload: "searchFilter",
      getlistPayload: getlistPayload,
      deleteListPayload: {},
      //listHeading: "Applications in progress",
      listDescription:
        "The applications below are not complete and cannot be processed. Please complete them using the resume buttons below.​",
      formWidget: {},
      nonRecord: false,
      role: role,
      customFilter: customFilter,
      rows: "",
      localDataStore: false,
      hideDelete: true,
      hasAggregate: false,
      staticTable: false,
      staticRow: [],
      hiddenColumn: {},
      contactId: getcontactId,
      filteredValue: "",
      hasFilter: true,
      filterField: "swfStatus",
      filterValue: [100000000],
      aggregateColumns: [],
      aggregateField: "",
      isCustomFetch: true,
      hideSearch: true,
      pageSize: 10,
      hideAdd: true,
      controlLists: false,
    };
    return <ListComponent options={progressOptions} />;
  }
);

export default Applications;
