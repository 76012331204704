export const getlistPayload =
{
    "properties": [
        "ctpt_attempt",
        "ctpt_name",
        "ctpt_candidatenumber",
        "ctpt_ismockexam",
        "_ctpt_contactid_value",
        "_ctpt_examid_value",
        "ctpt_isshortlisted",
        "ctpt_mark",
        "ctpt_officecode",
        "ctpt_result"
    ],
    "formattedFields": [
        "_ctpt_examid_value"
    ],
    "filter": {
        "globalfilter": "",
        "globalAndOperator": false,
        "fieldAndOperator": false,
        "filterfields": [
            {
                "field": "_ctpt_contactid_value",
                "searchvalue": "",
                "type": "guid"
            }
        ]
    },
    "Aggregate": {
        "AggregateFields": []
    },
    "sortfield": {
        "field": "createdon",
        "sorttype": "desc"
    },
    "recordtype": "ctpt_examresults",
    "idfield": "ctpt_examresultid",
    "continuationToken": "",
    "pazesize": "10"
}


