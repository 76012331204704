import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .statusTblheader": {
      backgroundColor: "rgba(255, 7, 0, 0.55)",
    },
  },
  snackbarSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  statusTblheader: {
    backgroundColor: "rgba(255, 7, 0, 0.55)",
  },
  snackbarError: {
    backgroundColor: theme.palette.error.main,
  },
  listSection: {
    margin: "1rem auto",
    [theme.breakpoints.down("xs")]: {
      margin: "3rem auto",
    },
  },
  paidInvoiceList: {
    margin: "0 auto",
  },
  formList: {
    marginTop: "20px",
  },
  formLink: {
    textDecoration: "none",
    color: theme.palette.primary.dark,
  },
  h2heading: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  listContent: {},
  primaryBtn: {
    display: "inline-block",
    border: "1px solid #000",
  },
  actionsButtons: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    minHeight: "55rem",
    padding: "2rem 2rem",
    "& div[class^=MuiTabs-flexContainer]": {
      margin: "0 5rem 3rem 5rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "3rem 0",
      "& div[class^=MuiTabs-flexContainer]": {
        margin: 0,
      },
    },
  },
  dueListHeader: {
    minWidth: 190,
  },
  paidListHeader: {
    minWidth: 90,
  },
  dueColumHeader: {
    minWidth: 80,
  },
  alignCenter: {
    textAlign: "center",
    minWidth: 57,
  },
  invoiceName: {
    minWidth: "22rem",
    textAlign: "center",
  },
  background1: {
    backgroundColor: theme.palette.secondary.light,
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "12rem",
    zIndex: 0,
    borderBottom: "3px solid",
    borderColor: theme.palette.primary.dark,
  },
  symbolStyle: {
    fontSize: "13px",
  },
}));
