import React, { useState } from "react";
import { settings } from "constants/settings";

async function loadComponent() {
  return import("./SonicWebForm." + settings.UI + ".js");
}

function Sonicwebform(props) {
  const { children, data, options, getForm } = props;
  const [component, setComponent] = useState(null);

  loadComponent().then(x => setComponent(x));
  if (!component) return null;
  return (
    <component.default data={data} options={options} getForm={getForm}>
      {children}
    </component.default>
  );
}

export default Sonicwebform;
