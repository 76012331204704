import { makeStyles } from "@material-ui/core/styles";
import { brandPalette } from "brand/theme/brandTheme";
import { globalTheme } from "style/globalTheme";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
      overflow: "visible",
    },
  },
  Paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(10),
    textAlign: "center",
    color: theme.palette.text.secondary,
    minHeight: "1000px",
  },
  heroImg: {
    [theme.breakpoints.down("xs")]: { marginTop: "-3rem" },
    maxHeight: "500px",
    minHeight: "428px",
    position: "absolute",
    zIndex: "1",
    left: "0px",
    right: "0px",
    width: "100%",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    // background: "url(/brand/slide4.jpeg) top left no-repeat",
    background: "url(/brand/ciipa-banner-image3.jpg) top left no-repeat",
    //background: "url(/brand/WEB-CIIPA-MemberPage.jpg) top left no-repeat",
  },
  paperMargin: {
    marginTop: "40px",
  },
  leftBlock: {
    margin: theme.spacing(10, "auto"),
    padding: theme.spacing(10),
  },
  textHead1: {
    margin: theme.spacing(10, "auto"),
    textAlign: "left",
  },
  textHead: {
    margin: theme.spacing(10, "auto"),
    textAlign: "left",
  },
  textSpacing: {
    padding: "30px",
  },
  textBox: {
    padding: "30px",
    height: "33vh",
    minHeight: "324px",
  },
  Divider: {
    margin: "20px",
  },
  buttonElem: {
    justifyContent: "center",
    margin: theme.spacing(10, "auto"),
  },
  buttonBand: {
    height: "6rem",
    backgroundColor: `${globalTheme.palette.secondary.light}`,

    position: "relative",
    zIndex: "2",
    opacity: "1",
    marginTop: "380px",
    [theme.breakpoints.down("xs")]: { marginTop: "300px", opacity: "1" },
  },
  bodyCopy2: {
    fontSize: "22px",
    paddingLeft: "15px",
    paddingRight: "15px",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "1px",
  },
  buttonBig: {
    width: "12rem",
    height: "3.1rem",
    borderRadius: "47px",
    margin: theme.spacing("auto"),
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
      margin: theme.spacing(5, "auto"),
    },
    fontSize: "1.125rem",
    backgroundColor: brandPalette["Secondary"]["50"],
  },
  smallBig: {
    width: "200px",
    lineHeight: "20px",
    height: "2.1rem",
    borderRadius: "47px",
    padding: "20px",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
      margin: theme.spacing(5, "auto"),
    },
    fontSize: "1.125rem",
    backgroundColor: brandPalette["Secondary"]["50"],
  },
  primary: {
    "&:hover": {
      color: brandPalette["Primary"]["50"],
      backgroundColor: brandPalette["Primary"]["200"],
    },
    fontWeight: "bold",
    //backgroundColor: theme.palette.secondary.main,

    backgroundColor: brandPalette["Primary"]["200"],
  },
  secondary: {
    color: brandPalette["Primary"]["900"],
    border: `2px solid ${brandPalette["Secondary"]["50"]}`,
    fontWeight: "bold",
    marginRight: "20px",
    "&:hover": {
      color: `${brandPalette["Primary"]["50"]}`,
      border: `2px solid ${brandPalette["Primary"]["50"]}`,
      backgroundColor: "transparent",
      opacity: "1",
    },
  },
  textBand: {
    padding: theme.spacing(0),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  navlist: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  homePageSubTitle: {
    backgroundColor: theme.palette.grey[500],
    opacity: "0.8",
  },
  bannerContainer: {
    background: "url(/brand/ciipa-banner-image1.jpg)",
    //backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "16rem",
    //width: "100%",
    borderRadius: "3px",
    margin: "1rem",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      backgroundPositionX: "center",
    },
  },
  banner: {
    width: "100%",
    height: "200%",
    display: "none",
  },
  imageCards: {
    marginTop: "1.7rem",
    paddingBottom: "60px",
  },
  heading: {
    //color: "red",
  },
  iframe: {
    display: "block",
  },
  tileCardContainer: {
    width: "400px",
    height: "400px",
    // flexWrap: "wrap",
    // flex: "1 0 30%",
  },
  tileCardContainerBlock: {
    padding: 0,
    margin: 0,
  },
  tileCardContainerItem: {
    fontWeight: "400",
    fontFamily: "Onest,sans-serif",
    lineHeight: "120%",
    fontSize: "18px",
    textTransform: "inherit",
    textDecoration: "none",
    margin: "0",
    padding: "0",
    color: "#01519b",
    "&:hover:before": {
      width: "100%",
    },
    "&:hover:after": {
      opacity: "1",
    },

    "&::before": {
      content: '""',
      position: "absolute",
      top: "0",
      left: "0",
      bottom: "0",
      background: "rgba(1,81,155,.05)",
      width: "0%",
      transition: "all .3s ease-in-out",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: "0",
      left: "-5px",
      bottom: "0",
      transition: "all .3s ease-in-out",
      width: "5px",
      background: "linear-gradient(0deg,#01519b 0,#00a4bd 100%);",
      opacity: ".5",
    },
    "&:hover": {
      color: "#00a4bd",
    },
  },
  tileh4: {
    padding: "20px",
    fontSize: "24px",
    color: "#01519b",
    fontWeight: "400",
    /* margin: 0; */
    marginBlockStart: "unset",
    marginBlockEnd: "unset",
    marginLeft: "16px",
  },
  blockquoteDiv: {
    display: "flex",
    alignItems: "center",
    borderLeft: "5px solid transparent",
    backgroundColor: "transparent",
    padding: "15px",
    position: "relative",
    height: "100%",
    transitionDelay: ".1s",
    cursor: "pointer",
    transition: "all .3s ease-in-out",
  },

  description: {
    marginTop: "-.2rem !important",
    marginBottom: "1.2rem",
    maxWidth: "50rem",
  },
  showflex: {
    display: "block",
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    fontSize: "15px",
    padding: "10px",
  },
  profileHeading: {
    backgroundColor: theme.palette.secondary.main,
  },
  hideflex: {
    display: "none",
  },
  profileBlock: {
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    fontSize: "15px",
  },
  notificationBar: {
    backgroundColor: theme.palette.secondary.light,
    padding: "20px",
    width: "100%",
    position: "relative",
    border: `1px solid ${theme.palette.secondary.main}`,
    "& >h6": {
      float: "left",
      fontSize: "20px",
      color: theme.palette.primary.main,
    },
    "& button": {
      float: "right",
    },
  },
  declareBlock: {
    "& ul li": {
      fontSize: "10px",
      paddingBottom: "5px",
    },
    "& >h6": {
      fontSize: "12px",
      padding: "0",
      margin: "0",
      marginLeft: "25px",
      color: theme.palette.primary.main,
    },
  },
  declareSection: {
    "& >h4": {
      fontSize: "18px",
      marginLeft: "25px",
      color: theme.palette.primary.main,
    },

    "& >p": {
      fontSize: "12px",
      paddingLeft: "30px",
    },
  },
}));
