import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getemaillistPayload } from "../model/payload/emailPayload";
import { config } from "utilities/config";

import ListComponent from "app/components/mvp/listWidget";
import { TELEPHONE_TYPE_MAP } from "../model/data/constants";
import { Button } from "@material-ui/core";
import { useStyles } from "../style";

function EmailInformation(props) {
  const contactid = useSelector((state) => state.contactid);
  const getcontactId = useSelector((state) => state.contactid);
  const classes = useStyles();
  const history = useHistory();
  const updateDetails = (params) => {
    window.location.href =
      "/profile/email/update?id=" + params.row.ctpt_channelid;
  };

  const columns = [
    { field: "ctpt_channelid", flex: 1, headerName: " ", hide: true },
    {
      field: "ctpt_emailtype",
      flex: 1,
      headerName: "Email Type",
      renderCell: (params) => (
        <div>
          {params.row.ctpt_emailtype !== null
            ? TELEPHONE_TYPE_MAP[3][params.row.ctpt_emailtype]
            : "-"}
        </div>
      ),
    },
    {
      field: "ctpt_emailaddress",
      flex: 2,
      headerName: "Email Address",
      renderCell: (params) => (
        <div>
          {params.row.ctpt_emailaddress !== null
            ? params.row.ctpt_emailaddress
            : "-"}
        </div>
      ),
    },
    {
      field: "action",
      flex: 1,
      headerName: <p>Action</p>,
      excludeFromSearch: true,
      renderCell: (params) => (
        <div>
          <Button
            variant="outlined"
            color="primary"
            className="primaryBtn"
            onClick={() => {
              updateDetails(params);
            }}
          >
            Update Details
          </Button>
        </div>
      ),
    },
  ];

  const options = {
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: columns,
    searchPayload: "searchFilter",
    getlistPayload: getemaillistPayload(contactid),
    deleteListPayload: {},
    listHeading: "Email",
    formWidget: {},
    nonRecord: false,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "",
    filterValue: [],
    aggregateField: "",
    hideSearch: true,
    pageSize: 6,
    hasPayloadfilter: true,
    hideAdd: true,
    controlLists: false,
  };

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item xs={12} md={9}>
        <div className={classes.componentHolder}>
          <Button
            size="medium"
            variant="outlined"
            onClick={() => {
              history.push("/profile");
            }}
            style={{ marginBottom: "1.5rem" }}
          >
            Go Back
          </Button>
          <ListComponent options={options} />
        </div>
      </Grid>
    </Grid>
  );
}

export default EmailInformation;
