import CustomTextBox from "app/components/atoms/customTextBox";

export const workAddressUiSchema = {
  "ui:title": "",
  ctpt_line1: {
    "ui:widget": props => {
      return <CustomTextBox getProps={props} />;
    }
  },
  ctpt_city: {
    "ui:widget": props => {
      return <CustomTextBox getProps={props} />;
    }
  },
  ctpt_county: {
    "ui:widget": props => {
      return <CustomTextBox getProps={props} />;
    }
  }
  
};
