export const contactSchema = {
  title: "Update Email",
  logicalname: "Contact",
  type: "object",
  properties: {
    ctpt_emailtype: {
      title: "Email Type",
      type: "string",
      schemaname: "ctpt_emailtype",
      datatype: "array",
      enum: ["2", "1"],
      enumNames: ["Work", "Personal"],
    },
    ctpt_emailaddress: {
      title: "Email Address",
      type: "string",
      schemaname: "ctpt_emailaddress",
      datatype: "string",
      requiredCheck: true,
      pattern:
        "^[a-zA-Z0-9.\\-_]+(\\.[a-zA-Z0-9\\-_]+)*@[a-z0-9]+(\\-[a-z0-9]+)*(\\.[a-z0-9]+(\\-[a-z0-9]+)*)*\\.[a-z]{2,4}$",
      message: "Invalid Email id",
    },
  },
};

export const telephoneSchema = {
  title: "Update Telephone",
  logicalname: "Contact",
  type: "object",
  properties: {
    ctpt_telephonetype: {
      title: "Telephone Type",
      type: "string",
      schemaname: "ctpt_telephonetype",
      datatype: "array",
      enum: ["1", "2"],
      enumNames: ["Work", "Personal"],
    },
    ctpt_telephone: {
      title: "Telephone number",
      type: "string",
      schemaname: "ctpt_telephone",
      datatype: "string",
      requiredCheck: true,
      pattern: "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$",
      message: "Please enter valid telphone number",
    },
  },
};
