import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import Box from "../LayoutBox";

const Wrapper = styled(Box)(
  (props) => css`
    overflow: hidden;
    position: relative;
    width: 90%;
    border-radius: 50px;
    margin: 0 auto;
    margin-bottom: 30px;
  `
);
/**
 * The `Frame` component acts as a placeholder for responsive media content (images or video). It is sized to 100% width of its parent container and takes a `ratio` prop which sets the **aspect ratio** of the required, responsive content - the source media is cropped to fit the aspect ratio using `object-fit:cover`.
 *
 * The aspect ratio of an element is derived by dividing its width by its height, and it is expressed in the format  &quot;width:height&quot;
 *
 * A traditional landscape photograph has the aspect ratio `4:3` and this is still the most common size for an illustrative photo on the web. Widescreen devices such as TVs and phones have led the `16:9` aspect ratio becoming increasingly common for video.
 *
 * The Frame ensures that media from disparate sources such as user uploads, remote APIs etc over which we may have no control, can be displayed in a uniform fashion. Care should be taken of course not to simply rely on a frame for rendering all media - large, unoptimised images will add significant weight to the page and should be optimised for web use wherever possible.*/

export default function Frame({ children, ratio = "", ...rest }) {
  const [frameWidth, frameHeight] = ratio.includes(":")
    ? ratio.split(":")
    : ["4", "3"];
  return (
    <Wrapper frameWidth={frameWidth} frameHeight={frameHeight} {...rest}>
      {children}
    </Wrapper>
  );
}

Frame.propTypes = {
  /** the contents of the frame - may be an image or video */
  children: PropTypes.node,
  /** The aspect ratio for the image the frame is to display */
  ratio: PropTypes.string,
};
