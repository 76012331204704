import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, CssBaseline } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { loginRequest, signupRequest } from "constants/msalConfig";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
  useAccount,
} from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useStyles } from "./style";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { InteractionStatus } from "@azure/msal-browser";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Cluster from "layout/Cluster";
import Stack from "layout/Stack";
import ImageCard from "app/components/molecules/imageCard/ImageCard.mui";
import StatusBar from "app/components/atoms/statusbar";
import Row from "layout/Row";
import { callRedirectAPI } from "utilities/utils";
import { getContactDetails } from "./service";
import { usePreLoader } from "app/services/hooks/usePreLoader";
import { HOME_TILES_DATA } from "./model/constants";
import "./styles.css";
import moment from "moment";
import { config } from "utilities/config";
import { post } from "app/services/api";
import { appData } from "data/appData";
import { useHistory } from "react-router-dom";
import _ from "lodash";

//layout

function Home() {
  const classes = useStyles();
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const contactId = useSelector((state) => state.contactid);
  const token = useSelector((state) => state.token);
  const [checked, setChecked] = React.useState(false);
  const { enableLoader } = usePreLoader();

  const [contactDetails, setContactDetails] = useState({});
  const [profileUpdate, setProfileupdate] = useState(false);

  const history = useHistory();

  const compareDates = () => {
    const startDate = contactDetails.profileupdateDate;
    const currenDate = moment(new Date()).format("DD/MM/YYYY");
    const endDate = moment(currenDate, "DD/MM/YYYY");
    const results = endDate.diff(startDate, "months");
    const expiryDate = appData[0].utilities.profileExpiryDuration;
    if (results > expiryDate) {
      setProfileupdate(true);
    } else {
      setProfileupdate(false);
    }
  };

  const saveProfile = () => {
    if (checked) {
      const apiURL = config.teleportal.submitForm.Update;
      const saveDate = new Date();
      const payload = {
        recordtype: "contacts",
        id: contactId,
        data: [
          {
            name: "ctpt_profiledetailsconfirmedaccurateon",
            value: saveDate,
            type: "string",
          },
        ],
      };
      post(apiURL, payload)
        .then((res) => {
          if (res) {
            setProfileupdate(false);
            enableLoader(false);
          }
        })
        .catch(console.log);
    } else {
      setProfileupdate(false);
    }
  };

  const handleLocalLogin = () => {
    localStorage.clear();
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      callRedirectAPI(instance, loginRequest);
      localStorage.setItem("isRedirect", false);
      localStorage.setItem("tknPay", "tokenRequest");
      localStorage.setItem("isSocial", false);
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleGoogleSignIn = () => {
    callRedirectAPI(instance, signupRequest);
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch({ type: "SET_AUTH", payload: true });
      dispatch({
        type: "SET_USER",
        payload: { name: account.idTokenClaims.given_name },
      });
    }
  }, [account, dispatch, isAuthenticated]);

  useEffect(() => {
    const fetchContactDetails = async () => {
      if (isAuthenticated && contactId && token) {
        const contactDetails = await getContactDetails(contactId);

        setContactDetails(contactDetails);
        dispatch({
          type: "SET_ACCOUNT_STATUS",
          payload: contactDetails.accountStatus,
        });
      }
      enableLoader(false);
    };

    fetchContactDetails().catch(console.log);
  }, [contactId, isAuthenticated, token, enableLoader, dispatch]);

  useEffect(() => {
    dispatch({ type: "SET_CONTACTDET", payload: contactDetails });
    compareDates();
  }, [contactDetails, dispatch]);

  return (
    <ThemeProvider theme={globalTheme}>
      <CssBaseline />
      <UnauthenticatedTemplate>
        <Grid container>
          <Grid item className={classes.heroImg} id="herobanner"></Grid>
        </Grid>
        <Cluster
          className={classes.buttonBand}
          align="center"
          padding="large"
          gap="medium"
        >
          <Row className={classes.textBand} align="center">
            <LockOutlinedIcon size="large" />
            <Typography variant="body2" className={classes.bodyCopy2}>
              CIIPA
            </Typography>
          </Row>

          <Button
            variant="Contained"
            className={clsx(classes.buttonBig, classes.secondary)}
            onClick={() => handleLocalLogin()}
          >
            Login
          </Button>

          <Button
            variant="Contained"
            className={clsx(classes.buttonBig, classes.secondary)}
            onClick={() => handleGoogleSignIn()}
          >
            Register
          </Button>
        </Cluster>
        <div className={classes.imageCards}>
          <Cluster justify="evenly" padding="medium" gap="medium">
            <Grid item xs={12} sm={4} spacing={0}>
              <ImageCard
                header="Pathways & Careers"
                image={<img alt="member" src="/brand/tile_image3.jpeg" />}
              >
                <Typography variant="body1">
                  An accounting profession offers a wide range of pathways and
                  careers, from traditional accounting and auditing to financial
                  management and reporting.
                </Typography>
              </ImageCard>
            </Grid>
            <Grid item xs={12} sm={4} spacing={0}>
              <ImageCard
                header="Training and Events"
                image={<img alt="member" src="/brand/tile_image1.jpeg" />}
              >
                <Typography variant="body1">
                  CIIPA offers a number of training opportunities and networking
                  events throughout the year for private and public accountants.
                </Typography>
              </ImageCard>
            </Grid>
            <Grid item xs={12} sm={4} spacing={0}>
              <ImageCard
                header="Membership"
                image={<img alt="member" src="/brand/tile_image2.jpeg" />}
              >
                <Typography variant="body1">
                  Join CIIPA's membership today and enjoy a number of membership
                  benefits.
                </Typography>
              </ImageCard>
            </Grid>
          </Cluster>
        </div>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        {/* <div class={classes.bannerContainer}></div> */}
        <Grid component="main" container spacing={0} className={classes.root}>
          <StatusBar className="show" open={true} />
          <Grid item xs={12}>
            <Stack gap="medium" padding="large">
              <div
                className={`profileBlock ${
                  profileUpdate ? classes.showflex : classes.hideflex
                }`}
              >
                <Typography
                  variant="h6"
                  element="h6"
                  className="profileHeading"
                >
                  Please check your profile details are up to date
                </Typography>
                <FormControlLabel
                  key="saveid"
                  control={
                    <Checkbox
                      checked={checked}
                      name="checked"
                      color="primary"
                      onChange={handleChange}
                    />
                  }
                  label="I can confirm my profile details are up to date"
                />
                <div>
                  <Button
                    disabled={!checked}
                    variant="contained"
                    onClick={saveProfile}
                    color={"primary"}
                  >
                    {" "}
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    disabled={checked}
                    style={{ marginLeft: "1rem" }}
                    onClick={() => history.push("/profile")}
                  >
                    {" "}
                    Go to profile
                  </Button>
                </div>
              </div>

              <Cluster justify="start" gap="large">
                {HOME_TILES_DATA.map((tile) => (
                  <div className={classes.tileCardContainer} key={tile.title}>
                    <div className={classes.tileCardContainerBlock}>
                      <h3 className={classes.tileh4}>{tile.title}</h3>
                      {tile.items?.map((link, index) => (
                        <div>
                          <blockquote className={classes.blockquoteDiv}>
                            <a
                              key={index}
                              className={classes.tileCardContainerItem}
                              href={link.link}
                            >
                              {link.name}
                            </a>
                          </blockquote>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </Cluster>
            </Stack>
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default Home;
