import React from "react";
import html2pdf from "html2pdf.js";
import { invoiceData } from "./model/invoicedata";

export default function Examreport(props) {
  const downloadPdf = () => {
   // let invoiceDetail=JSON.parse(document.getElementById("examReport"))
    //let orderNum=invoiceDetail.invoiceNumber;
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-');
    let pdfFile = document.getElementById("examReport").cloneNode(true);
 
    html2pdf()
      .set({
        filename:"examreport-"+formattedDate+'.pdf',
        jsPDF:{ unit:'mm', format: 'a4', orientation: 'landscape' },
   
        
      })
      .from(pdfFile)
      .toPdf()
      .get("pdf")
      .then(function (pdfObject) {
        // get the number of pages in the pdf
        let totalPages = pdfObject.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          // We are telling our pdfObject that we are now working on this page and
          // setting font size of text and color of text
          pdfObject.setPage(i);
          pdfObject.setFontSize(10);
          pdfObject.setFont("Helvetica");
          pdfObject.setTextColor(100);
          var footerContent =
            invoiceData.footer.disclaimer1 +
            " " +
            `31st December ${new Date().getFullYear()}` +
            " ." +
            "Please note that your is liable to be struck off the Register of Members if payment of subscription is not received\n" +
            "\n" +
            invoiceData.footer.address +
            "\n" +
            invoiceData.footer.note;
          // showing page number on bottom of page
          var splitTitle = pdfObject.splitTextToSize(footerContent, 200);
          pdfObject.text(105, 260, splitTitle, "center");
          pdfObject.internal.write(0, "Tw");
          pdfObject.text(
            pdfObject.internal.pageSize.getWidth() - 30,
            pdfObject.internal.pageSize.getHeight() - 4,
            "Page " + i + " of " + totalPages
          );
        }
      })
      .save();
  };
  React.useEffect(() => {
    props.downloadFunc.current = downloadPdf;
  }, []);

  return (
    <div>
      <div style={{ position: "absolute", overflow: "hidden", height: "0%" }}>
        <div id="renderInvoice"  style={{ position: "relative", width: "100%" }}>
          <table width="100%">
            <tr>
              <td width="70%">
                {" "}
                <img
                  src={invoiceData.header.logo}
                  width="150"
                  height="80"
                  alt="logo"
                />
              </td>
              <td
                width="30%"
                style={{
                  fontSize: "11px",
                  textAlign: "right",
                  paddingRight: "10px",
                }}
              >
                <div>{invoiceData.header.address}</div>
                <div>
                  Tel:&nbsp;{invoiceData.header.phone}, Fax:&nbsp;
                  {invoiceData.header.fax}
                </div>
                <div>
                  Email:&nbsp;{invoiceData.header.email}, Website:&nbsp;
                  {invoiceData.header.website}
                </div>
                <div>VAT NUMBER:&nbsp;{invoiceData.header.vat}</div>
              </td>
            </tr>
            <tr>
              <td
                colSpan="2"
                style={{
                  textAlign: "right",
                  textTransform: "uppercase",
                  fontSize: "20px",
                  fontWeight: "bold",
                  padding: "20px 10px 20px 20px",
                }}
              >
                {invoiceData.heading}
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <table width="100%">
                  <td width="60%">
                  
                  </td>
                  <td width="40%">
                    <table width="100%">
                      <tr>
                        <td
                          width="40%"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                        
                        </td>
                        <td width="60%" style={{ fontSize: "12px" }}>
                         
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="30%"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          Membership Category:
                        </td>
                        <td width="70%" style={{ fontSize: "12px" }}>
                         
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="30%"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          Invoice Number:
                        </td>
                        <td width="70%" style={{ fontSize: "12px" }}>
                         
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="30%"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          Invoice Date:
                        </td>
                        <td width="70%" style={{ fontSize: "12px" }}>
                         
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="30%"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          Due Date:
                        </td>
                        <td width="70%" style={{ fontSize: "12px" }}>
                          {`31st December ${new Date().getFullYear()}`}
                        </td>
                      </tr>
                    </table>
                  </td>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan="2" style={{ paddingTop: "100px" }}>
                <table
                  width="95%"
                  align="right"
                  style={{ fontSize: "14px", borderCollapse: "collapse" }}
                >
                  <tr style={{ borderBottom: "1px solid black" }}>
                    <th style={{ paddingBottom: "20px" }}>Code</th>
                    <th style={{ paddingBottom: "20px" }}>Description</th>
                    <th style={{ paddingBottom: "20px" }}>Unit</th>
                    <th style={{ paddingBottom: "20px" }}>Qty</th>
                    <th style={{ paddingBottom: "20px" }}>VAT</th>
                    <th style={{ paddingBottom: "20px" }}>Amount&nbsp;(<span style={{fontStyle: "italic",fonWeight:"normal"}}></span>)</th>
                  </tr>
                 
                  <tr>
                    <td colSpan="3"></td>
                    <td
                      colSpan="2"
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        borderTop: "1px solid black",
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }}
                    >
                      Total excl. VAT
                    </td>
                    <td
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        borderTop: "1px solid black",
                        fontWeight: "bold",
                      }}
                    >
                    
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3"></td>
                    <td
                      colSpan="2"
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }}
                    >
                      Value added tax
                    </td>
                    <td
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontWeight: "bold",
                      }}
                    >
                     
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3"></td>
                    <td
                      colSpan="2"
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontWeight: "bold",
                        fontStyle: "italic",
                      }}
                    >
                      Invoice total due
                    </td>
                    <td
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                       
                      }}
                    >
                     
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}