//Import React Library
import React, { useState } from 'react';

import { settings } from "constants/settings"

async function loadComponent() {
  return import('./DatePickers.' + settings.UI + '.js')
}

function CustomDate(props) {

  const { children, getProps } = props;
  const [component, setComponent] = useState(null)

  loadComponent().then(x => setComponent(x))

  if (!component) return null;
  return (
    <component.default getProps={getProps}>{children}</component.default>
  )
}

export default CustomDate;