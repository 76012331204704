export const uploadCVSchema = {
  schema: {
    title: "",
    helpText: "",
    tootltipheading: "head",
    tootltipText: "text",
    type: "string",
    datatype: "upload",
    format: "string",
    requiredCheck: false,
    containerName: "contacts",
    fileName: "id",
    extensions: ".doc,.pdf",
    acceptType: [
      "application/pdf",
      "application/msword",
      "image/png",
      "image/jpeg",
    ],
    acceptExtension: [".pdf", ".doc", ".docx", ".png", ".jpeg"],
    recordPath: "",
    onChange: {},
  },
};

export const getQualificationDetailsSchema = {
  title: "Qualification Details",
  logicalname: "qualifications",
  type: "object",
  properties: {
    mshied_name: {
      title: "Previous Education Name",
      type: "string",
      datatype: "string",
      requiredCheck: true,
      pattern: "",
      message: "Only string allowed"
    },
    ctpt_schoolname: {
      title: "School Name",
      type: "string",
      datatype: "string",
      requiredCheck: false,
      pattern: "",
      message: "Only string allowed",
      // isDynamic: true,
      // apiURL: "/api/GetAllAsync",
      // payload: {
      //   properties: [
      //     "name",
      //     "accountid"
      //   ],
      //   filter: {
      //     globalfilter: "",
      //     globalAndOperator: false,
      //     fieldAndOperator: false,
      //     filterfields: [
      //       {
      //         field: "ctpt_accounttype",
      //         searchvalue: "533910004",
      //         type: "guid",
      //       },
      //     ]
      //   },
      //   Aggregate: {
      //     AggregateFields: []
      //   },
      //   sortfield: {
      //     field: "name",
      //     sorttype: "asc"
      //   },
      //   recordtype: "accounts",
      //   idfield: "accountid",
      //   continuationToken: "",
      //   pazesize: "1000"
      // },
      // enum: [
       
      // ],
      // enumNames: [
       
      // ]
    },
    ctpt_startdate: {
      title: "Start Date",
      type: "string",
      datatype: "number",
      requiredCheck: true,
      disableFuture: true
    },
    ctpt_enddate: {
      title: "End Date",
      type: "string",
      datatype: "number",
      requiredCheck: false,
      disableFuture: false
    },
    _mshied_educationlevelid_value: {
      title: "Education Level",
      type: "string",
      datatype: "array",
      requiredCheck: false,
      pattern: "",
      message: "Only string allowed",
      isDynamic: true,
      apiURL: "/api/GetAllAsync",
      payload: {
        properties: [
          "mshied_name",
          "mshied_educationlevelid"
        ],
        filter: {
          globalfilter: "",
          globalAndOperator: false,
          fieldAndOperator: false,
          filterfields: [
          ]
        },
        Aggregate: {
          AggregateFields: []
        },
        sortfield: {
          field: "mshied_name",
          sorttype: "asc"
        },
        recordtype: "mshied_educationlevels",
        idfield: "mshied_code",
        continuationToken: "",
        pazesize: "1000"
      },
      enum: [
       
      ],
      enumNames: [
       
      ]
    },
    ctpt_grade: {
      title: "Grade",
      type: "string",
      datatype: "string",
      requiredCheck: true,
      pattern: "",
      message: "Only string allowed"
    },
    // ctpt_level: {
    //   title: "Level",
    //   type: "string",
      
    //   datatype: "string",
    //   requiredCheck: false,
    //   pattern: "",
    //   message: "Only string allowed"
    // },
    ctpt_type: {
      title: "Type",
      type: "string",
      datatype: "array",
      requiredCheck: true,
      pattern: "",
      message: "Only string allowed",
      enum: ["533910000", "533910001"],
      enumNames: ["Full Time", "Part Time"],
    },
    // ctpt_yearofaward: {
    //   title: "Year of Award",
    //   type: "string",
    //   datatype: "string",
    //   requiredCheck: true,
    //   pattern: "",
    //   message: "Only string allowed"
    // },
    
    mshied_graduationdate: {
      title: "Graduation Date",
      type: "string",
      datatype: "string",
      requiredCheck: false,
      pattern: "",
      message: "Only string allowed"
    },
  },
};

export const getEmploymentHistoryDetailsSchema = {
  title: "Employment History",
  logicalname: "employmenthistory",
  type: "object",
  properties: {
    ctpt_professionalbody: {
      title: "Organisation Name",
      type: "string",
      schemaname: "ctpt_professionalbody",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false
    },
    ctpt_jobtitle: {
      title: "Title",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false
    },
    ctpt_jobdescription: {
      title: "Description",
      type: "string",
      schemaname: "ctpt_jobdescription",
      datatype: "string",
      requiredCheck: false,
      isReadonly: false,
      disabled: false
    },
    ctpt_typeofemployment: {
      title: "Type",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "array",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
      enum: ["100000000", "100000001","100000002"],
      enumNames: ["Permanent", "Contract","Fixed Contract"],
    },
    ctpt_startdate: {
      title: "Start Date",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false
    },
    ctpt_enddate: {
      title: "End Date",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: false,
      isReadonly: false,
      disabled: false
    }
  },
};

export const addNewEmploymentHistoryDetailsSchema = {
  title: "Employment History",
  logicalname: "employmenthistory",
  type: "object",
  properties: {
    ctpt_professionalbody: {
      title: "Organisation Name",
      type: "string",
      schemaname: "ctpt_professionalbody",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false
    },
    ctpt_jobtitle: {
      title: "Title",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false
    },
    ctpt_jobdescription: {
      title: "Description",
      type: "string",
      schemaname: "ctpt_jobdescription",
      datatype: "string",
      requiredCheck: false,
      isReadonly: false,
      disabled: false
    },
    ctpt_typeofemployment: {
      title: "Type",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "array",
      requiredCheck: true,
      isReadonly: false,
      disabled: false,
      enum: ["100000000", "100000001","100000002"],
      enumNames: ["Permanent", "Contract","Fixed Contract"],
    },
    
    ctpt_startdate: {
      title: "Start Date",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false
    },
    ctpt_enddate: {
      title: "End Date",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: false,
      isReadonly: false,
      disabled: false
    }
  },
};

export const getProfessionalHistoryDetailsSchema = {
  title: "Professional History",
  logicalname: "professionalhistory",
  type: "object",
  properties: {
    ctpt_professionalbody: {
      title: "Professional Body Name",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false
    },
    ctpt_jobtitle: {
      title: "Title",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false
    },
    ctpt_jobdescription: {
      title: "Description",
      type: "string",
      schemaname: "ctpt_jobdescription",
      datatype: "string",
      requiredCheck: false,
      isReadonly: false,
      disabled: false
    },
    ctpt_startdate: {
      title: "Start Date",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false
    },
    ctpt_enddate: {
      title: "End Date",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: false,
      isReadonly: false,
      disabled: false
    }
  },
};

export const addNewProfessionalHistoryDetailsSchema = {
  title: "Professional History",
  logicalname: "professionalhistory",
  type: "object",
  properties: {
    ctpt_professionalbody: {
      title: "Professional Body Name",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false
    },
    ctpt_jobtitle: {
      title: "Title",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false
    },
    ctpt_jobdescription: {
      title: "Description",
      type: "string",
      schemaname: "ctpt_jobdescription",
      datatype: "string",
      requiredCheck: false,
      isReadonly: false,
      disabled: false
    },
    ctpt_startdate: {
      title: "Start Date",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: true,
      isReadonly: false,
      disabled: false
    },
    ctpt_enddate: {
      title: "End Date",
      type: "string",
      schemaname: "ctpt_jobtitle",
      datatype: "string",
      requiredCheck: false,
      isReadonly: false,
      disabled: false
    }
  },
};

export const addNewQualificationDetailsSchema = {
  title: "Qualification Details",
  logicalname: "qualifications",
  type: "object",
  properties: {
    mshied_name: {
      title: "Previous Education Name",
      type: "string",
      
      datatype: "string",
      requiredCheck: true,
      pattern: "",
      message: "Only string allowed"
    },
    ctpt_schoolname: {
      title: "School Name",
      type: "string",
      datatype: "string",
      requiredCheck: false,
      pattern: "",
      message: "Only string allowed",
      // isDynamic: true,
      // apiURL: "/api/GetAllAsync",
      // payload: {
      //   properties: [
      //     "name",
      //     "accountid"
      //   ],
      //   filter: {
      //     globalfilter: "",
      //     globalAndOperator: false,
      //     fieldAndOperator: false,
      //     filterfields: [
      //       {
      //         field: "ctpt_accounttype",
      //         searchvalue: "533910004",
      //         type: "guid",
      //       },
      //     ]
      //   },
      //   Aggregate: {
      //     AggregateFields: []
      //   },
      //   sortfield: {
      //     field: "name",
      //     sorttype: "asc"
      //   },
      //   recordtype: "accounts",
      //   idfield: "accountid",
      //   continuationToken: "",
      //   pazesize: "1000"
      // },
      // enum: [
       
      // ],
      // enumNames: [
       
      // ]
    },
    ctpt_startdate: {
      title: "Start Date",
      type: "string",
      datatype: "number",
      requiredCheck: true,
      disableFuture: true
    },
    ctpt_enddate: {
      title: "End Date",
      type: "string",
      datatype: "number",
      requiredCheck: false,
      disableFuture: false
    },
    mshied_educationlevelid: {
      title: "Education Level",
      type: "string",
      
      datatype: "string",
      requiredCheck: false,
      pattern: "",
      message: "Only string allowed",
      isDynamic: true,
      apiURL: "/api/GetAllAsync",
      payload: {
        properties: [
          "mshied_name",
          "mshied_educationlevelid"
        ],
        filter: {
          globalfilter: "",
          globalAndOperator: false,
          fieldAndOperator: false,
          filterfields: [
          ]
        },
        Aggregate: {
          AggregateFields: []
        },
        sortfield: {
          field: "mshied_name",
          sorttype: "asc"
        },
        recordtype: "mshied_educationlevels",
        idfield: "mshied_code",
        continuationToken: "",
        pazesize: "1000"
      },
      enum: [
       
      ],
      enumNames: [
       
      ]
    },
    ctpt_grade: {
      title: "Grade",
      type: "string",
      
      datatype: "string",
      requiredCheck: true,
      pattern: "",
      message: "Only string allowed"
    },
    // ctpt_level: {
    //   title: "Level",
    //   type: "string",
      
    //   datatype: "string",
    //   requiredCheck: false,
    //   pattern: "",
    //   message: "Only string allowed"
    // },
    ctpt_type: {
      title: "Type",
      type: "string",
      datatype: "array",
      requiredCheck: true,
      pattern: "",
      message: "Only string allowed",
      enum: ["533910000", "533910001"],
      enumNames: ["Full Time", "Part Time"],
    },
    mshied_graduationdate: {
      title: "Graduation Date",
      type: "string",
      datatype: "string",
      requiredCheck: false,
      pattern: "",
      message: "Only string allowed"
    },
    
  },
};

export const getContactDetailsSchema = {
  title: "Contact Details",
  logicalname: "contactdetails",
  type: "object",
  properties: {
    ctpt_telephone: {
      title: "Contact Number",
      type: "string",
      schemaname: "ctpt_telephone",
      datatype: "string",
      pattern: "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$",
      helpText: "Note - Prefix your country code to the telephone /mobile number. Eg: +267",
      message: "Please enter valid contact number",
      isReadonly: false,
      disabled: false
    }
  },
};

export const getIdentityDetailsSchema = {
  title: "Identity Details",
  logicalname: "ctpt_identity",
  type: "object",
  properties: {
    ctpt_type: {
      title: "Type",
      type: "string",
      schemaname: "ctpt_type",
      datatype: "string",
      isReadonly: true,
      disabled: false
    },
    ctpt_identitynumber: {
      title: "Number",
      type: "string",
      schemaname: "ctpt_identitynumber",
      datatype: "string",
      isReadonly: false,
      disabled: false
    }
  },
};