import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getIdentityListPayload } from "data/payload/contactInfoPayload";
import { config } from "utilities/config";
import { useStyles } from "../style";
import ListComponent from "app/components/mvp/listWidget";
import { IDENTITY_NAME_MAP } from "../model/data/constants";

function IdentityInformation(props) {
  const contactid = useSelector((state) => state.contactid);
  const classes = useStyles();
  const history = useHistory();
  //const classes = useStyles();
  const getcontactId = useSelector((state) => state.contactid);
  //const history = useHistory();

  const updateDetails = (params) => {
    window.location.href =
      "/profile/identity/update?id=" + params.row.ctpt_identityid;
  };

  const columns = [
    { field: "ctpt_identityid", flex: 1, headerName: " ", hide: true },
    {
      field: "ctpt_type",
      flex: 1,
      headerName: "Type",
      renderCell: (params) => (
        <p>
          {params.row.ctpt_type !== null
            ? IDENTITY_NAME_MAP[params.row.ctpt_type]
            : "N/A"}
        </p>
      ),
    },
    { field: "ctpt_identitynumber", flex: 2, headerName: "Number" },
    {
      field: "action",
      flex: 1,
      headerName: <p>Action</p>,
      excludeFromSearch: true,
      renderCell: (params) => (
        <div>
          <Button
            variant="outlined"
            color="primary"
            className="primaryBtn"
            onClick={() => {
              updateDetails(params);
            }}
          >
            Update Details
          </Button>
        </div>
      ),
    },
  ];

  const options = {
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: columns,
    searchPayload: "searchFilter",
    getlistPayload: getIdentityListPayload(contactid),
    deleteListPayload: {},
    listHeading: "Identity Information",
    formWidget: {},
    nonRecord: false,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "",
    filterValue: [],
    aggregateField: "",
    hideSearch: true,
    pageSize: 6,
    hasPayloadfilter: true,
    hideAdd: true,
    controlLists: false,
  };

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item xs={12} md={9}>
        <div className={classes.componentHolder}>
          <Button
            size="medium"
            variant="outlined"
            onClick={() => {
              history.push("/profile");
            }}
            style={{ marginBottom: "1.5rem" }}
          >
            Go Back
          </Button>
          <ListComponent options={options} />
        </div>
      </Grid>
    </Grid>
  );
}

export default IdentityInformation;
