import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    paddingTop: "4rem",
  },

  withNav: {

  },
  withoutNav: { paddingLeft: "0px;" },
}));
