export const workAddressSchema = {
  title: "Edit Work Address",
  logicalname: "Channel",
  type: "object",
  //required: ["name", "address", "primaryemail", "primarycontact"],
  properties: {
    ctpt_line1: {
      title: "Plot Number",
      type: "string",
      datatype: "string",
    },
    ctpt_city: {
      title: "City",
      type: "string",
      datatype: "string",
    },
    ctpt_county: {
      title: "Country",
      type: "string",
      datatype: "string",
      pattern: "",
    },
  },
};
