import React, { useEffect } from "react";
import { Switch } from "react-router-dom";

import {
  Home,
  Applications,
  IndvMembership,
  MemberDeclarations,
  EvidenceDetails,
  Paymentsucess,
  Prepayment,
  Paymentfailure,
  ViewEvidence,
  Invoices,
  Notifications,
  ViewRecord,
  PayLater,
  PaymentSuccessStatic,
  NoAccess,
  CustomError,
  UploadCV,
  FirmMembership,
  Profile,
  Nationalities,
  WorkAddress,
  PersonalAddress,
  PostalAddress,
  EmailInformation,
  AboutYou,
  ExamCertificates,
  ExamResult,
  Events,
  EventDetails,
  Exams,
  AddEvidence,
  ErrorPage,
  EmploymentHistory,
  ViewEmploymentHistoryDetails,
  Qualifications,
  Preferences,
  PageNotFound,
  EventRegister,
  ViewContactDetails,
  Declarations,
  ViewDeclaration,
  ViewQualificationDetails,
  TelephoneInformation,
  IdentityInformation,
  AddressInformation,
  ViewAddressDetails,
  ViewTelephoneDetails,
  ViewEmailDetails,
  ViewidentityInformation,
  PersonalInformation,
  ViewRenewal,
} from "app/pages";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";
import AccountInactive from "app/pages/accountInactive";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

export default function Pages() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "SET_SHOW_LOADER",
      payload: { status: false, message: "" },
    });
  }, [location, dispatch]);

  return (
    <Switch>
      <PublicRoute
        restricted={false}
        component={Home}
        path="/"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Applications}
        path="/applications"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={IndvMembership}
        path="/application/individualMembership"
        exact
        siteNav="hidden"
      />
      <PrivateRoute
        component={AddEvidence}
        path="/add-evidence"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ErrorPage}
        path="/error"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Paymentsucess}
        path="/application/prepayment/sucess"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Paymentfailure}
        path="/application/prepayment/failure"
        exact
        siteNav="visible"
      />

      <PrivateRoute
        component={Invoices}
        path="/invoices"
        exact
        siteNav="visible"
      />

      <PrivateRoute
        component={Notifications}
        path="/notifications"
        exact
        siteNav="hidden"
      />
      <PrivateRoute
        component={FirmMembership}
        path="/application/firmMembership"
        exact
        siteNav="hidden"
      />
      <PrivateRoute
        component={Nationalities}
        path="/profile/nationalities"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={TelephoneInformation}
        path="/profile/contactinformation"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={IdentityInformation}
        path="/profile/identityinformation"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewidentityInformation}
        path="/profile/identity/update"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={AddressInformation}
        path="/profile/addresses"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={WorkAddress}
        path="/profile/workaddress"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={PersonalAddress}
        path="/profile/personaladdress"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={PostalAddress}
        path="/profile/postaladdress"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={EmailInformation}
        path="/profile/email"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewEmailDetails}
        path="/profile/email/update"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={UploadCV}
        path="/profile/upload-cv"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewRecord}
        path="/cpd/viewrecord"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Prepayment}
        path="/application/prepayment"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={PayLater}
        path="/application/prepayment/pay-later"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={PaymentSuccessStatic}
        path="/application/prepayment/payment-success"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={PaymentSuccessStatic}
        path="/application/success"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={PersonalInformation}
        path="/profile/aboutyou"
        exact
        siteNav="visible"
      />
      <PublicRoute
        component={NoAccess}
        path="/noaccess"
        exact
        siteNav="visible"
      />

      <PrivateRoute
        component={UploadCV}
        path="/profile/upload-cv"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={MemberDeclarations}
        path="/cpd/declaration"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Profile}
        path="/profile"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={EvidenceDetails}
        path="/exam/evidence"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewEvidence}
        path="/viewEvidence"
        exact
        siteNav="visible"
      />
      <PublicRoute
        component={AccountInactive}
        path="/inactive"
        exact
        siteNav="visible"
      />
      <PublicRoute
        component={CustomError}
        path="/error"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ExamCertificates}
        path="/exam/certificates"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        restricted={false}
        component={ExamResult}
        path="/exam/reports"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Events}
        path="/event/registration"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={EventDetails}
        path="/event/details"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={EventRegister}
        path="/application/eventRegistration"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Exams}
        path="/exam/register"
        exact
        siteNav="visible"
      />

      <PrivateRoute
        component={EmploymentHistory}
        path="/profile/employmenthistory"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewEmploymentHistoryDetails}
        path="/profile/viewemploymenthistorydetails"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewAddressDetails}
        path="/profile/viewaddressdetails"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Qualifications}
        path="/profile/qualifications"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Preferences}
        path="/preferences"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewTelephoneDetails}
        path="/profile/contact/update"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Declarations}
        path="/declarations"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewRenewal}
        path="/renewals"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewDeclaration}
        path="/declarations/:id"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Declarations}
        path="/declarations"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewDeclaration}
        path="/declarations/:id"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewQualificationDetails}
        path="/profile/viewqualificationdetails"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Declarations}
        path="/declarations"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewDeclaration}
        path="/declarations/:id"
        exact
        siteNav="visible"
      />
      <PrivateRoute component={PageNotFound} path="*" exact siteNav="visible" />
    </Switch>
  );
}
