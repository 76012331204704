export const applicationNameMap = {
  individualMembership: "Individual Membership",
  firmMembership_nonaat: "Firm Application (ACCTECH)",
  firmMembership_nonaudit: "Firm Application",
  duplicateCertificate: "Duplicate Certificate",
  studentApplication: "Student Application",
  trainingemployer: " Training Employer",
  tutionProvider: "Tuition Provider",
  upgradeApplication: "Upgrade Application",
  exemptionCertificate: "Exemption Certificate",
  clearanceCertificate: "Clearance Certificate",
};
