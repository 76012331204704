export default {
  CLIENT_ID: "b0a0a6e7-c31c-47aa-a30c-50f41a68d9c0",
  AUTHORITY:
    "https://prodciipab2c.b2clogin.com/prodciipab2c.onmicrosoft.com/B2C_1_signin_signup",
  KNOWN_AUTHORITY: ["https://prodciipab2c.b2clogin.com"],
  REDIRECT_URI: "https://register.ciipa.ky/",
  SIGNOUT_REDIRECT_URI: "https://register.ciipa.ky/#/signedOut",
  API_URL: "https://cp-azgb",
  UPDATE_PASSWORD:
    "https://prodciipab2c.b2clogin.com/prodciipab2c.onmicrosoft.com/B2C_1_password_reset",
  APP_NAME: "Membership-Poweup",
  INNOVATE_SITE_URL: "https://register.ciipa.ky",
  FAC: "https://staging.ptranz.com/api/spi/",
};
