import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Typography, Button } from "@material-ui/core";
import { globalTheme } from "style/globalTheme";
import Preference from "app/components/mvp/preference/Preference";
import { useSelector } from "react-redux";
import { config } from "utilities/config";
import { post } from "app/services/api";
import { ReactComponent as SuccessSVG } from "../../../assets/success.svg";
import { ReactComponent as ErrorSVG } from "../../../assets/error.svg";
import { brandPalette } from "brand/theme/brandTheme";
import { useHistory } from "react-router-dom";
import { usePreferenceStyles } from "./style";
import { usePreLoader } from "app/services/hooks/usePreLoader";

const Container = styled.div`
  padding: ${globalTheme.spacing(2)}px;
  margin: ${globalTheme.spacing(2)}px;
  background-color: white;
  ${globalTheme.breakpoints.down("sm")} {
    background-color: ${brandPalette.Secondary[50]};
  }
  #cancelButton {
    margin-top: ${globalTheme.globalSizes.small};
  }
  .description {
    max-width: 55rem;
    margin-top: 2rem;
  }
`;

const Heading = styled.div`
  height: 14.5rem;
  padding: 1rem 1.2rem;
  background-image: linear-gradient(
      to right bottom,
      ${brandPalette["Primary"][200]},
      ${brandPalette["Complementary"][600]}
    ),
    url(brand/slide4.jpeg);
  //background-blend-mode: screen;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);

  h2 {
    display: inline-block;
    justify-content: flex-start;
    margin: 2.7rem 2rem;
    max-width: 30rem;
    font-size: 2.9rem;
    letter-spacing: 5px !important;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.07rem;
    padding: 0.5rem;
    border-radius: 8px;
    background-image: linear-gradient(
      to right,
      rgba(60, 60, 60, 0.5),
      rgba(0, 28, 48, 0.5)
    );
    //-webkit-background-clip: text;
    color: rgba(255, 255, 255, 0.9);
  }

  ${globalTheme.breakpoints.down("sm")} {
    position: relative;
    width: 100%;
    clip-path: none;
    h2 {
      margin-left: 0;
      font-size: 2.5rem;
    }
  }

  ${globalTheme.breakpoints.down("xs")} {
    height: 15rem;
    clip-path: none;
    h2 {
      font-size: 2.2rem;
     margin-left: 1rem;
     margin-right: 1rem;
    }
  }
}
`;

export default function Preferences() {
  const contactId = useSelector((state) => state.contactid);
  const history = useHistory();

  const [preferences, setPreferences] = useState({});
  const [channelInfo, setChannelInfo] = useState({});
  const [initialPreferenceData, setInitialPreferenceData] = useState({});
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const [showNoPreferenceAlert, setShowNoPreferenceAlert] = useState(false);

  const classes = usePreferenceStyles();
  const { enableLoader } = usePreLoader();

  const loadPreferences = useCallback(async () => {
    if (contactId) {
      enableLoader(true);
      const getPreferenceAPI = config.teleportal.preferences.get;
      post(getPreferenceAPI, { contactid: contactId })
        .then((response) => {
          const apiData = response.data;
          const preferenceList = {};
          const channelDataList = {};
          setShowNoPreferenceAlert(!apiData?.length);
          if (apiData && apiData?.length > 0) {
            apiData.forEach((channel) => {
              channelDataList[channel.channelId] = {
                title:
                  channel.name ||
                  channel.email ||
                  channel.fullAddress ||
                  channel.telephone ||
                  channel.mobile,
                description: channel.channelType,
              };
              preferenceList[channel.channelId] = channel.preferences.map(
                (preference) => {
                  return {
                    PreferenceId: preference.preferenceId,
                    PreferenceType: preference.preferenceType,
                    SupportInterestType: preference.supportInterestType,
                    ChannelId: channel.channelId,
                    PreferenceTypeId: preference.preferenceTypeId,
                    SupportInterestTypeId: preference.supportInterestTypeId,
                    OptIn: preference.optIn,
                  };
                }
              );
            });

            setChannelInfo(channelDataList);
            setPreferences(preferenceList);
            setInitialPreferenceData(preferenceList);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          enableLoader(false);
        });
    }
  }, [contactId]);

  useEffect(() => {
    loadPreferences().catch(console.log);
  }, [loadPreferences]);

  function compare(a, b) {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  }

  const getFormattedPreferenceList = (channelId) => {
    if (preferences[channelId]) {
      return preferences[channelId]
        .map(({ PreferenceId, OptIn, SupportInterestType }) => ({
          id: PreferenceId,
          value: OptIn,
          label: SupportInterestType,
        }))
        .sort(compare);
    }

    return [];
  };

  const handlePreferenceChange = (channelId, preferenceId, value) => {
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
    if (preferences[channelId]) {
      const selectedChannel = preferences[channelId];
      const updatedPreferenceOfSelectedChannel = selectedChannel.map(
        (preference) =>
          preference.PreferenceId === preferenceId
            ? { ...preference, OptIn: value }
            : preference
      );

      setPreferences((oldPreferences) => {
        return {
          ...oldPreferences,
          [channelId]: updatedPreferenceOfSelectedChannel,
        };
      });
    }
  };

  const getPreferencesUpdatePayload = () => {
    const updatedPreferences = [];
    Object.keys(preferences).forEach((channelId) => {
      preferences[channelId].forEach((currentPreference) => {
        const preferenceBeforeUpdate = initialPreferenceData[channelId]?.find(
          (initialPreference) =>
            initialPreference.PreferenceId === currentPreference.PreferenceId
        );
        if (
          preferenceBeforeUpdate &&
          currentPreference.OptIn !== preferenceBeforeUpdate.OptIn
        ) {
          updatedPreferences.push(currentPreference);
        }
      });
    });

    return {
      contactid: contactId,
      preferences: updatedPreferences,
    };
  };

  const onClickSaveButton = () => {
    const preferenceUpdateAPI = config.teleportal.preferences.update;
    const updatePreferencePayload = getPreferencesUpdatePayload();

    if (updatePreferencePayload.preferences?.length > 0) {
      setEnableSaveButton(false);
      post(preferenceUpdateAPI, updatePreferencePayload)
        .then((response) => {
          setShowSuccessAlert(true);
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          setShowErrorAlert(true);
          window.scrollTo(0, 0);
          console.log(err);
        })
        .finally(() => {
          loadPreferences().finally(() => {
            setEnableSaveButton(true);
          });
        });
    }
  };

  const onClickCancelButton = () => {
    history.goBack();
  };

  return (
    <div>
      <Heading>
        <h2>Communication Preferences</h2>
      </Heading>
      {showSuccessAlert && (
        <div className={classes.alert}>
          <SuccessSVG />
          <div className={classes.alertContent}>
            <b>Your communication preferences have been updated</b>
            {/* <p className={classes.alertMessage}>description</p> */}
          </div>
        </div>
      )}
      {showErrorAlert && (
        <div className={`${classes.alert} ${classes.errorAlert}`}>
          <ErrorSVG />
          <div className={classes.alertContent}>
            <b>Update failed</b>
            <p className={classes.alertMessage}>Please try again later.</p>
          </div>
        </div>
      )}
      <Container>
        <div className="description">
          <Typography variant="subtitle1">
            Use this page to manage your marketing and communication
            preferences. If you need to update your contact details visit the
            personal details area of your profile.
          </Typography>
        </div>

        <div>
          {Object.keys(preferences)
            .sort()
            .map((channelId) => (
              <div className={classes.preferenceContainer}>
                <Preference
                  title={channelInfo[channelId]?.title}
                  description={channelInfo[channelId]?.description}
                  preferences={getFormattedPreferenceList(channelId)}
                  onChange={(prefenceId, value) =>
                    handlePreferenceChange(channelId, prefenceId, value)
                  }
                />
              </div>
            ))}
        </div>

        {Object.keys(preferences).length > 0 && (
          <div className={classes.footerButtons}>
            <Button
              id="saveButton"
              variant="contained"
              color={"primary"}
              size="small"
              className={classes.saveButton}
              onClick={onClickSaveButton}
              disabled={!enableSaveButton}
            >
              Save updates
            </Button>
            <Button
              id="cancelButton"
              variant="outlined"
              size="small"
              onClick={onClickCancelButton}
            >
              Cancel
            </Button>
          </div>
        )}
        {showNoPreferenceAlert && (
          <div
            className={`${classes.alert} ${classes.errorAlert}`}
            style={{ display: "inline-block", marginTop: "3rem" }}
          >
            <div className={classes.emptyListAlert}>
              <b>
                We do not currently have any communication preferences stored
                against your account !!!
              </b>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}
