export const personalAddressSchema = {
  title: "Edit Personal Address",
  logicalname: "Channel",
  type: "object",
  //required: ["name", "address", "primaryemail", "primarycontact"],
  properties: {
    ctpt_line1: {
      title: "Plot Number",
      type: "string",
      datatype: "string",
    },
    ctpt_city: {
      title: "City",
      type: "string",
      datatype: "string",
    },
    ctpt_county: {
      title: "County",
      type: "string",
      datatype: "string",
    },
  },
};
