import React from "react";
import PropTypes from "prop-types";

import { Card, Box, Typography } from "@material-ui/core";
import Frame from "layout/Frame";
import styled, { css } from "styled-components";
import Cluster from "layout/Cluster";
import { globalTheme } from "style/globalTheme";

const ImgCard = styled(Card)(
  () => css`
    position: relative;
    height: ${(props) => props.$height};
    width: ${(props) => props.$width};
    box-shadow: unset;
  `
);

const CardContent = styled(Cluster)`
  position: relative;
  z-index: 1;
  margin-top: 1rem;
`;
const CardHeader = styled(Typography)`
  display: block;
  padding: 0.5rem;
  font-size: 1.625rem;
  color: ${globalTheme.palette.secondary.dark};
`;
const CardBox = styled(Box)(
  () => css`
    width: 100%;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    height: 100%;
  `
);
const ImgBox = styled(Box)(
  () => css`
    width: 100%;
  `
);

const ImageCard = ({
  image,
  children,
  header,
  width,
  height,
  imgRatio = "1:1",
  lrRatio = "60:40",
}) => {
  const contentSplit = lrRatio.split(":");
  return (
    <ImgCard $width={width} $height={height}>
      <CardContent justify="between" alignContent="start" align="end">
        <ImgBox>
          <Frame ratio={imgRatio}>{image}</Frame>
        </ImgBox>
        <CardHeader variant="h4" component="h3">
          {header}
        </CardHeader>
        <CardBox $width={contentSplit[0]}>{children}</CardBox>
      </CardContent>
    </ImgCard>
  );
};

ImageCard.propTypes = {
  /** The image for the card - pass in a whole HTML `img` */
  image: PropTypes.element,
  /**  The header text for the card*/
  header: PropTypes.string,
  /**  The aspect ratio for the image (it is rendered by a Frame - see layout primitives -> Frame)*/
  imgRatio: PropTypes.string,
  /** Ratio of content to image eg: "70:30" will have the left hand (content) side be 70% of the width of the card, the image 30% */
  lrRatio: PropTypes.string,
};

export default ImageCard;
