import React from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import styled from "styled-components";
import { useStyles } from "./style";
import { Button, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

const PageNotFound = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <StartPage>
          <h1 className={classes.headingTxt}>Oops! You seem to be lost.</h1>

          <div className={classes.desc}>
            <p>Lets' go back and try again...</p>
            <Box>
              <Button
                color="primary"
                variant="contained"
                onClick={() => history.push("/")}
              >
                {" "}
                Go Back to Homepage​
              </Button>
            </Box>
          </div>
        </StartPage>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
};

export default PageNotFound;
