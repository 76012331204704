export const getphonelistPayload = (contactid) => ({
  properties: [
    "ctpt_channelid",
    "ctpt_telephonetype",
    "ctpt_maptelephoneto",
    "ctpt_telephone",
  ],
  recordType: "ctpt_channels",
  formattedFields: ["_ctpt_customerid_value"],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: true,
    filterfields: [
      {
        field: "_ctpt_customerid_value",
        searchvalue: contactid,
        type: "guid",
      },
      {
        field: "ctpt_methodtype",
        searchvalue: "727530002",
        type: "int",
      },
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  idfield: "ctpt_channelid",
  continuationToken: "",
  pagesize: "10",
});

export const getphonerecordPayload = {
  properties: ["ctpt_channelid", "ctpt_telephonetype", "ctpt_telephone"],
  formattedFields: [],
  recordType: "ctpt_channels",
  id: "",
};

export const updatePhonePayload = (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.recordId,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "ctpt_telephonetype",
        value: details[0].value,
        type: "string",
      },
      {
        name: "ctpt_telephone",
        value: details[1].value,
        type: "string",
      },
    ],
  };
  return payload;
};
