export const getEventRegisterSchema = {
  title: "Event Registration",
  logicalname: "eventReg",
  type: "object",
  properties: {
    ctpt_specialrequirements: {
      title: "Special Requirements",
      type: "string",
      schemaname: "ctpt_specialrequirements",
      datatype: "string",
      ismultiline: true,
      multilineRow: 4,
    },
  },
};

export const getDocumentSchema = {
  title: " ",
  logicalname: "Contact2",
  type: "object",
  properties: {
    ctpt_name: {
      title: "Name",
      type: "string",
      schemaname: "ctpt_customerid",
      datatype: "string",
      isDynamic: false,
    },
    ctpt_documenttype: {
      title: "Document Type",
      type: "string",
      schemaname: "ctpt_documenttype",
      datatype: "string",
    },
    ctpt_case: {
      title: "Case",
      type: "string",
      schemaname: "ctpt_case",
      datatype: "string",
    },
    ctpt_documenturl: {
      title: "Student attendance register",
      type: "string",
      schemaname: "ctpt_documenturl",
      datatype: "string",
      requireBoburl: true,
    },
  },
};
