import React from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useStyles } from "./style";
import { Grid } from "@material-ui/core";
import ListComponent from "app/components/mvp/listWidget";
import { useSelector } from "react-redux";
import { config } from "utilities/config";
import moment from "moment";
import { getEventDocumentsPayload } from "./model/payload";
import { Button } from "@material-ui/core";

function ExamCertificates() {
  const classes = useStyles();
  const role = useSelector((state) => state.role);
  const contactID = useSelector((state) => state.contactid);

  const unRegisteredColumns = [
    {
      field: "ctpt_name",
      headerName: <p>Exam Name</p>,
      flex: 1.5,
      renderCell: (params) => <p>{params.row.ctpt_name}</p>,
    },
    {
      field: "ctpt_documentdate",
      headerName: <p>Submission Date</p>,
      flex: 1.5,
      renderCell: (params) => (
        <p>
          {" "}
          {params.row.ctpt_documentdate !== null
            ? moment.utc(params.row?.ctpt_documentdate).format("DD MMM YYYY")
            : "N/A"}
        </p>
      ),
    },
    {
      field: "ctpt_documenturl",
      headerName: <p className={classes.dueListHeader}>Document</p>,
      flex: 1,
      renderCell: (params) => (
        <div className={classes.actionsButtons}>
          <Button
            variant="outlined"
            color="primary"
            className="primaryBtn"
            onClick={() => {
              window.open(params.row.ctpt_documenturl);
            }}
          >
            Download Certificate
          </Button>
        </div>
      ),
    },
  ];

  const unRegisteredEventOptions = {
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: unRegisteredColumns,
    searchPayload: "searchFilter",
    getlistPayload: getEventDocumentsPayload(contactID),
    deleteListPayload: {},
    listHeading: "Exam Certificates",
    formWidget: {},
    nonRecord: false,
    role: role,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: true,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: contactID,
    isCustomFetch: false,
    hasFilter: true,
    hasPayloadfilter: false,
    payloadModifyForbidden: true,
    filterField: "_ctpt_customerid_value",
    filterValue: [contactID],
    hideSearch: true,
    pageSize: 10,
    hideAdd: true,
    controlLists: false,
  };
  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <div className={classes.container}>
              <ListComponent options={unRegisteredEventOptions} />
            </div>
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default ExamCertificates;
