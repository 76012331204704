import env from "../environments/env";

export const config = {
  teleportal: {
    env: `${env.API_URL}-tp-prfun01.azurewebsites.net`,
    file: {
      Create: "/api/CreateFileAsync",
      Delete: "/api/DeleteFileAsync",
      get: "/api/GetFileAsync",
      list: "/api/GetAllAsync",
      delete: "/api/DeleteRecordAsync",
    },
    lookup: {
      get: "/api/GetAllAsync",
    },
    submitForm: {
      Create: "/api/CreateRecordAsync",
      Update: "/api/UpdateRecordAsync",
      get: "/api/GetAllAsync",
      getId: "/api/GetRecordByIdAsync",
      delete: "/api/DeleteRecordAsync",
    },
    profile: {
      get: "/api/ProfileAsync",
    },
    sonicForm: {
      get: "/api/GetFormSchemaAsync",
    },
    preferences: {
      get: "/api/GetPreferencesAsync",
      update: "/api/UpdatePreferencesAsync",
    },
    letter: {
      create: "/api/CaseCreationAsync",
    },
    order: {
      create: "/api/OrderCreationAsync",
    },
    custom: {
      fetch: "/api/ApplicationFormListAsync",
    },
    events: {
      getAllEventList: "/api/FunctionExecuteAsync",
    },
  },
  entitlement: {
    env: `${env.API_URL}-etl-prfun01.azurewebsites.net`,
    entitle: {
      getContactEntitle: "/api/GetContactEntitlementAsync?",
      getEntitle: "/api/GetEntitlementAsync?",
    },
  },
  // IMPORTANT - below needs to be updated with membership teleportal apis when member directories will be setup
  publicAPI: {
    firmDirectory: `${env.API_URL}-tp-dvfun04.azurewebsites.net/api/GetPublicMemberFirmDirectoryAsync?code=YwPDfwlNMCti2u3CaGChUK8bIcsXlQWFWKzLD5coUJjM1WgT1m4PFA==&recordtype=account`,
    memberDirectory: `${env.API_URL}-tp-dvfun04.azurewebsites.net/api/GetPublicMemberFirmDirectoryAsync?code=YwPDfwlNMCti2u3CaGChUK8bIcsXlQWFWKzLD5coUJjM1WgT1m4PFA==&recordtype=contact`,
  },
  sonicwebform: {
    env: `${env.API_URL}-swf-prfun02.azurewebsites.net`,
    getFormschema: "/api/GetFormSchemaAsync",
    createFormsubmit: "/api/CreateFormSubmissionAsync",
    getFormsubmit: "/api/GetFormSubmissionAsync",
    cancelFormsubmit: "/api/CancelFormSubmissionAsync",
  },
  payment: {
    env: `${env.API_URL}-tp-prfun03.azurewebsites.net`,
    endPoint: `${env.API_URL}-tp-prfun03.azurewebsites.net/api/PaymentHandlerAsync`,
    hashKey: "",
    terminalId: "",
    paymentSuccessHandlerAsyncUrl: `${env.API_URL}-tp-dvfun03.azurewebsites.net/api/PaymentSuccessHandlerAsync?code=WSuRk8cYa2wG8ql1URqXrt5WQevrx5ARFhGBSMiqvCzFoP7gl5dozA==`,
    paymentFailureHandlerAsyncUrl: `${env.API_URL}-tp-dvfun03.azurewebsites.net/api/PaymentFailureHandlerAsync?code=6FLlQr6g7ffIaiESyvj3ODJMHbH5cwIDm/HxD6HU1lAwEq6Q8i4PrA==`,
    orderDetails: "/api/OrderDetailsAsync",
    payHandler: "/api/paymenthandlerasync",
    paymentComplete: `${env.FAC}payment`,
  },
  blob: {
    env: "https://cpazgbtpdvasa02.z6.web.core.windows.net",
  },
};
