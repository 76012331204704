import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { contactSchema } from "../model/schema/contactJson";
import { contactUiSchema } from "app/components/uiSchema/contactUiSchema";
import { ContactPayload } from "app/services/payloadSubmit/contactPayload";
import { config } from "utilities/config";
import { post } from "app/services/api";
import FormWidget from "app/components/mvp/formWidget";
import { useStyles } from "../style";
import { globalTheme } from "style/globalTheme";
import styled from "styled-components";
function PersonalInformation(props) {
  const userEntitlement = useSelector((state) => state.entitleMents);
  const userRole = useSelector((state) => state.role);
  let permissions = props.permissions;
  const contactid = useSelector((state) => state.contactid);
  const [contactFormData, setContactFormData] = useState({});
  const dispatch = useDispatch();
  const classes = useStyles();
  const contact = {
    widgetType: "updateContact",
    formSchema: contactSchema,
    uiSchema: contactUiSchema,
    formData: contactFormData,
    apiURL: config.teleportal.submitForm.Update,
    payloadSchema: {},
    entityType: "contacts",
    formUischema: "",
    button1Label:
      userRole && userRole.name && !userRole.name.includes("Individual")
        ? "Save"
        : "Submit",
    button2Label: "Go Back",
    payloadSubmit: ContactPayload,
    contactId: contactid,
    goBackPage: "/profile",
    button2GoBack: true,
    historyUrl: "",
  };
  const Heading = styled.div`

  border-radius: 4px; 
  //padding: 1rem 1.2rem;
  
  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;


  h2 {
    max-width: max-content;   
    //margin: 4rem 2.5rem;
    margin: 0;
    font-size: 1.5rem;
    letter-spacing: -0.16px;
    text-transform: uppercase;
    font-weight: 700;
    
    // border-radius: 8px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    );

     color:${globalTheme.palette.primary.main};
     margin-left: 20px;
  }

  ${globalTheme.breakpoints.down("sm")} {
    position: relative;
    width: 100%;
    h2 {
      margin-left: 0;
      font-size: 2.5rem;
    }
  }

  ${globalTheme.breakpoints.down("xs")} {
    height: 15rem;
    h2 {
     margin-left: 1rem;
     margin-right: 1rem;
    }
  }
}
`;
  const [contactOptions, setContactOptions] = useState(contact);

  useEffect(() => {
    if (contactid.length > 0) {
      getContactDetails();
    }
  }, [contactid]);

  const updateFormData = (entityType, formData) => {
    if (
      userRole &&
      userRole.name &&
      userRole.name.includes("Individual") &&
      permissions &&
      permissions.permissionsets != null &&
      permissions.permissionsets.length > 0 &&
      permissions.permissionsets[0] != null &&
      permissions.permissionsets[0].canwrite == "0" &&
      permissions.permissionsets[0].cancreate == "0"
    ) {
      contact.formSchema.properties.firstname.disabled = true;
      contact.formSchema.properties.lastname.disabled = true;
      contact.formSchema.properties.birthdate.isReadonly = true;

      contact.formSchema.properties.ctpt_othername.disabled = true;
      contact.formSchema.properties.ctpt_profiledetailsconfirmedaccurateon.isReadonly = true;
      //contact.formSchema.properties.fax.disabled = true
      contact.formSchema.properties.fullname.disabled = true;
      contact.formSchema.properties.salutation.disabled = true;
    } else if (
      userRole &&
      userRole.name &&
      userRole.name.includes("Student") &&
      permissions &&
      permissions.permissionsets != null &&
      permissions.permissionsets.length > 0 &&
      permissions.permissionsets[0] != null &&
      permissions.permissionsets[0].canwrite == "0" &&
      permissions.permissionsets[0].cancreate == "0"
    ) {
      contact.formSchema.properties.firstname.disabled = true;
      contact.formSchema.properties.lastname.disabled = true;
      contact.formSchema.properties.birthdate.isReadonly = true;
    }

    contact.formData = formData;
    contact.initialFormData = formData;
    setContactOptions(contact);
    setContactFormData(formData);
  };

  const getContactDetails = () => {
    let api;
    let payloads;
    const formData = {};
    api = config.teleportal.submitForm.getId;

    payloads = {
      properties: [
        "contactid",
        "salutation",
        "firstname",
        "lastname",
        "fullname",
        "ctpt_othername",
        "birthdate",
        "fax",

        "ctpt_profiledetailsconfirmedaccurateon",
      ],
      formattedFields: ["gendercode"],
      recordType: "contacts",
      id: contactid,
    };
    post(api, payloads)
      .then((res) => {
        res.data.data.forEach((x) => {
          if (x.name === "ctpt_profiledetailsconfirmedaccurateon") {
            formData["ctpt_profiledetailsconfirmedaccurateon"] = new Date();
          }
          formData[x.name] = x.value;
        });
        updateFormData({}, formData);
      })
      .catch(console.log);
  };

  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record updated successfully",
          code: "",
          display: "show",
          severity: "success",
        },
      });
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record update failed",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item xs={12} md={9}>
        <div className={classes.componentHolder}>
        <Heading>
                <h2>Personal Information</h2>
                </Heading>
                <hr
          style={{
            border: "none",
            height: "1px",
            margin: "0",
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            marginLeft:"20px",
            marginRight:"20px",
            marginTop:"20px"
          }}
        />
          <FormWidget
            options={contactOptions}
            updateFormData={updateFormData}
            successResponse={getResponse}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default PersonalInformation;
